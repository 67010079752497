import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withStyles, TableHead, TableCell, TableBody, TableRow, Checkbox, Switch} from '@material-ui/core';
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Table} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import layersSvg from '../../../static/images/layers.svg';
import * as Enums from '../../enums';

const styles = (theme) => ({
    heading : {
        color: theme.palette.navy.main,
        alignItems: 'center',
        display: 'flex',
        marginLeft: '8px',
        backgroundColor: theme.palette.primary.contrastText,
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            ...theme.overrides.MuiTypography.h6
        }
    },
    additionalLayersContainer: {
        marginTop: '8px',
        overflowX: 'hidden',
        overflowY: 'auto',
        boxShadow: 'none',
        [theme.breakpoints.down('xs')]: {
            marginBottom: '95px !important'
        }
    }
});

@inject('additionalLayersStore','appMainStore', 'browseStore')
@observer
class AdditionalLayersSelector extends React.Component {

    componentDidMount() {
        if (this.props.additionalLayersStore.layers.length == 0) {
            this.props.additionalLayersStore.loadLayersList();
        }
    }
    
    render() {
        const {layers, toggleLayer, toggleLayerLabels} = this.props.additionalLayersStore;
        const {classes} = this.props;
        return (
            <React.Fragment>
                <ExpansionPanel className={classes.additionalLayersContainer} square={true}>
                    <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <img alt='layers image' className = {classes.browseList} src={layersSvg} />
                    <Typography variant='h5' className={classes.heading}>Additional Layers</Typography>
                    </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell size='small' style={{padding: '0px 12px 0px 5px'}}>Show</TableCell>
                                        <TableCell size='medium'></TableCell>
                                        <TableCell size='small' padding='checkbox'>Label</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {layers.map((layer) => {
                                        return (
                                        <TableRow key={'additionalLayer'+ layer.additionalLayerID}>
                                            <TableCell padding='checkbox'><Checkbox inputProps={{'aria-label':'Show ' + layer.displayName}} aria-label={'Show ' + layer.displayName} color='primary' checked={layer.show} onChange={() => {toggleLayer(layer.additionalLayerID);}}/></TableCell>
                                            <TableCell padding='none'><Typography variant='h5'>{layer.displayName}</Typography></TableCell>
                                            <TableCell padding='none'>{layer.sourceType == Enums.AdditionalLayerType.GISDB && layer.hasLabels? <Switch color='primary' inputProps={{'aria-label':'Show labels for ' + layer.displayName}} arial-label={'Show labels for '+ layer.displayName} disabled={!layer.show} checked={layer.showLabels} onChange={() => {toggleLayerLabels(layer.additionalLayerID);}}/>: null}</TableCell>
                                        </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </ExpansionPanelDetails>
                </ExpansionPanel>
            </React.Fragment>
        )
    }

    static propTypes = {
        appMainStore: PropTypes.object,
        additionalLayersStore: PropTypes.object,
        browseStore: PropTypes.object,
        classes: PropTypes.object
    }
}

export default  withStyles(styles)((AdditionalLayersSelector))
