import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { compose, withProps } from "recompose"
import { GoogleMap, Marker, InfoWindow, KmlLayer} from "@react-google-maps/api"
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { withStyles, Typography, Link } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { action} from 'mobx'
import MarkerIconUrl from '../../../static/images/map/group.png';
import MarkerActiveIconUrl from '../../../static/images/map/active.png';
import { melbourneDefaultCenter } from '../../utils/constants';
import { debounce } from '../../utils/debounce';
import { ZoomControls } from './main-map.zoom-controls';
import DistrictIcon from '../../../static/images/search/DistrictIconMobile.svg';
import CouncilIcon from '../../../static/images/search/CouncilIconMobile.svg';
import DataLayer from './data-layer';
import * as Enums from '../../enums';
import {Tools} from '../../tools';

const styles = theme => ({
    markerToolTip: {
        borderColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
    zoomMobileContainer: {
        display: 'none',
        position: 'absolute',
        bottom: '64px',
        right: '16px',
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    mobileIconButton: {
        cursor: 'pointer',
        backgroundSize: '28px',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPositionY: '16px',
        backgroundPositionX: '16px',
        width: '52px',
        height: '52px',
        borderRadius: '50%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.04)',
    },
    searchThisLocationDialog: {
        padding: '5px',
        ['& h5']: {
            color: '#37444e',
        },
        ['& button']: {
            fontSize: theme.overrides.MuiTypography.h6.fontSize,
            fontWeight: 600,
            color: theme.palette.secondary.main,
            ['& #arrow']: {
                fontSize: theme.overrides.MuiTypography.h4.fontSize,
            }
        }
    }
});

@inject('appMainStore', 'mapStore', 'votingCentresStore', 'electionsStore', 'searchStore', 'electoratesStore', 'additionalLayersStore')
@observer
class MainMap extends React.Component {

    constructor(props){
        super(props)
        this.dblClickFired = false;
        this.mapCentre = melbourneDefaultCenter;
    }
    
    componentDidMount() {
        this.props.mapStore.getVictoriaBoundary();
    }

    renderElectionLayers() {
        if (this.props.appMainStore.applicationMode != Enums.ApplicationMode.Browse && google) {
            const {searchStore, votingCentresStore, classes} = this.props;
            const {selectedAddress} = searchStore;
            const visibleVotingCentres = votingCentresStore.visibleVotingCentres || [];
            const selectedAddressCoordinates = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;

            const scaledSize = (isWidthUp('sm', this.props.width))? new google.maps.Size(50, 60) : new google.maps.Size(44, 53);

            let selectedAddressMarker = selectedAddressCoordinates && <Marker position={selectedAddressCoordinates} />;
            
            let votingCentreMarkers = visibleVotingCentres.map((votingCentre) => (
                <Marker key={votingCentre.venueId} icon={{ url: votingCentresStore.isSelectedVotingCentre(votingCentre) ? MarkerActiveIconUrl : MarkerIconUrl, scaledSize, }}
                position={votingCentre.coordinates} onClick={this.handleSelectVotingCentreMarker(votingCentre)}
                >{
                (votingCentresStore.isSelectedVotingCentre(votingCentre) && isWidthUp('sm', this.props.width, false)) &&
                            <InfoWindow className={classes.markerToolTip}  position={votingCentre.coordinates} options={{disableAutoPan: true, pixelOffset:{height: 0, width: 0}}}>
                                <div>{votingCentre.venueName ? votingCentre.venueName.replace("EO - EVC", "") : votingCentre.venueName}</div>
                            </InfoWindow>
                }
                </Marker>
            ));              

            return (<React.Fragment>
                {selectedAddressMarker}
                {votingCentreMarkers}
            </React.Fragment>);
        } else {
            return null
        }
    }

    renderBrowseLayers() {
        if (this.props.appMainStore.applicationMode == Enums.ApplicationMode.Browse) {
            const {isMarkerShown, searchStore, additionalLayersStore} = this.props;
            const {selectedAddress} = searchStore;
            const selectedAddressCoordinates = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;
            let selectedAddressMarker = isMarkerShown && selectedAddressCoordinates && <Marker position={selectedAddressCoordinates} clickable={false} options={{ disableAutoPan: true }}/>;
            const kmlPrefix = window.location.protocol + '//' + window.location.host;
            return (
            <React.Fragment>
                {selectedAddressMarker}
                {additionalLayersStore.layers.map((layer, index) => {
                    switch (layer.sourceType) {
                        case  Enums.AdditionalLayerType.GISDB:
                            return <DataLayer key={'AdditionalLayer-' + index} dataSource={layer.features} map={this.map} featureId={'id'} style={() => {return additionalLayersStore.additionalLayerStyleFn(layer.layerStyleObject, layer.orderPosition)}} visible={layer.show}/>
                        case Enums.AdditionalLayerType.KML:
                            return layer.show? <KmlLayer key={'AdditionalLayer-' + layer.orderPosition} 
                            url={`${kmlPrefix}/api/additionalLayers/${layer.additionalLayerID}/kml`} //KML needs to be a publicly available url so this wont work in dev
                            zIndex={layer.orderPosition}/> : null
                    }                     
                })}
                {additionalLayersStore.layers.filter(layer=> layer.hasLabels).map(layer => {
                    return (<React.Fragment key={'Labels for ' + layer.additionalLayerID}> 
                        {layer.labels.map((label, index) => {
                            return <Marker key={'label ' + index} position={label.position} icon='/images/transparent-1x1.png' clickable={false} label={{text: label.text, fontFamily: 'poppins', fontSize: layer.labelSize + 'px', color: '#' + layer.labelColour, fontWeight: layer.labelBold? '700': '500'}} visible={layer.showLabels && layer.show}/>
                        })}
                    </React.Fragment>)
                })}
            </React.Fragment>
            );
        } else {
            return null
        }

    }


    searchThisLocationDialog(){
        const {searchThisLocationPlace, showSearchThisLocationDialog, closeSearchThisLocationDialog, searchLocation} = this.props.mapStore;
        const {classes} = this.props;
        return (showSearchThisLocationDialog?
            <Marker position={searchThisLocationPlace.geometry.location}>
                    <InfoWindow position={searchThisLocationPlace.geometry.location} options={{ disableAutoPan: true }} onCloseClick={closeSearchThisLocationDialog}>
                        <div className={classes.searchThisLocationDialog}>
                            <Typography variant='h5'>{searchThisLocationPlace.formatted_address}</Typography>
                            <Link component='button' onClick={searchLocation}>Search this address <span id='arrow'>→</span></Link>
                        </div>
                    </InfoWindow>
            </Marker>:null);
    }

    render() {
        const { classes, mapStore, votingCentresStore} = this.props;
        const { regions, districts, shadedBackground, wards, regionStyle, districtStyle, councilStyle, wardStyle, selectedElectorates, notSelectedDistrictStyle, selectedDistrict} = this.props.electoratesStore;
        const { vicBoundary} = mapStore;
        const { isAddressSearchInVictoria } = this.props.electionsStore;

        return (
            <React.Fragment>
                <GoogleMap
                    zoom={8}
                    center={this.mapCentre}
                    onLoad={this.handleSaveMapRef}
                    mapContainerStyle={{width: '100%', height: '100%'}}
                    options={{
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        streetViewControl: false,
                        zoomControl: false,
                        mapTypeControl: Tools.breakpoints.up('sm'),
                        mapTypeControlOptions: {mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE], position: google.maps.ControlPosition.RIGHT_BOTTOM},
                        draggableCursor: 'pointer',
                        fullscreenControl: false,
                        gestureHandling: 'greedy',
                        styles: [
                            { stylers: [{ 'lightness': 20 }, { 'saturation': -80 }] },
                            {
                                featureType: 'road',
                                elementType: 'geometry',
                                stylers: [ { color: '#ffffff'  } ]
                            },
                            {
                                featureType: 'road.highway',
                                elementType: 'labels.icon',
                                stylers: [{ visibility: 'off' }]
                            },
                            {
                                featureType: 'poi',
                                elementType: 'labels.icon',
                                stylers: [{ visibility: 'off' }]
                            }
                        ]
                    }}
                    onCenterChanged={this.handleViewportChanged}
                    onZoomChanged={this.handleViewportChanged}
                    onClick={this.handleMapClick}
                    onDblClick={this.handleMapDoubleClick}
                >
                    <DataLayer dataSource={vicBoundary} featureId={'id'} map={this.map} style={{strokeColor: '#757575', strokeOpacity: '1.0',strokeWeight: 4, fillOpacity: 0, clickable: false}} visible={vicBoundary.length>=1} />
                    <DataLayer dataSource={regions} map={this.map} featureId={'id'} style={regionStyle} visible={true}/>
                    <DataLayer dataSource={districts} map={this.map} featureId={'id'} style={districtStyle} visible={true}/>
                    <DataLayer dataSource={shadedBackground} map={this.map} featureId={'id'} style={notSelectedDistrictStyle} visible={this.props.appMainStore.applicationMode != Enums.ApplicationMode.Browse && !!selectedDistrict}/>
                    <DataLayer dataSource={wards} map={this.map} featureId={'id'} style={councilStyle} visible={true}/>
                    <DataLayer dataSource={wards} map={this.map} featureId={'id'} style={wardStyle} visible={true}/>
                    {this.searchThisLocationDialog()}
                    {this.renderElectionLayers()}
                    {this.renderBrowseLayers()}
                </GoogleMap>
                { !votingCentresStore.showVotingCenterDetails &&
                    isAddressSearchInVictoria && selectedElectorates.length != 0 && this.props.appMainStore.applicationMode != Enums.ApplicationMode.Browse &&
                    <div className={classes.zoomMobileContainer}>
                        <div className={classes.mobileIconButton} role="button" style={{ backgroundImage: `url(${selectedElectorates[0].electorateType == 'Council'? CouncilIcon : DistrictIcon})`, }}
                            onClick={() => this.onDistrictIconClick()} />
                    </div>
                }
            </React.Fragment>
        );
    }


    @action.bound handleMapClick(event) {
        setTimeout(() => {
            if (!this.dblClickFired) {
                this.props.mapStore.openSearchThisLocationDialog(event.latLng);
            } else {
                this.dblClickFired = false;
            }
        }, 200)
    }

    @action.bound handleMapDoubleClick() {
        this.dblClickFired = true;
    }
    
    handleSelectVotingCentreMarker = (votingCentre) => () => {
        const {electoratesStore, votingCentresStore, electionsStore, mapStore} = this.props
        electoratesStore.electoratesSummary = null;
        votingCentresStore.selectVotingCentre(votingCentre);        
        if (Tools.breakpoints.up('md')) {
            votingCentresStore.toggleShowVotingCenterDetails(true);
        } else if (Tools.breakpoints.up('sm')) {
            votingCentresStore.toggleShowVotingCenterDetails(!!votingCentresStore.selectedVotingCentre);
        }
        if (Tools.breakpoints.equals('sm')) {
            electionsStore.toggleExpandedInTablet(!!votingCentresStore.selectedVotingCentre);
        }
        mapStore.centerVotingCentre(votingCentre);
    }

    handleViewportChanged = debounce(() => {
        const { votingCentresStore, mapStore, appMainStore } = this.props
        mapStore.updateViewPort();        
        
        // Update Floating panel list items
        if (votingCentresStore.selectedVotingCentreId) {
            appMainStore.scrollToVotingCentreSearchResult(votingCentresStore.selectedVotingCentreId);
        }
    }, 500)

    handleSaveMapRef = ref => {
        const {mapStore} = this.props
        this.map = ref;
        if (ref) {
            this.addZoomControls(ref, mapStore);
            mapStore.setGoogleMaps({
                map: ref,
                googleSDK: google,
            });
        } else {
            mapStore.setGoogleMaps({
                map: null,
                googleSDK: null,
            });
        }
    }

    addZoomControls = (map, mapStore, isVicVotingCentresAvaliable) => {
        const zoomControlDiv = document.createElement('div');
        zoomControlDiv.index = 1;
        zoomControlDiv.className = 'gmaps-zoom-controls';
        ReactDOM.render(<ZoomControls map={map} mapStore={mapStore} isVicVotingCentresAvaliable={isVicVotingCentresAvaliable} />, zoomControlDiv);
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlDiv);
    }

    @action onDistrictIconClick = () => {
        const { selectedAddress } = this.props.searchStore;
        if (!this.props.appMainStore.rightDrawerOpen) {
            const selectedAddressCoordinates = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;
            this.props.votingCentresStore.setSelectedVotingCentre(null);
            this.props.electoratesStore.getElectoratesSummary(selectedAddressCoordinates.lat(), selectedAddressCoordinates.lng());
            this.props.appMainStore.openRightDrawer();
         } else {
             this.props.appMainStore.closeRightDrawer();
         }
    }
}

MainMap.propTypes = {
    classes: PropTypes.object.isRequired,
    isMarkerShown: PropTypes.bool.isRequired,
    searchStore: PropTypes.object,
    mapStore: PropTypes.object,
    votingCentresStore: PropTypes.object,
    additionalLayersStore: PropTypes.object,
    appMainStore: PropTypes.object,
    electoratesStore: PropTypes.object,
    electionsStore: PropTypes.object,
    width: PropTypes.string
};

export default compose(
    withStyles(styles),
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%`, width: '100vw', }} />
    }),
    withWidth()
)(MainMap);
