import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import MarkDownViewer from './markdown-viewer';
import { Link } from '@material-ui/core';

@inject('electionsStore', 'appMainStore')
@observer
class ElectionAlerts extends Component {

    handleFindOutMoreClick = () => {
        var searchAddress = document.getElementById('FP_SearchAddress').value;
        window.location.href = "/elections?address=" + searchAddress;
    }
    
    render() {
        const {classes, electionsStore} = this.props;
        const { electionsForAlert } = electionsStore;
        const noOfElections = electionsForAlert && electionsForAlert.length;
        const alertMessage = noOfElections == 0 ? '' : noOfElections > 1  ? "The following elections are now on:": 
                                                 "The following election is now on:";        
        return (<React.Fragment> {noOfElections > 0 &&
            <div className={classes.alertsContainer}>  
                <MarkDownViewer mdName = "electionMessage" mdValue = { alertMessage}/>
                {electionsStore.electionsForAlert.map((election, index) => {
                    return (                   
                        <div key={'election-' + index} className={classes.title}>
                            { noOfElections > 1 ? (index+1 + '. ' + election.electionName) : election.electionName}
                        </div>); 
                })}
                <Link name="findOutMore" target='_blank' underline='always'  onClick = {this.handleFindOutMoreClick}>Find out more</Link>    
        </div>}
        </React.Fragment>
        );
    }
    static propTypes = {
        electionsStore: PropTypes.object,
        appMainStore: PropTypes.object,
        classes: PropTypes.object,
        history: PropTypes.object
    }
}

export default ElectionAlerts;