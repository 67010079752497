import 'whatwg-fetch'
import { Tools } from '../tools';

class StaticGeometryService {
    getVictoriaBoundary() {
        let url = '/api/staticgeometry/vicboundary';
        return fetch(url).then(Tools.getResponseJson);
    }

    isUserLocationInVictoria(latitude, longitude){
        let url = `/api/staticgeometry/location/isVictoria?latitude=${latitude}&longitude=${longitude}`;
        return fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(Tools.getResponseJson);
    }
}

const staticGeometryService = new StaticGeometryService();

export default staticGeometryService;