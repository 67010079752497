import React from 'react';
import PlusIcon from '../../../static/images/map-controls/plus.png';
import MinusIcon from '../../../static/images/map-controls/minus.png';
import DistrictIcon from '../../../static/images/map-controls/district-gray.png';
import { withStyles } from '@material-ui/core/styles';
import { melbourneDefaultCenter } from '../../utils/constants'

const styles = (theme) => ({
    zoomContainer: {
        display: 'flex',
        flexDirection: 'column',
        objectFit: 'contain',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.04)',
        marginRight: '24px',
        marginBottom: '12px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    iconButton: {
        width: '32px',
        height: '32px',
        backgroundSize: '28px',
        backgroundColor: 'white',
        backgroundPositionY: '6px',
        backgroundRepeat: 'no-repeat',
        backgroundPositionX: '2px',
        cursor: 'pointer',
        ['&.mg-btm']: {
            borderRadius: '4px',
            marginBottom: '8px',
        },
        ['&:active']: {
            backgroundColor: '#efefef',
        }
    },
    iconButtonTop: {
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        borderBottom: `1px solid #e2e2e2`,
    },
    iconButtonBottom: {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    },
});

export const ZoomControls = withStyles(styles)(({ classes, map }) => {

    const handleZoomIn = () => {
        map.setZoom(map.getZoom() + 1);
    };
    const handleZoomOut = () => {
        map.setZoom(map.getZoom() - 1);
    };
    const handleZoomToVicBoundary = () => {
        map.setCenter(melbourneDefaultCenter);
        map.setZoom(7);
    };
    return (
        <React.Fragment>
            <div className={classes.zoomContainer}>
                <div id="icon-district" className={`${classes.iconButton} mg-btm`} role="button" aria-label='reset map view button' onClick={handleZoomToVicBoundary} style={{ backgroundImage: `url(${DistrictIcon})`, }} />
                <div id="icon-plus" className={`${classes.iconButton} ${classes.iconButtonTop}`} onClick={handleZoomIn} role="button" aria-label='map zoom in button' style={{ backgroundImage: `url(${PlusIcon})`, }} />
                <div id="icon-minus" className={`${classes.iconButton} ${classes.iconButtonBottom}`} onClick={handleZoomOut} role="button" aria-label='map zoom out button' style={{ backgroundPosition: '2px 12px', backgroundImage: `url(${MinusIcon})`, }} />
            </div>
        </React.Fragment>
    );
});