import { observable, action, computed, runInAction } from 'mobx';
import electoratesService from '../services/electorates.service';
import electionsService from '../services/elections.service';
import staticGeometryService from '../services/static-geometry.service';
import moment from 'moment-timezone';
import * as Enums from '../enums';

export default class ElectionsStore {    

    constructor(appMainStore, electoratesStore){
        this.appMainStore = appMainStore;
        this.electoratesStore = electoratesStore;
    }

    @observable elections = [];  
    @observable selectedElectionIndex = -1;   
    @observable searchInProgress = false;
    @observable isAddressSearchInVictoria = false;    
    @observable expandedInTablet = false;
    @observable leftDrawIsOpen = false;
    @observable isTimeOut = false;
    @observable isThereGeneralStateElection = false;
    @observable generalStateElectionName = '';

    @computed get selectedElection () {
        if (this.selectedElectionIndex != -1 && this.elections.length > this.selectedElectionIndex) 
        {
            return this.elections[this.selectedElectionIndex];
        } else {
            return null;
        } 
    }

    @computed get isBeforeEarlyVotingCutoff() {
        return this.selectedElection? moment.tz(this.selectedElection.earlyVotingFinish, 'Australia/Melbourne').isAfter(moment().tz('Australia/Melbourne')) : false;            
    }

    @computed get formattedElectionName () {
        let result = '';
        if(this.selectedElection) {
            const originalName = this.selectedElection.electionName;
            // if(this.selectedElection.electionCategoryId == Enums.ElectionCategory.State 
            //     &&  this.selectedElection.electionTypeId == Enums.ElectionType.ByElection){
            //     let word = 'district';           
            //     let index = originalName.toLowerCase().indexOf(word);
            //     result = originalName.slice(0, index + word.length ) + ' State' + originalName.slice(index + word.length) + ' Map';
            // }
            // else{
                result = originalName + ' Map';
            // }
        }
        return result;        
    }

    @computed get electionsForAlert() {        
        if(this.selectedElection){
            return this.elections.filter(election => {
                return election.electionId !== this.selectedElection.electionId;
            });
        }
        else {
            return this.elections;
        }
    }

    @action.bound getAddressElections(place) {
        if (place) {
            const coords = place.geometry.location;
            this.appMainStore.setBusy(true);
            this.searchInProgress = true;
            return electoratesService.getElectoratesSummary(coords.lat(), coords.lng()).then(async (electorates) => {
                if (electorates.region) {
                    this.isAddressSearchInVictoria = true;
                    this.appMainStore.isLocationInVictoria = true;
                } else {
                    await staticGeometryService.isUserLocationInVictoria(coords.lat(), coords.lng()).then(result => {
                        this.isAddressSearchInVictoria = result;
                        this.appMainStore.isLocationInVictoria = result;
                    });
                }
                if (this.electoratesStore.regions.length == 0 || 
                    this.electoratesStore.districts.length == 0 ||
                    this.electoratesStore.councils.length == 0 ||                
                    this.electoratesStore.wards.length == 0)
                {
                    await Promise.all([
                        this.electoratesStore.getRegions(),
                        this.electoratesStore.getDistricts(),
                        this.electoratesStore.getCouncils(),
                        this.electoratesStore.getWards(),
                    ]);
                }
                this.getElectorateElections(electorates);
            })
            .catch(() => {
                this.elections.clear();                
                this.searchInProgress = false;
            }).finally(() => {
                this.appMainStore.setBusy(false);
            });
        }
    }   

    @action async getElectorateElections(electorates) {

        this.isTimeOut = false;

        let electorateIds = [];
        if (electorates.region) electorateIds.push(electorates.region.electorateId);        
        if (electorates.district) electorateIds.push(electorates.district.electorateId);
        if (electorates.council) electorateIds.push(electorates.council.electorateId);
        if (electorates.ward) electorateIds.push(electorates.ward.electorateId);

        if (electorateIds.length) {            
            this.appMainStore.setBusy(true);
            await electionsService.getReleventElections(electorateIds).then(relatedElections => {
                let selectedElectionId;
                if (this.selectedElection) {
                    selectedElectionId = this.selectedElection.electionId;
                }
                runInAction(() => {
                    this.elections.replace(relatedElections.map(election => {
                        election.electionElectorates.forEach((ee, index, electionElectorates) => {
                            if (electorates.district && electorates.district.electorateId == ee.electorateId) {
                                electionElectorates[index] = {...electorates.district, ...ee, parentElectorate: electorates.region, containsAddress: true}
                            } else if (electorates.region && electorates.region.electorateId == ee.electorateId) {
                                electionElectorates[index] = {...electorates.region, ...ee, containsAddress: true}        
                            } else if (electorates.council && electorates.council.electorateId == ee.electorateId) {
                                electionElectorates[index] = {...electorates.council, ...ee, containsAddress: true}        
                            } else  if (electorates.ward && electorates.ward.electorateId == ee.electorateId) {
                                electionElectorates[index] = {...electorates.ward, ...ee, parentElectorate: electorates.council, containsAddress: true}        
                            } else {
                                let electorate = this.electoratesStore.findElectorateById(ee.electorateId);
                                electionElectorates[index] = {...electorate, containsAddress: false}                                        
                            }
                        });                        
                        return election;
                    }));
                    if (selectedElectionId) {
                        //Need to reselect the original election incase its index has changed
                        this.setSelectedElection(selectedElectionId);                            
                    }
                });
            })
            .catch(err => {
                if (err.name === 'AbortError') {
                    console.error('Search for address in VIC took more than 30 seconds. Automatically cancelled.');
                    this.isTimeOut = true;
                }
                this.elections.clear();
            }).finally(() => {
                this.appMainStore.setBusy(false);
                this.searchInProgress = false;
            });
        } else {                    
            this.appMainStore.setBusy(true);                        
            electionsService.getCurrentElectionSummary({electionType: Enums.ElectionType.General, electionCategory: Enums.ElectionCategory.State})
            .then(electionResults => {
                electionResults.forEach(election => {election.electionElectorates.forEach((ee, index, electionElectorates) => {                            
                    let electorate = this.electoratesStore.findElectorateById(ee.electorateId);
                    electionElectorates[index] = {...electorate, containsAddress: false}                                        
                });});   
                this.elections.replace(electionResults);
            }).catch(err => {
                if (err.name === 'AbortError') {
                    console.error('Search for address outside VIC took more than 30 seconds. Automatically cancelled.');
                    this.isTimeOut = true;
                }
                this.elections.clear();
            }).finally( () => {
                this.appMainStore.setBusy(false);
                this.searchInProgress = false;
            });                    
        }
    }

    selectedElectionContainsElectorate(electorateIds) {
        let electorate = null;
        let foundIndex = -1;
        if (this.selectedElection) {
            this.selectedElection.electionElectorates.forEach(electionElectorate => {
                const thisFoundIndex = electorateIds.indexOf(electionElectorate.electorateId);
                if (thisFoundIndex > -1 && (thisFoundIndex < foundIndex || foundIndex == -1)) {
                    foundIndex = thisFoundIndex;
                    electorate = electionElectorate;
                }
            });
        }
        return electorate;
    }

    @action.bound setSelectedElection(selectedElectionId){
        let newSelectedIndex = -1
        if (selectedElectionId) {
            this.elections.some((election, index) => {
                if (election.electionId == selectedElectionId) {
                    newSelectedIndex = index;
                    return true;
                } else {
                    return false;
                }
            });                            
        } 
        if (this.selectedElectionIndex != newSelectedIndex) {
            this.selectedElectionIndex = newSelectedIndex;        
            if (this.selectedElection){
                let electionMode = Enums.ApplicationMode.State;
                switch (this.selectedElection.electionCategoryId) {
                    case Enums.ElectionCategory.Municipal:
                        switch (this.selectedElection.electionTypeId) {
                            case Enums.ElectionType.General:
                                electionMode =Enums.ApplicationMode.Council;
                                break;
                            case Enums.ElectionType.ByElection:
                                electionMode =Enums.ApplicationMode.CouncilBy;                        
                                break;
                        }
                        break;
                    case Enums.ElectionCategory.State:
                        switch (this.selectedElection.electionTypeId) {
                            case Enums.ElectionType.General:
                                electionMode = Enums.ApplicationMode.State;
                                break;
                            case Enums.ElectionType.ByElection:
                                electionMode =Enums.ApplicationMode.StateBy;                        
                                break;
                        }               
                    break;
                }
                this.appMainStore.setApplicationMode(electionMode);
            }
        }
    }

    @action.bound toggleExpandedInTablet = (value) => {
        this.expandedInTablet = value || !this.expandedInTablet;
        this.leftDrawIsOpen = value || !this.leftDrawIsOpen;
    }

    @action checkMajorElection = () => {

        this.isTimeOut = false;
        
        electionsService.getCurrentElectionSummary({electionType: Enums.ElectionType.General, electionCategory: Enums.ElectionCategory.State})
        .then((response) => {
            runInAction(() => {                
                this.isThereGeneralStateElection = response.length ? true : false;
                this.generalStateElectionName = response.length? response[0].electionName : '';
                });
        }).catch(err => {
            if (err.name === 'AbortError') {
                console.error('Search for major election took more than 30 seconds. Automatically cancelled.');
                this.isTimeOut = true;
            }
        });                        
    }
}
