import Home from '../../static/images/home.svg';
import Faq from '../../static/images/faq.svg';
import StartOver from '../../static/images/start-over.svg';
import MoreInfo from '../../static/images/more-info.svg';

export const melbourneDefaultCenter = { lat: -36.817741, lng: 144.922896 };
export const vicBoundaryViewportPoints = [
    { coordinates: { lat: -39.196686, lng: 149.873940 } },
    { coordinates: { lat: -33.750823, lng: 149.745857 } },
    { coordinates: { lat: -33.990104, lng: 140.929490 } },
    { coordinates: { lat: -39.147038, lng: 140.790733 } },
];

export const footerNavigationItems = {
    Privacy: {
        label: 'Privacy',
    },
    Legal: {
        label: 'Legal',
    },
    Accessibility: {
        label: 'Accessibility',
    },
    Contact: {
        label: 'Contact',
    }
};

export const navigationItems = {
    StartOver: {
        label: 'Start Over',
        icon: StartOver,
    },
    VECHome: {
        label: 'VEC Home',
        icon: Home,
    },
    FAQHelp: {
        label: 'FAQ & Help',
        icon: Faq,
    },
};

export const informationItem = {
    
    MoreInfo: {        
        icon: MoreInfo,
    },
};

export const accessibilityItem = {
    IWA: {
        label: 'Independent wheelchair access',
    },
    AWA: {
        label: 'Wheelchair access with assistance',
    },
    LNWA: {
        label: 'Limited or no wheelchair access',
    },
    ALL: {
        label: 'Show all venues',
    }
};

export const wheelChairAccessibilityType = {
    IWA: {
        label: 'Independent wheelchair access (IWA)',
    },
    AWA: {
        label: 'Wheelchair access with assistance (AWA)',
    },
    LNWA: {
        label: 'Limited or no wheelchair access (LNWA)',
    }
};
