import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import AddressSearchBox from '../../common/address-search-box/address-search-box';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import HeaderTitle from '../../common/header-title';
import ElectionResults from './election-results';
import { navigationItems } from '../../../utils/constants'
import * as Enums from '../../../enums';
import ballotImage from '../../../../static/images/ipad-lady.png';
import Hidden from '@material-ui/core/Hidden';
import BrowseFooter from './browseFooter';
import StartOver from '../../common/start-over';

const styles = theme => {
    const { zIndex } = theme;
    return ({
        landingPageContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: zIndex.drawer + 100,
            minHeight: '100vh',
            maxHeight: '100vh',
            width: '100vw',
            overflowX: 'hidden',
            overflowY: 'auto',
            display: 'flex',            
            flexDirection: 'column',
            boxSizing: 'border-box',
            [theme.breakpoints.down('xs')]: {
                minHeight: 'calc(100vh - 80px)',
                maxHeight: 'calc(100vh - 80px)',
            }
        },
        searchPanel: {
            overflowY: 'visible',
            display: 'flex',
            width: '100vw',
            marginTop: '56px',
            padding: '0px 89px 0px 166px',
            backgroundColor: theme.palette.secondary.webpink,
            minHeight: '475px',
            height: 'calc(100vh - 380px)',
            maxHeight: 'calc(100vh - 380x)',
            [theme.breakpoints.down('sm')]: {
                minHeight: '640px',              
                paddingLeft: '45px',   
            },
            [theme.breakpoints.down('xs')]: {
                minHeight: '394px' ,                   
                paddingLeft: '20px',
            },
            boxSizing: 'border-box',
        },
        searchContainer: {
            paddingTop: '184px',
            width: '767px',
            [theme.breakpoints.down('sm')]: {
                top: '190px',
                left: '45px',
                width: 'calc(100vw - 90px)',
                paddingRight: '45px'                 
            },
            [theme.breakpoints.down('xs')]: {
                width: 'calc(100vw - 40px)',
                left: '20px',
                paddingTop: '50px',
                paddingRight: '20px',   
            },
            boxSizing: 'border-box',            
        },
        heading: {
            color: theme.palette.navy.main,
            fontSize: '44px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            display: 'inline-block',
            lineHeight: '1.13',
            letterSpacing: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: '44px',
                lineHeight: '1.14'
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '42px',
            }
        },
        searchPromptText: {
            color: theme.palette.secondary.charcoal,
            marginTop: '35px',
            marginBottom: '33px',
            fontSize: '18px',
            fontWeight: '400',
            fontStretch: 'normal',
            lineHeight: '1.89',
            letterSpacing: '0.4px',
            width: '541px',
            '& a': {
                fontWeight: '600',
            },
            [theme.breakpoints.down('sm')]: {
                lineHeight: '1.56',               
                width: 'calc(100vw - 90px)',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '15px',
                width: 'calc(100vw - 40px)',
                height: '84px'
            }
        },
        searchBox: {
            paddingBottom: '194px',
            [theme.breakpoints.down('sm')]: {
                paddingBottom: '192px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingBottom: '64px',
            }
        },
        inputWrapper: {
            margin: '8px 12px 19px 28px',
            [theme.breakpoints.down('sm')]: {
                margin: '8px 12px 19px 28px'
            },
            [theme.breakpoints.down('xs')]: {
                margin: '12px 4px 12px 10px'
            },
            '&:after': {
                background: 'white' + ' !important',
                left: 'calc(100% - 60px)',
            },
        },
        appBar: {
            position: 'absolute',
            zIndex: zIndex.drawer + 101,
            backgroundColor: theme.palette.secondary.webpink,
            color: theme.palette.navy.main
        },
        homeButton: {
            verticalAlign: 'middle',
            width: '24px',
            height: '24px',
            padding: '0px 0px 9px 0px ',
            [theme.breakpoints.down('sm')]: {
                padding: '18px 15px 20px 5px ',
            },
            [theme.breakpoints.down('xs')]: {
                padding: '18px 15px 20px 5px ',
            }
        },
        homeLabel: {
            fontSize: '18px',
            fontWeight: 'bold',
            lineHeight: '1.67',
            letterSpacing: '0.4px',
            paddingLeft: '10px',
            width: '150px',
        },        
        ballotImage: {
            width: '334px',
            height: '586px',
            [theme.breakpoints.down('md')]: {
                width: '234px',
                height: '436px',
            },
        },
        ballotImageDiv: {
            paddingRight: '90px',
            paddingTop: '105px',
            zIndex: 1,
        },
        rightNavContainer: {            
            textAlign: 'right', 
            paddingRight: '0px',
            [theme.breakpoints.down('md')]: {
                paddingRight: '0px',
                paddingLeft: '0px',
            }
        }
    });
}

@inject('mapStore', 'appMainStore', 'electionsStore', 'searchStore', 'browseStore')
@observer
class LandingPage extends Component {

    @observable searchAddress = null;    

    @action setSearchAddress (address) {
        this.searchAddress = address && address[0];
    }
    componentDidMount() {
        this.props.electionsStore.checkMajorElection();
        this.props.appMainStore.setApplicationMode(Enums.ApplicationMode.State);
        this.props.appMainStore.setShowGenericHeader(false);
        if(this.props.appMainStore.applicationMode != Enums.ApplicationMode.Browse){
            document.title = "Election venue locator";
        }

    }

    vecHomeClickHandler = () => {
        window.location.assign('https://www.vec.vic.gov.au/');
    }

    render() {
        const { classes, mapStore, appMainStore, searchStore, electionsStore } = this.props;       
        const styling = {
            paddingLeft: '0rem'
        };

        return (
            <React.Fragment>
                {
                    !appMainStore.showGenericHeader &&
                    <AppBar className={classes.appBar}>
                        <Toolbar className={classes.appToolbar}>
                            <Grid container alignItems='center'>
                                <Grid item xs={1}>
                                <IconButton
                                    name='VEC Home button'
                                    className={classes.homeButton}
                                    color="inherit"
                                    aria-label="Menu"
                                    id="btn-header-menu"
                                    onClick={this.vecHomeClickHandler}>
                                    <img src={navigationItems.VECHome.icon} alt='Home Icon' />
                                </IconButton>
                                <Hidden only={['sm', 'xs']}>
                                    <Link href='https://www.vec.vic.gov.au/' color='secondary'><span className={classes.homeLabel}>VEC Home</span></Link>
                                </Hidden>
                                </Grid>
                                <Grid item xs={10}>
                                    <HeaderTitle customeStyle={styling} headerTitleText='Election venue locator' />
                                </Grid>
                                <Grid item xs={1} className={classes.rightNavContainer}> 
                                    <StartOver/>
                                </Grid>
                            </Grid> 
                        </Toolbar>
                    </AppBar>
                }
                <div className='landing-page-container' style={{display: '-webkit-flex'}}>
                    {!searchStore.searchPerformed ? <div>
                        <div className={classes.searchPanel} style={{display: '-webkit-flex'}}>
                            <div className={classes.searchContainer}>
                                <Typography className={classes.heading}> Search your address </Typography>
                                {electionsStore.isThereGeneralStateElection && 
                                <Typography className={classes.searchPromptText}>
                                    Find your nearest voting centre and other voting options for the <Link href="https://www.vec.vic.gov.au" className={classes.address}>2022 State election</Link>.
                                </Typography>}
                                {!electionsStore.isThereGeneralStateElection && 
                                <Typography className={classes.searchPromptText}>
                                    Search your <Link href="https://enrolment.vec.vic.gov.au/" className={classes.address}>enrolled address</Link> in Victoria to find out about upcoming elections and voting centres.
                                </Typography>}
                                <AddressSearchBox
                                    placeholder='Start typing your address'
                                    defaultAddress={this.searchAddress}
                                    google={mapStore.googleSDK}
                                    map={mapStore.map}
                                    onPlacesChanged={this.handleSearchAddress}
                                    isLandingInput={true} />
                            </div>
                            <Hidden only={['sm', 'xs']}>
                                <div className={classes.ballotImageDiv}>
                                    <img alt='image lady using an Ipad' src={ballotImage} className={classes.ballotImage} ></img>
                                </div>
                            </Hidden>
                         </div>   
                        <BrowseFooter />
                    </div> :
                        <ElectionResults initialAddress={searchStore.selectedAddress} />
                    }                    
                </div>
            </React.Fragment>
        );
    }

    handleSearchAddress = (places) => {
        const { electionsStore,searchStore } = this.props;
        this.setSearchAddress(places); 
        if (places && places.length) {
            electionsStore.getAddressElections(places[0]).then(() => {
                searchStore.setSelectedAddress( places );
                searchStore.setSearchPerformed(true);
            });
        }
    }

    handleBrowseElectoratesClick = (event) => {
        event.preventDefault();
        this.props.appMainStore.history.push('/');        
    }
}

LandingPage.propTypes = {
    classes: PropTypes.object.isRequired,
    mapStore: PropTypes.object,
    appMainStore: PropTypes.object,
    electionsStore: PropTypes.object,
    searchStore: PropTypes.object,
    browseStore: PropTypes.object,
}

export default withStyles(styles)(LandingPage);