import React from "react";

export const SVGProps = {
    style: {},
    fill: "transparent",
    width: "100%",
    className: "",
    viewBox: "0 0 32 32"
}
  

export const DirectionIcon = (SVGProps) => {
  return(<svg
    width={SVGProps.width}
    style={SVGProps.style}
    height={SVGProps.width}
    viewBox={SVGProps.viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${SVGProps.className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
   <path fill={SVGProps.fill} fillRule="nonzero" d="M19.224 9.068l-8.78-8.78c-.38-.38-1-.38-1.376 0l-8.78 8.78c-.38.38-.38 1 0 1.38l8.78 8.776v.005c.38.38 1 .38 1.38 0l8.781-8.78c.38-.386.38-1-.005-1.38zm-7.517 3.127V9.756H7.805v2.927H5.854V8.78c0-.541.434-.975.975-.975h4.878v-2.44l3.415 3.415-3.415 3.415z"/>
  </svg>
)}

export const WheelchairIcon = (SVGProps) => {
    return(<svg
        width={SVGProps.width}
        style={SVGProps.style}
        height={SVGProps.width}
        viewBox={SVGProps.viewBox}
        xmlns="http://www.w3.org/2000/svg"
        className={`svg-icon ${SVGProps.className || ""}`}
        xmlnsXlink="http://www.w3.org/1999/xlink"
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-8.000000, 0.000000)" fill={SVGProps.fill} fillRule="nonzero">
                <g transform="translate(8.000000, 0.000000)">
                    <g>
                        <g>
                            <path d="M13.3038182,12.15376 L11.9151136,12.78144 L9.96752273,8.4336 L9.77852273,8.0136 L9.32129545,8.01984 L5.11254545,8.08 L5.07388636,7.17664 L8.56211364,7.17664 L8.56211364,5.77168 L5.01629545,5.77168 L4.89379545,2.85424 C5.418,2.62448 5.78502273,2.09968 5.78502273,1.4888 C5.78518182,0.6656 5.12177273,0 4.30436364,0 C3.48536364,0 2.82290909,0.6656 2.82290909,1.4888 C2.82290909,2.0088 3.08938636,2.46688 3.49125,2.73152 L3.74643182,8.8216 L3.77363636,9.50368 L4.45311364,9.4944 L8.88252273,9.4312 L10.92875,13.99536 L11.2155909,14.6352 L11.8500455,14.34896 L13.87575,13.43424 L13.3038182,12.15376 Z"></path>
                            <path d="M8.96652273,11.77424 L8.77879545,11.7296 C8.3685,13.4168 6.87161364,14.5952 5.14197727,14.5952 C3.07634091,14.5952 1.39665909,12.9064 1.39665909,10.82976 C1.39665909,9.55088 2.03413636,8.3856 3.07793182,7.69136 L2.90102273,6.47168 L2.77852273,6.23536 C1.06431818,7.128 0,8.88816 0,10.82976 C0,13.67984 2.30681818,16 5.14197727,16 C7.09370455,16 8.82588636,14.90672 9.69675,13.2288 L8.96652273,11.77424 Z" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>    
    </svg>
)}

export const EarlyVotingIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"> 
                <path fillRule="evenodd" d="M46.61 9.538c-1.853-1.814-4.868-1.813-6.72 0l-1.768 1.731V1.772C38.122.795 37.31 0 36.313 0H1.809C.812 0 0 .795 0 1.772V46.23C0 47.206.812 48 1.809 48h34.504c.998 0 1.809-.794 1.809-1.77V24.433l8.488-8.314a4.59 4.59 0 0 0 0-6.581zM35.462 45.715H2.66a.322.322 0 0 1-.325-.319V2.606c0-.177.145-.32.325-.32h32.803c.18 0 .326.143.326.32v10.95L22.239 26.823l-1.777 1.74-1.014.993a2.83 2.83 0 0 0-.742 1.235l-1.82 6.242c-.134.457-.004.947.338 1.282a1.353 1.353 0 0 0 1.31.333l1.928-.54 1.866-.522 2.58-.722c.476-.134.91-.384 1.26-.728l9.62-9.42v18.68a.322.322 0 0 1-.326.318zM22.328 34.544l.873.896-.873.245-1.866.522-1.518.426 1.25-4.285.268.275 1.866 1.92zm15.794-12.698l-2.334 2.286-10.76 10.537-2.7-2.779-1.28-1.315 1.28-1.253L35.16 16.755l.628.648 2.334 2.418 1.004 1.04-1.004.985zm2.326-2.277l-2.326-2.41-1.638-1.698 1.638-1.605.955-.937 3.966 4.108-2.595 2.542zm4.72-4.625l-.804.79-3.965-4.108.81-.794c1.193-1.17 3.185-1.099 4.281.218.954 1.145.746 2.849-.321 3.894zM20.462 39.848H8.324V27.962h12.137v.602l1.777-1.74a1.184 1.184 0 0 0-1.095-.69h-13.5c-.655 0-1.186.494-1.186 1.103v13.336c0 .61.531 1.104 1.185 1.104h13.501c.654 0 1.186-.495 1.186-1.104v-2.986l-1.868.522v1.739zM15.48 17.665h-1.797V12.06l-1.767.507v-1.333l3.399-1.151h.165v7.582zm5.562 4.202H7.54c-.654 0-1.185-.494-1.185-1.103V7.428c0-.61.531-1.104 1.185-1.104h13.502c.653 0 1.185.494 1.185 1.104v13.336c0 .61-.532 1.103-1.185 1.103zm-12.82-1.828h12.137V8.153H8.221V20.04z"/>
            </svg>);
}

export const ElectionDayVotingIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="40" height="48" viewBox="0 0 40 48">
                <path fillRule="evenodd" d="M25.669 17.56h-4.571c-.63 0-1.144-.524-1.144-1.17 0-.646.513-1.17 1.144-1.17h4.57c.632 0 1.144.524 1.144 1.17 0 .646-.512 1.17-1.143 1.17zm-8.912 1.991h-2.948c-.784 0-1.422-.653-1.422-1.456v-3.037c0-.793.63-1.44 1.404-1.44h2.966c.784 0 1.421.654 1.421 1.457v3.02c0 .803-.637 1.456-1.421 1.456zm-2.77-1.639h2.59V15.26h-2.59v2.653zM25.67 9.365h-4.571c-.63 0-1.144-.524-1.144-1.17 0-.646.513-1.17 1.144-1.17h4.57c.632 0 1.144.524 1.144 1.17 0 .646-.512 1.17-1.143 1.17zm-8.912 1.991h-2.948c-.784 0-1.422-.653-1.422-1.456V6.863c0-.793.63-1.44 1.404-1.44h2.966c.784 0 1.421.654 1.421 1.457V9.9c0 .803-.637 1.456-1.421 1.456zm-2.77-1.639h2.59V7.064h-2.59v2.653zm22.475 7.844h-4.939V1.476C31.523.662 30.844 0 30.008 0H9.991c-.836 0-1.514.662-1.514 1.476v16.085H3.63c-.583 0-1.093.405-1.238.983L.319 26.822C.107 27.669 0 28.54 0 29.414v17.277C0 47.415.573 48 1.278 48h37.408c.706 0 1.277-.584 1.279-1.307l.03-17.486a9.23 9.23 0 0 0-.256-2.187l-2.036-8.463a1.284 1.284 0 0 0-1.24-.996zm-25.7-15.22h18.476v19.902H10.762V2.341zm-6.21 17.561h3.627c.164 0 .298.136.298.304v2.037H7.429c-.631 0-1.143.525-1.143 1.171 0 .647.512 1.172 1.143 1.172H32.57c.631 0 1.143-.525 1.143-1.172 0-.646-.512-1.17-1.143-1.17h-1.048v-2.038a.3.3 0 0 1 .296-.304h3.629a.3.3 0 0 1 .288.23l1.566 6.418a.302.302 0 0 1-.288.377H2.986a.302.302 0 0 1-.288-.377l1.566-6.419a.3.3 0 0 1 .288-.229zm32.831 25.757H2.586a.303.303 0 0 1-.3-.307V29.576c0-.17.133-.308.3-.308h34.827c.165 0 .299.137.299.308l-.03 15.777a.302.302 0 0 1-.299.306z"/>
            </svg>);
}

export const PostalVotingIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
        <defs>
          <style>
            {
              "\n      .cls-1 {\n        fill: none;\n        stroke: #0e3a6c;\n        stroke-linecap: round;\n        stroke-linejoin: round;\n        stroke-width: 1.92px;\n      }\n    "
            }
          </style>
        </defs>
        <polyline
          className="cls-1"
          points="46.36 18.61 46.36 44.91 1.64 44.91 1.64 18.61"
        />
        <polyline className="cls-1" points="1.64 44.91 24 27.23 46.36 44.91" />
        <polyline className="cls-1" points="38.99 13.49 46.36 18.61 29.92 31.91" />
        <polyline className="cls-1" points="15.85 8.74 24 3.09 32.15 8.74" />
        <polyline className="cls-1" points="18.08 31.91 1.64 18.61 9.01 13.49" />
        <polyline
          className="cls-1"
          points="9.01 24.56 9.01 8.74 38.99 8.74 38.99 24.56"
        />
        <line className="cls-1" x1={24} y1={16.42} x2={34.65} y2={16.42} />
        <line className="cls-1" x1={24} y1={20.28} x2={34.65} y2={20.28} />
        <rect className="cls-1" x={15.32} y={15.85} width={5} height={5} />
      </svg>);
}

export const ElectionOfficeIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <defs>
      <style>
        {
          ".cls-1,.cls-2{stroke-linecap:square;fill:none;stroke:#0e3a6c;stroke-linejoin:round;stroke-width:1.92px}.cls-2{stroke-linecap:round}"
        }
      </style>
    </defs>
    <path d="m14.46 9.78 29.72 5.71v23.19H14.46V9.78zM12.03 9.32 47 16.03M47 38.68H14.46" className="cls-2" />
    <path d="M19.49 24.62H32.6v14.07H19.49zM32.6 24.62h6.55v14.07H32.6zM26.04 24.62v14.06" className="cls-1" />
    <path d="M1 38.68h13.46V15.23L1 19.04" className="cls-2" />
    <path d="M14.46 15.23 3.82 18.24v20.44h10.64V15.23z" className="cls-2" />
    <path d="M8.33 24.62h1.63v1.85H8.33z" className="cls-1" />
  </svg>);
}

export const NavForwardArrowIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fillRule="evenodd" d="M16.186 4l-1.55 1.588 5.164 5.289H0v2.246h19.8l-5.164 5.289L16.186 20 24 12z"/>
    </svg>);
} 

export const ReloadIcon = (SVGProps) => {
    return (<svg {...SVGProps} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
        <path fill={SVGProps.fill} fillRule="nonzero" d="M8.484 0C13.174-.008 17 3.811 17 8.5a8.5 8.5 0 0 1-14.196 6.31.412.412 0 0 1-.016-.598l1.36-1.36a.41.41 0 0 1 .56-.018A5.716 5.716 0 0 0 8.5 14.258c3.215 0 5.732-2.604 5.758-5.708.027-3.272-2.642-5.835-5.81-5.808a5.716 5.716 0 0 0-3.88 1.552L6 5.724a.823.823 0 0 1-.582 1.405H.823A.823.823 0 0 1 0 6.306V1.712c0-.733.886-1.1 1.404-.582l1.224 1.224A8.471 8.471 0 0 1 8.484 0z"/>
    </svg>);
}