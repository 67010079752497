import 'whatwg-fetch'
import { Tools } from '../tools';

class ElectionsService {
    getCurrentElectionSummary(electionOptions) {        
        let url = '/api/elections/current/summary';   
        let request = {
            method: 'post',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(electionOptions),
            timeout: 30000
        }
        return fetchWithTimeout(url, request).then(Tools.getResponseJson);
    }

    getReleventElections(electorateIds) {
        let url = '/api/elections/current/';   
        let request = {
            method: 'post',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(electorateIds),
            timeout: 30000
        }
        return fetchWithTimeout(url,request).then(Tools.getResponseJson);
    }

}

// https://dmitripavlutin.com/timeout-fetch-request/
async function fetchWithTimeout(resource, options = {}) {
    const { timeout = 30000 } = options;
    
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    const response = await fetch(resource, {
      ...options,
      signal: controller.signal  
    });
    clearTimeout(id);
    return response;
  }


const electionsService = new ElectionsService();

export default electionsService;