import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Reload from '../../../static/images/reload.svg';
import {Tooltip, IconButton } from '@material-ui/core';

const style = ({
    startOver : {
        width: '21px',
        height: '21px',      
    },
    container: {
        display: 'inline-block' 
    }
});

class StartOver extends Component {

    handleReload = () => {               
        let url = window.location.origin + window.location.pathname;
        window.location.replace(url);
    }
    render() {
        const { classes } = this.props;
        return (
            <Tooltip id={ 'id_oz2d8zq2m'} key={ 'uk_umuvgh5sw'} title={ 'Start over'} className={classes.container}>
                <div>
                    <IconButton onClick={this.handleReload} aria-label='Start over button'>
                        <img alt='Start over' className = {classes.startOver} src={Reload} />
                    </IconButton>
                </div>
            </Tooltip>
           
        );
    }
}

StartOver.propTypes = {
    classes: PropTypes.object.isRequired   
};

export default withStyles(style)(StartOver);