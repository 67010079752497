import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, action } from 'mobx';
import * as Enums from '../../enums';
import AddressSearchBox from '../common/address-search-box/address-search-box';
import VotingCenterSearchResults from './voting-center-search-results';
import AddressSearchFiltersBox from './address-search-filters-box';
import RightDrawer from './right-drawer';
import BottomDrawer from './bottom-drawer';
import LandingPage from './landing/landing-page';
import ElectionsTheme from './elections-theme';
import Hidden from '@material-ui/core/Hidden';
import { withStyles, IconButton, Drawer, Grid, Dialog, Typography, DialogContent, Button, Collapse } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ExpandMoreIcon  from '@material-ui/icons/ExpandMore';
import ExpandLessIcon  from '@material-ui/icons/ExpandLess';
import {ReloadIcon} from '../common/svg-icons';
import Footer from '../browse/footer';
import VotingCentreDetailsPanel from './voting-centre-details-panel';
import DistrictDetailsPanel from './district-details-panel';
import CouncilDetailsPanel from './council-details-panel';
import mapIcon from '../../../static/images/map.svg';
import browseList from '../../../static/images/list.svg';
import {Tools} from '../../tools';

const styles = (theme) => ({
    paperAnchor : {
         marginTop: '65px',
         overflow: 'visible',
         background: 'transparent',
         border: 'none',
    },
    drawerClose: {
        width: '50px',
    },
    expansionDiv: {
        height: '70px',
        width:'32px',
        background: 'white',
        boxShadow: '4px 5px 10px 1px #8a8a8a',
    },
    arrowIcon : {
        paddingTop: '23px',
        color : theme.palette.navy.main + ' !important'
    },
    tabletGrid : {
        background: 'white',
        height: '100%',        
        [theme.breakpoints.down('sm')]: {
            width: '400px',
            height: 'calc(100vh - 65px)',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    },
    desktopContainer: {
        maxHeight: 'calc(100vh - 163px)',
        display: 'flex',
        flexDirection: 'column',
    },
    mobileContainer: {
        overflowY: 'visible',
        top: '57px',
        zIndex: 3,
        position: 'fixed',
        width: '100%'
    },
    addressOutOfElectionDialog: {
        color: theme.palette.secondary.charcoal,
        padding: '27px 21px 47px 21px',
        [theme.breakpoints.down('sm')]: {
            padding: '27px 21px 32px 21px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '8px 15px 18px 15px',
        },
    },
    addressOutOfElectionDialogCollapseText: {
        background: '#edf0f4',
        padding: '19px 22px 19px 23px',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '15px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '10px 15px 18px 15px',
            marginBottom: '10px',
        }
        
    }
});

@inject('appMainStore', 'searchStore', 'mapStore', 'votingCentresStore', 'landingDialogStore', 'electionsStore', 'electoratesStore' )
@observer
class ElectionsPanel extends React.Component {

    constructor (props) {        
        super(props);      
        
        this.state = {
            footer : {
                text : 'Show list',
                icon : browseList
            }, 
            expandAddressOutOfElectionDialogDetails: false
        };
        this.drawerRef = null;
    } 
    componentDidMount() {
        this.props.appMainStore.setApplicationMode(Enums.ApplicationMode.State)
    }

    footerTextOptions = {
        showMap: "Show map",
        showVotingCentres: "Show list"
    };
   
    @computed get isBottomDrawerOpen() {
        return (Tools.breakpoints.down('sm') && !this.props.appMainStore.mobileSearchListOpen && !this.props.votingCentresStore.showVotingCenterDetails && !!this.props.votingCentresStore.selectedVotingCentre);
    }


    onFooterMenuClick = () => {        
        switch(this.state.footer.text) {
            case this.footerTextOptions.showMap: 
                    this.setState({footer: {text : this.footerTextOptions.showVotingCentres, icon: browseList }});
                    break;
            case this.footerTextOptions.showVotingCentres: 
                    this.setState({footer: {text : this.footerTextOptions.showMap, icon: mapIcon }});
                    break;
        }
    }

    toggleExpandedInTablet = () => {
        const {electionsStore, votingCentresStore} = this.props;
        electionsStore.toggleExpandedInTablet();
        if (!electionsStore.expandedInTablet) {
            votingCentresStore.toggleShowVotingCenterDetails();
        }
    }

    render() {
        const { searchStore, appMainStore, mapStore, votingCentresStore, electionsStore, style, classes } = this.props;
        const { selectedAddress } = searchStore;
        const { selectedElection } = electionsStore;
        //const rightDrawerOpen = appMainStore.isRightDrawerOpen;
        const {footer} = this.state;
        let showMobileFooter = (!votingCentresStore.showVotingCenterDetails && !this.isBottomDrawerOpen && !appMainStore.rightDrawerOpen);
        const { expandedInTablet, leftDrawIsOpen } = electionsStore;  
        let tabletStyle = {
            top : '0px'
        } 
        return (
            <React.Fragment>
                {
                   selectedElection ?
                   <React.Fragment>
                    <Hidden only={['xs', 'lg', 'xl','md']}>
                        <Drawer open={leftDrawIsOpen} anchor='left' onClose={() => this.toggleExpandedInTablet()} variant = "permanent" 
                                classes = {{paper : classes.paperAnchor}} >
                                    <Grid container direction='column' style={{height:'calc(100% - 65px)'}}>
                                        {leftDrawIsOpen && <Grid item className = {classes.tabletGrid}>
                                            {((!appMainStore.rightDrawerOpen && !votingCentresStore.showVotingCenterDetails) || !votingCentresStore.selectedVotingCentre) &&
                                                <div className = {style} style = {tabletStyle} >
                                                    <div >
                                                        <AddressSearchBox secondary
                                                            placeholder = 'Enter an address'
                                                            defaultAddress={selectedAddress}
                                                            google={mapStore.googleSDK}
                                                            map={mapStore.map}
                                                            onPlacesChanged={this.onVotingCenterSearchAddressChange} />
                                                    </div>
                                                
                                                    { votingCentresStore.isAddressSearchInVictoria && votingCentresStore.isBeforeEarlyVotingCutoff && <AddressSearchFiltersBox /> }
                                                
                                                     
                                                    <ElectionsTheme><VotingCenterSearchResults 
                                                        isAlertTitleAvailable={appMainStore.IsAlertTitleAvailable} 
                                                        isAlertMessageAvailable={appMainStore.IsAlertMessageAvailable} 
                                                        votingCentreClicked = {() => votingCentresStore.toggleShowVotingCenterDetails()}
                                                        /></ElectionsTheme>
                                                </div>
                                            }                                            
                                            
                                            <VotingCentreDetailsPanel closeClickHandler={this.onVotingCentreDrawerCloseClick} />
                                            <DistrictDetailsPanel closeClickHandler={this.onRightDrawerCloseClick} />
                                            <CouncilDetailsPanel closeClickHandler={this.onRightDrawerCloseClick} />
                                        </Grid>}
                                        <Grid item>
                                            <div className = {classes.expansionDiv} >
                                            <IconButton color="inherit" size='small' className = {classes.arrowIcon} onClick={() => this.toggleExpandedInTablet()}  disableRipple={true} >
                                                { expandedInTablet ? <KeyboardArrowLeftIcon/> : <KeyboardArrowRightIcon/> }
                                            </IconButton>
                                            </div>
                                        </Grid>
                                    </Grid> 
                    
                            </Drawer>   
                    </Hidden> 
                    <Hidden smUp> 
                        {(!appMainStore.rightDrawerOpen && !votingCentresStore.showVotingCenterDetails) && <div className = {classes.mobileContainer}>                   
                         <AddressSearchBox secondary
                             placeholder = 'Enter an address'
                             defaultAddress={selectedAddress}
                             google={mapStore.googleSDK}
                             map={mapStore.map}
                             onPlacesChanged={this.onVotingCenterSearchAddressChange} />
                                           
                             { votingCentresStore.isAddressSearchInVictoria && votingCentresStore.isBeforeEarlyVotingCutoff && <AddressSearchFiltersBox /> } 
                        </div> }
                        {
                            (footer.text == this.footerTextOptions.showMap && !votingCentresStore.showVotingCenterDetails) &&
                            <ElectionsTheme><VotingCenterSearchResults 
                                isAlertTitleAvailable={appMainStore.IsAlertTitleAvailable} 
                                isAlertMessageAvailable={appMainStore.IsAlertMessageAvailable} 
                                votingCentreClicked = {() => votingCentresStore.toggleShowVotingCenterDetails()}
                            /></ElectionsTheme>
                        }                     
                        <VotingCentreDetailsPanel closeClickHandler={this.onVotingCentreDrawerCloseClick} />
                        <DistrictDetailsPanel closeClickHandler={this.onRightDrawerCloseClick} />
                        <CouncilDetailsPanel closeClickHandler={this.onRightDrawerCloseClick} />
                        {showMobileFooter && <Footer menuClickHandler= {this.onFooterMenuClick} title = {footer.text} icon = { footer.icon } />}
                        <BottomDrawer open={this.isBottomDrawerOpen} />

                    </Hidden>
                    <Hidden smDown className={classes.desktopContainer}>
                        <div className = {style} >
                            <div >
                                <AddressSearchBox secondary
                                    placeholder = 'Enter an address'
                                    defaultAddress={selectedAddress}
                                    google={mapStore.googleSDK}
                                    map={mapStore.map}
                                    onPlacesChanged={this.onVotingCenterSearchAddressChange} />
                            </div>
                            { votingCentresStore.isAddressSearchInVictoria && votingCentresStore.isBeforeEarlyVotingCutoff && <AddressSearchFiltersBox /> }
                                    <ElectionsTheme><VotingCenterSearchResults 
                                isAlertTitleAvailable={appMainStore.IsAlertTitleAvailable} 
                                isAlertMessageAvailable={appMainStore.IsAlertMessageAvailable} 
                                votingCentreClicked={()=>{}} 
                                    /></ElectionsTheme>
                            <RightDrawer open={appMainStore.isRightDrawerOpen} closeClickHandler={this.onRightDrawerCloseClick} />
                        </div>                                   
                    </Hidden> 
                    {votingCentresStore.showAddressOutOfElectionDialog ?
                    <ElectionsTheme>
                        <Dialog 
                            open={true} 
                            onClose={() => {this.hideAddressOutOfElectionDialog()}}
                            disableEscapeKeyDown
                            disableBackdropClick
                            >                            
                            <DialogContent className={classes.addressOutOfElectionDialog}>
                                <Typography variant='h3' color='secondary' gutterBottom>The address you&apos;ve searched is outside of this election area</Typography>
                                <Typography variant='h5' color='secondary'>More information <IconButton onClick={() => {this.setState({expandAddressOutOfElectionDialogDetails: !this.state.expandAddressOutOfElectionDialogDetails});}}>{this.state.expandAddressOutOfElectionDialogDetails? <ExpandLessIcon color='secondary'/> : <ExpandMoreIcon color='secondary'/>}</IconButton></Typography>
                                <Collapse in={this.state.expandAddressOutOfElectionDialogDetails}>
                                    <Typography variant='body2' gutterBottom className={classes.addressOutOfElectionDialogCollapseText}>
                                        Elections have different voting methods - some require you to vote at a voting centre, some are by postal voting. We need to start over with your newly searched address to provide you with accurate election and voting information.
                                    </Typography>
                                </Collapse>
                                <Typography variant='body1' gutterBottom>
                                    Would you like to start over with this address?
                                </Typography>
                                <Typography variant='body1' gutterBottom>
                                    {searchStore.selectedAddress.formatted_address}
                                </Typography>
                                <Grid container justify='space-between'>
                                    <Grid item>
                                        <Button startIcon={<ReloadIcon fill='white'/>} variant='contained' onClick={() => {this.toggleLandingDialogForAddress()}} color="primary">
                                             Start over
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button  variant='outlined' onClick={() => {this.hideAddressOutOfElectionDialog()}} color="primary" autoFocus>
                                            Back to map
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                        </Dialog>
                    </ElectionsTheme>
                    : null}
                </React.Fragment>                   
                    :
                    <ElectionsTheme><LandingPage/></ElectionsTheme>
                }
               
            </React.Fragment>
        );
    }

    @action.bound onVotingCenterSearchAddressChange = async (places) => {
        if (places && places.length) {
            this.props.votingCentresStore.handleAddressSearch(places[0]);
        }
    }

    @action.bound hideAddressOutOfElectionDialog() {
        this.props.votingCentresStore.showAddressOutOfElectionDialog = false;
    }

    toggleLandingDialogForAddress = () => {
        const { electionsStore, searchStore, votingCentresStore } = this.props;
        electionsStore.setSelectedElection(null);
        votingCentresStore.showAddressOutOfElectionDialog = false;
        electionsStore.getAddressElections(searchStore.selectedAddress);
        
    }

    onRightDrawerCloseClick = () => {
        this.props.appMainStore.closeRightDrawer();
    }

    @action.bound onVotingCentreDrawerCloseClick = () => {
        this.props.appMainStore.closeVotingCentreDrawer();
        this.props.appMainStore.closeRightDrawer();
        if (this.state.footer.text == this.footerTextOptions.showMap) {
            this.props.votingCentresStore.setSelectedVotingCentre(null);          
        }
    }

    static propTypes = {
        appMainStore: PropTypes.object,
        searchStore: PropTypes.object,
        electionsStore: PropTypes.object,
        electoratesStore: PropTypes.object,
        votingCentresStore: PropTypes.object,
        landingDialogStore: PropTypes.object,
        mapStore: PropTypes.object,
        style: PropTypes.string,
        classes: PropTypes.object
    }
}

export default withStyles(styles)(ElectionsPanel);
