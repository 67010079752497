import moment from 'moment';
import {detect} from 'detect-browser';

var Tools = {

    fixIEScrollIssue: function(elementId) {
        if (document.documentMode) {
                const el = document.getElementById(elementId);
            if (el) {
                const rect = el.getClientRects()[0];
                if (rect) {
                    el.style.height = Math.floor(document.body.clientHeight - rect.top - 20) + 'px';
                }
            }
        }
    },

    MAXINT: Math.pow(2,31) - 1,

    isDateValidator: function(value) {
        let regIE = /^\d{2}[./]\d{2}[./]\d{4}$/
        if (regIE.test(value) && moment(value, 'DD/MM/YYYY').isValid()) {   // Check for value with format DD/MM/YYYY possibly from IE
            value = moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD');       // Convert for valid date check
        }
        let reg = /^\d{4}[./-]\d{2}[./-]\d{2}$/
        if (reg.test(value)) {
            return moment(value, 'YYYY-MM-DD').isValid() && moment(value).year() != "0000"
        }
        else 
        {
            return false;
        }
    },

    isEmailValidator: function (value) {
        // eslint-disable-next-line
        let reg = /^([a-zA-Z0-9'_\-\.!#$%&*+=?^`{}|\"~\\,:;<>()\[\]/]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-\[\]]+\.)+))([a-zA-Z\[\]]{2,18}|[0-9\[\]]{1,3})(\]?)$/;
        return reg.test(value);
    },
    vecFormatNumbers: function (number) {
        if (typeof(number) == 'number' || number < 10) {
           const words=['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
           return words[number];
        } else {
            return number.toString();
        }
    },
    
    vecFormatDateRange: function(date1, date2, junctionText) {
        const startDate = moment.tz(date1, 'Australia/Melbourne');
        const endDate = moment.tz(date2, 'Australia/Melbourne');
        if (startDate.month() != endDate.month()) {
            return startDate.format('dddd D MMMM') + junctionText + endDate.format('dddd D MMMM');
        } else {
            return startDate.format('dddd D') + junctionText + endDate.format('dddd D MMMM');
        }
    }, 

    getResponseJson: function (response) {
        if (!response.ok) {
            let error = response.statusText;
            if (!error) {
                if (response.bodyUsed) {
                    response.clone().json().then(content => {
                        error = content;
                        return Promise.reject(new Error(error));
                    });            
                } else {
                    return Promise.reject(new Error('Failed'));
                }
            } else {
                return Promise.reject(new Error(error));
            }
        }
        return response.json();
    },
    hexToRgb(hex) {
        if (hex[0] === '#') hex = hex.substring(1);
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
    
        return {r: r, g: g, b: b};
    },
    breakpoints: {
        up: (target) => {
            switch (target){
                case 'sm': 
                    return window.innerWidth > 600
                case 'md':
                    return window.innerWidth > 960
                case 'lg':
                    return window.innerWidth > 1280
                case 'xl':
                    return window.innerWidth > 1920
                default:
                    return true;    
            }
        },
        down: (target) => {
            switch (target){
                case 'xs': 
                    return window.innerWidth < 600
                case 'sm':
                    return window.innerWidth < 960
                case 'md':
                    return window.innerWidth < 1280
                case 'lg':
                    return window.innerWidth < 1920
                default:
                    return false;
            }
        },            
        equals: (target) => {
            switch (target){
                case 'xs': 
                    return window.innerWidth < 600
                case 'sm':
                    return window.innerWidth < 960 && window.innerWidth >= 600
                case 'md':
                    return window.innerWidth < 1280 && window.innerWidth >= 960
                case 'lg':
                    return window.innerWidth < 1920 && window.innerWidth >= 1280
                default:
                    return false;
            }
        }
    }, 
    iOSVersion: () => {
        /*
            * Outputs a float representing the iOS version if user is using an iOS browser i.e. iPhone, iPad
            * Possible values include:
            *	3 - v3.0
            *	4.0 - v4.0
            *	4.14 - v4.1.4
            *	false - Not iOS
        */
        return parseFloat(
            ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0,''])[1])
            .replace('undefined', '3_2').replace('_', '.').replace('_', '')
            ) || false;
    },

    isUnsupportedBrowser: function () {
        const browser = detect();
        if (browser) {  
            const version = parseInt(browser.version.split('.')[0]);      
            if ( browser.os=='iOS' && this.iOSVersion() < 11) {return true;}
            switch (browser.name) {
                case 'chrome': 
                    return version < 67;
                case 'crios': 
                    return version < 67;
                case 'firefox':
                    return version < 57;
                case 'FxiOS':
                    return version < 15;
                case 'edge':
                    return version < 16;
                case 'ie': 
                    return version < 11;      
                case 'safari': 
                    return version < 11;      
                case 'ios': 
                    return version < 11;      
                case 'Opera': 
                    return version < 10;      
                default:
                    return false;
            }
        } else {
            return false;
        }
    },

    isDeviceIOS: function () {
        const browser = detect();
        if (browser) {     
            if ( browser.os=='iOS')
                return true;
            else 
                return false;
        }else 
            return false;
    }
};


if (!Array.prototype.sortBy) {

    Array.prototype.sortBy = function(names) {
        var result = [];
        this.forEach(function(element) {
            result.push(element);
        });
        if (names && (typeof(names) == 'string' || Array.isArray(names))) {
            if (typeof(names) == 'string') names = [names];
            return result.sort(function(a, b) {
                for(var i = 0; i < names.length; i++) {
                    var name = names[i];
                    if (a[name] == b[name])
                        continue;
                    else if (a[name] > b[name])
                        return 1;
                    else
                        return -1;
                }
                return 0;
            });
        }
        else {
            return result.sort();
        }
    }
}

if (!Array.prototype.uniqBy) {

    Array.prototype.uniqBy = function(names) {
        var hashed = [];
        var result = [];
        if (names) {
            this.forEach(function(element) {
                if (hashed.indexOf(element[names]) < 0) {
                    hashed.push(element[names]);
                    result.push(element);
                }
            });
        }
        else {
            this.forEach(function(element) {
                if (hashed.indexOf(element) < 0) {
                    hashed.push(element);
                    result.push(element);
                }
            });
        }
        return result;
    }
}

if (!Array.prototype.last) {
    
    Array.prototype.last = function() {
        return this.length ? this[this.length -1]: null
    }
}

if (!Array.prototype.max) {
    Array.prototype.max = function(name) {
        return this.reduce((max, value) => {
            var val = name ? value[name]: value;
            return max > val ? max: val;
        }, 0);
    }
}

if (!Array.prototype.sumInt) {
    Array.prototype.sumInt = function(name) {
        if (name) {
            return this.reduce((tot, value) => {
                return tot + (parseInt(value[name]) || 0);
            }, 0);
        }
        else {
            return this.reduce((tot, value) => {
                return tot + (parseInt(value) || 0);
            }, 0);
        }
    }
}

if (!Array.prototype.find) {
    Object.defineProperty(Array.prototype, 'find', {
        value: function (predicate) {
            // 1. Let O be ? ToObject(this value).
            if (this == null) {
                throw new TypeError('"this" is null or not defined');
            }

            var o = Object(this);

            // 2. Let len be ? ToLength(? Get(O, "length")).
            var len = o.length >>> 0;

            // 3. If IsCallable(predicate) is false, throw a TypeError exception.
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate must be a function');
            }

            // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
            var thisArg = arguments[1];

            // 5. Let k be 0.
            var k = 0;

            // 6. Repeat, while k < len
            while (k < len) {
                // a. Let Pk be ! ToString(k).
                // b. Let kValue be ? Get(O, Pk).
                // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
                // d. If testResult is true, return kValue.
                var kValue = o[k];
                if (predicate.call(thisArg, kValue, k, o)) {
                    return kValue;
                }
                // e. Increase k by 1.
                k++;
            }

            // 7. Return undefined.
            return undefined;
        }
    });    
}

export { Tools }