import React, { Component } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => {
    const { zIndex } = theme;
    return ({
        appBar: {
            position: 'fixed',
            zIndex: zIndex.tooltip + 1,
             backgroundColor : theme.palette.primary.contrastText,
             bottom: '0',
             left: '0',
             width: "100%"
        },
        menuButton: {
            color : theme.palette.primary.main,
            width: 'calc(100% - 32px)'
        },
        appToolbar: {
            paddingLeft: '0px'
        },
        browseList : {          
            paddingRight: '10px',
            paddingLeft: '70px'       
        },
        title : {
            color: theme.palette.primary.main,
            fontWeight: '600'           
        }
    });
}

class Footer extends Component {
    render() {
        const { classes, menuClickHandler,title, icon } = this.props;
        return (
            <div className={classes.appBar}>
                <Toolbar className={classes.appToolbar}>
                    <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        id="btn-footer-menu"
                        onClick={menuClickHandler}
                        edge = "start">
                       <img className = {classes.browseList} src={icon} />
                       <Typography variant = "h6"  className = {classes.title}> { title} </Typography>
                    </IconButton> 

                            
                </Toolbar>
            </div>
        );
    }    
}
Footer.propTypes = {
    classes: PropTypes.object.isRequired,
    menuClickHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    icon : PropTypes.string.isRequired
};
export default withStyles(styles)(Footer);



