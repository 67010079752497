import React from 'react';
import PropTypes from 'prop-types';
import { compose } from "recompose"
import { withStyles } from '@material-ui/core/styles';
import {Drawer, Typography, Link} from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import {footerNavigationItems, navigationItems} from '../../utils/constants'
import classnames from 'classnames';

// Icons
import VecLogo from '../../../static/images/vec-logo.svg';
import InterpreterIcon from '../../../static/images/misc/interpretericon.png';
import ShapeCopyIcon from '../../../static/images/misc/shape-copy.svg';
import SocialFacebook from '../../../static/images/social-facebook.svg';
import SocialTwitter from '../../../static/images/social-twitter.svg';
import SocialYoutube from '../../../static/images/social-youtube.svg';
import SocialInstagram from '../../../static/images/social-instagram.svg';

import { styles } from './left-drawer.styles.js';

@inject('appMainStore')
@observer
class LeftDrawer extends React.Component {
    render() {
        const { classes, appMainStore } = this.props;        

        return (
            <Drawer
                open={appMainStore.leftDrawerOpen}
                onClose={this.closeClickHandler}
                variant="temporary"
                classes={{
                    paper: classes.drawerPaper
                }}
            >
                <div className={classes.drawerItems}>
                    <div className={classes.panelHeaderWrapper}>
                        <div className={classes.drawerLogo}><img className={classes.logo} src={VecLogo} />
                            <Typography variant='body2' color='secondary' className={classes.appBarTitle} id="menu-title">
                                Victorian Electoral Commission
                            </Typography>
                        </div>
                        <button id="btn-drawer-close" onClick={this.closeClickHandler} className={classes.drawerCloseButton}><img src={ShapeCopyIcon} /></button>
                    </div>
                    
                    <div className={[classes.navItems, classes.drawerNavItems].join(' ')}>
                        <ul>
                            <li id="lst-start-over" className={classes.navItem} onClick={this.startOverClickHandler}>
                                <span>{navigationItems.StartOver.label}</span>
                            </li>
                            <li id="lst-vec-home" className={classes.navItem} onClick={this.vecHomeClickHandler}>
                                <span>{navigationItems.VECHome.label}</span>
                            </li>
                            {/* to be implemented later when page exists
                            <li id="lst-faq-help" className={classes.navItem} onClick={this.faqClickHandler}>
                                <span>{navigationItems.FAQHelp.label}</span>
                            </li> */}
                        </ul>
                    </div>
                    <div className={classes.drawerFooter}>
                        <div className={classes.footerIcons}>
                            <img src={InterpreterIcon} onClick={this.interpreterServicesClickHandler} />
                        </div>
                        <div className={[classes.navItems, classes.footerNavItems].join(' ')}>
                            <ul>
                                <li id="lst-privacy" className={classnames(classes.navItem, classes.footerNavItem)}>
                                    <Link target='_blank' className={classnames(classes.navItem, classes.footerNavItem)} href='https://vec.vic.gov.au/privacy'>{footerNavigationItems.Privacy.label}</Link>
                                </li>
                                <li id="lst-legal" className={classnames(classes.navItem, classes.footerNavItem)} onClick={this.legalClickHandler}>
                                    <Link target='_blank' className={classnames(classes.navItem, classes.footerNavItem)} href='https://vec.vic.gov.au/legal'>{footerNavigationItems.Legal.label}</Link>
                                </li>
                                <li id="lst-accessibility"className={classnames(classes.navItem, classes.footerNavItem)} onClick={this.accessibilityClickHandler}>
                                    <Link target='_blank' className={classnames(classes.navItem, classes.footerNavItem)} href='https://vec.vic.gov.au/accessibility'>{footerNavigationItems.Accessibility.label}</Link>
                                </li>
                                <li id="lst-contact" className={classnames(classes.navItem, classes.footerNavItem)} onClick={this.contactClickHandler}>
                                    <Link target='_blank' className={classnames(classes.navItem, classes.footerNavItem)} href='https://vec.vic.gov.au/contact-us'>{footerNavigationItems.Contact.label}</Link>
                                </li>
                            </ul>
                        </div>
                        <p className={classes.extraText}>
                            Authorised by S. Bluemmel, Electoral Commissioner, 530 Collins Street, Melbourne, Victoria.
                        </p>
                        <div className={[classes.footerIcons, classes.footerSocialIcons].join(' ')}>
                            <img src={SocialFacebook} onClick={this.facebookClickHandler} />
                            <img src={SocialTwitter} onClick={this.twitterClickHandler} />
                            <img src={SocialYoutube} onClick={this.youtubeClickHandler} />
                            <img src={SocialInstagram} onClick={this.instagramClickHandler} />
                        </div>
                    </div>
                </div>
            </Drawer>
        );
    }

    closeClickHandler = () => {
        this.props.appMainStore.setLeftDrawerOpen(false);
    }

    startOverClickHandler = () => {
        window.location.reload();
    }

    vecHomeClickHandler = () => {
        window.location.assign('https://www.vec.vic.gov.au/');
    }

    // to be implemented later when page exists
    // faqClickHandler = () => {
    //     window.location.assign('https://www.vec.vic.gov.au/elections/2018StateElectionFAQ.html');
    // }

    interpreterServicesClickHandler = () => {
        window.location.assign('https://www.vec.vic.gov.au/information-in-your-language');
    }

    facebookClickHandler = () => {
        window.location.assign('https://www.facebook.com/electionsvic');
    }

    twitterClickHandler = () => {
        window.location.assign('https://www.twitter.com/electionsvic');
    }

    youtubeClickHandler = () => {
        window.location.assign('https://www.youtube.com/electionsvic');
    }

    instagramClickHandler = () => {
        window.location.assign('https://www.instagram.com/electionsvic/');
    }
}

LeftDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    appMainStore: PropTypes.object,
};

export default compose(
    withStyles(styles),
)(LeftDrawer);