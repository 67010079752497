import { observable, action, runInAction, computed } from 'mobx';
import { VotingCentreType } from '../enums';
import electionsServcie from '../services/elections.service';
import {ElectionCategory, ElectionType, ApplicationMode} from '../enums';
export default class LandingDialogStore {
    bindStores({ landingDialogCommonStore, searchStore, votingCentresStore, appMainStore}) {
        this.landingDialogCommonStore = landingDialogCommonStore;
        this.searchStore = searchStore;
        this.votingCentresStore = votingCentresStore;
        this.appMainStore = appMainStore;
    }

    @observable step = 0;    

    @observable searchQuery = {
        address: null,
        filters: [
            VotingCentreType.EarlyVotingCentre,
            VotingCentreType.ElectionDayVotingCentre,
        ],
    };

    @observable _currentElectionSummary = {
        electionDate: null,
        electionTypeDescription: null
    };

    @observable currentElectionSummaryError = null;

    @action setSearchQueryAddress = (address) => {
        this.searchQuery.address = address;
    }

    @action setSearchQueryFilters = filters => {
        this.searchQuery.filters = filters;
    }

    @action reset() {
        this.landingDialogCommonStore.landingDialogOpen = false;
        this.step = 0;
        this.searchQuery = {
            address: null,
            filters: this.searchQuery.filters,
        };
    }

    @action
    clearFilters() {
        this.searchQuery.filters = [];
    }

    @action
    setPostEarlyVotingCutoffFilters() {
        this.searchQuery.filters = [VotingCentreType.ElectionDayVotingCentre];
    }

    @action
    setVotingFilterOption = (id) => {
        const newFilters = this.searchQuery.filters.filter(filterId => filterId !== id);
        if (newFilters.length === this.searchQuery.filters.length) {
            newFilters.push(id);
        }
        this.searchQuery.filters = newFilters;
    }

    @action
    setLandingDialogOpen(landingDialogOpen) {
        this.landingDialogCommonStore.landingDialogOpen = landingDialogOpen;
    }

    @action setStep = (stepNumber) => {
        this.step = stepNumber;
    }

    @action submitSearchQuery = () => {
        const _places = { places: this.searchQuery.address ? [this.searchQuery.address] : [] }
        this.searchStore.setSelectedAddress(_places);
        this.searchStore.setSelectedFilters(this.searchQuery.filters);
        this.votingCentresStore.retrieveVotingCenterSearchResults();
    }

    @action getCurrentElectionSummary = () => {
        let electionOptions = {};
        switch (this.appMainStore.applicationMode){
            case ApplicationMode.Council: 
                electionOptions.electionType = ElectionType.General;
                electionOptions.electionCategory = ElectionCategory.Council;
                break;
            case ApplicationMode.CouncilBy: 
                electionOptions.electionType = ElectionType.ByElection;
                electionOptions.electionCategory = ElectionCategory.Council;
                break;
            case ApplicationMode.StateBy: 
                electionOptions.electionType = ElectionType.ByElection;
                electionOptions.electionCategory = ElectionCategory.State;
                break;
            case ApplicationMode.State: 
            default:
                electionOptions.electionType = ElectionType.General;
                electionOptions.electionCategory = ElectionCategory.State;
                break;
        }

        electionsServcie.getCurrentElectionSummary(electionOptions).then((response) => {
            runInAction(() => {                
                this._currentElectionSummary = response.length? response[0] : {};
            });
        }).catch((error) => {
            this.currentElectionSummaryError = error;
        });
    }

    @computed get CurrentElectionSummary() {
        return this._currentElectionSummary;
    }
}