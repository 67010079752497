import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import AddressSearchBox from '../common/address-search-box/address-search-box';
import { withStyles,  IconButton, Drawer, Grid } from '@material-ui/core';
import BrowseSelection from './browse-selection';
import AdditionalLayersSelector from './additionalLayersSelector';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { observable, action } from 'mobx';
import browseList from '../../../static/images/list.svg';
import showMap from '../../../static/images/show-map.svg';
import showMapKey from '../../../static/images/show-map-key.svg'
import Footer from './footer';
import * as Enums from '../../enums';
import Hidden from '@material-ui/core/Hidden';


const styles = (theme) => ({
    paperAnchor : {
         marginTop: '65px',
         overflow: 'visible',
         background: 'transparent',
         border: 'none',
    },
    drawerClose: {
        width: '50px',
    },
    expansionDiv: {
        height: '70px',
        width:'32px',
        background: 'white',
        boxShadow: '4px 5px 10px 1px #8a8a8a',
    },
    arrowIcon : {
        paddingTop: '23px',
        color : theme.palette.navy.main + ' !important'
    },
    tabletGrid : {
        background: theme.palette.gray.gray100,
        height: '100%',        
        [theme.breakpoints.down('sm')]: {
            width: '400px',
            height: 'calc(100vh - 65px)',
            overflowX: 'hidden',
            overflowY: 'auto'
        }
    },
    desktopContainer: {
        maxHeight: 'calc(100vh - 163px)',
        display: 'flex',
        flexDirection: 'column',
    },
    mobileContainer: {
        height: 'calc(100vh - 112px)',
        background: theme.palette.gray.gray100,
        overflowX: 'hidden',
        overflowY: 'auto'
    }
});

@inject('appMainStore', 'searchStore', 'mapStore', 'landingDialogStore', 'electoratesStore', 'browseStore', 'electionsStore', 'additionalLayersStore')
@observer
class BrowsePanel extends React.Component {   

    @observable showBrowseList = false;   

    @action setshowBrowseList() {
        this.showBrowseList = !this.showBrowseList;
    }

    footerTextOptions = {
        showMap: "Show map",
        showMapKey: "Show map key",
        browseElectorate: "Browse electorates"

    };

    setupFooter = () => {
        let footer ={}
        if(this.showBrowseList) {            
            footer.Text = this.footerTextOptions.showMap;
            footer.Icon = showMap;                                                                   
        }
        else {
            let selected = this.props.electoratesStore.selectedRegion || 
                            this.props.electoratesStore.selectedDistrict ||
                            this.props.electoratesStore.selectedCouncil ||
                            this.props.electoratesStore.selectedWard 
            footer.Text = selected ? this.footerTextOptions.showMapKey : this.footerTextOptions.browseElectorate;
            footer.Icon = selected ? showMapKey : browseList;  
        }
        return footer;
    }

    onFooterMenuClick = () => {        
        this.setshowBrowseList();
    }

   
    constructor (props) {        
        super(props);      
        
        this.state = {
            expandedInTablet: true, 
            drawIsOpen: true           
        };
        this.drawerRef = null;
    } 

    componentDidMount() {
        this.props.appMainStore.setApplicationMode(Enums.ApplicationMode.Browse)
        this.props.appMainStore.setShowGenericHeader(true);
        if (this.props.searchStore.selectedAddress) {
            this.props.electionsStore.setSelectedElection(null);
            this.props.browseStore.handleAddressSearch([this.props.searchStore.selectedAddress]);
        }
    }

    componentWillUnmount() {
        this.props.additionalLayersStore.hideAll();
        this.props.electoratesStore.cleanUp();
    }

    toggleExpandedInTablet = () => {
        this.setState({expandedInTablet: !this.state.expandedInTablet});
        this.setState({drawIsOpen: !this.state.drawIsOpen});
    }

    render() {
        const { classes, searchStore, mapStore, browseStore } = this.props;
        const { selectedAddress } = searchStore;  
        const { expandedInTablet, drawIsOpen } = this.state;        
        const mobileFooter = this.setupFooter();

        return (
            <div>
                  <Hidden only={['xs', 'lg', 'xl','md']}>
                     <Drawer open={drawIsOpen} anchor='left' onClose={() => this.toggleExpandedInTablet()} variant = "permanent" 
                              classes = {{paper : classes.paperAnchor}} >
                                <Grid container direction='column' style={{height:'calc(100% - 65px)'}}>
                                {drawIsOpen && <Grid item className = {classes.tabletGrid}>
                                        <AddressSearchBox secondary
                                        placeholder = 'Enter an address'
                                        defaultAddress={selectedAddress}
                                        google={mapStore.googleSDK}
                                        map={mapStore.map}
                                        onPlacesChanged={(places) => browseStore.handleAddressSearch(places)} />
                                        <BrowseSelection />    
                                        <AdditionalLayersSelector/>
                                    </Grid>}
                                    <Grid item>
                                        <div className = {classes.expansionDiv} >
                                        <IconButton color="inherit" size='small' className = {classes.arrowIcon} onClick={() => this.toggleExpandedInTablet()}  disableRipple={true} >
                                            { expandedInTablet ? <KeyboardArrowLeftIcon/> : <KeyboardArrowRightIcon/> }
                                        </IconButton>
                                        </div>
                                    </Grid>
                                </Grid> 
                
                        </Drawer>   
                  </Hidden>
                        
                     <Hidden smUp>
                        { this.showBrowseList ?
                                <div className={classes.mobileContainer}>
                                    <BrowseSelection /> 
                                    <AdditionalLayersSelector/>
                                </div>
                         :
                                <AddressSearchBox secondary
                                placeholder = 'Enter an address'
                                defaultAddress={selectedAddress}
                                google={mapStore.googleSDK}
                                map={mapStore.map}
                                onPlacesChanged={(places) => browseStore.handleAddressSearch(places)} />
                        }
                        <Footer menuClickHandler= {this.onFooterMenuClick} title = {mobileFooter.Text} 
                                 icon = { mobileFooter.Icon } />
                     </Hidden>

                     <Hidden smDown className={classes.desktopContainer}>
                        <AddressSearchBox secondary
                            placeholder = 'Enter an address'
                            defaultAddress={selectedAddress}
                            google={mapStore.googleSDK}
                            map={mapStore.map}
                            onPlacesChanged={(places) => browseStore.handleAddressSearch(places)} />
                        <BrowseSelection />
                        <AdditionalLayersSelector/>                                         
                    </Hidden>  
            </div>
        );
    }
    
    setSearchResultsComponentRef = (element) => {
        if (element) {
            this.props.appMainStore.searchResultsComponentRef = element;
        }
    }   
}

BrowsePanel.propTypes = {
    classes: PropTypes.object.isRequired,   
    electoratesStore: PropTypes.object,
    appMainStore: PropTypes.object,
    searchStore: PropTypes.object,
    landingDialogStore: PropTypes.object,
    mapStore: PropTypes.object,
    browseStore: PropTypes.object,   
    electionsStore: PropTypes.object,   
    additionalLayersStore: PropTypes.object,   
}

export default withStyles(styles)(BrowsePanel);
