//eslint-disable-next-line
/*global google*/
import React from "react"
import PropTypes from "prop-types"
import { observe, reaction, computed, toJS } from "mobx";
import { observer} from "mobx-react";

@observer
class DataLayer extends React.Component {

    constructor(props) {
        super(props)
        const dataLayer = new google.maps.Data();
        if (props.style) {
            dataLayer.setStyle(props.style);
        } else if (props.styleFn) {
            dataLayer.setStyle(props.styleFn);
        }
        if (props.onClick) {
            dataLayer.addListener('click', (event) => {
                props.onClick(event.feature);
            });
            
        }
        this.map = this.props.map;
        this.sourceFeatures = props.dataSource;
        this.observerDisposer = observe(this.sourceFeatures, (change) => {this.addRemoveFeatures(change)});
        this.renderedFeatures = [];
        this.dataLayer = dataLayer;
        this.featureIdProperty = props.featureId;
        this.featureObservers = [];
        this.createInitialFeatures();
        this.updateVisibility(this.visible);
        this.visibilityDisposer = reaction(() => {return this.visible}, (visible) => {this.updateVisibility(visible)});
        this.mapDisposer = reaction(() => {return this.props.map}, (map) => {this.map = map; this.updateVisibility(this.visible)});
    }

    @computed get visible() {return (!!this.props.visible || this.props.visible == undefined);}

    componentWillUnmount() {
        const dataLayer = this.dataLayer;
        this.observerDisposer();
        if (this.visibilityDisposer) {this.visibilityDisposer()}
        if (this.mapDisposer) {this.mapDisposer()}
        if (dataLayer) {
            dataLayer.setMap(null);
        }
    }

    render() {
        return <React.Fragment>
                {null}
            </React.Fragment>
    }
    
    createInitialFeatures= () => {
        this.sourceFeatures.forEach(feature => {
            this.addFeature(feature);
        });
    }

    updateVisibility = (visible) => {
        this.dataLayer.setMap(visible? this.map: null);        
    }

    addRemoveFeatures = (changes) => {
        changes.removed.forEach(feature => {            
            this.removeFeature(feature.properties[this.featureIdProperty]);            
        });
        changes.added.forEach(feature => {
            this.addFeature(feature);
        });
    }

    updateFeatureProperties = (changes) => {
        if (changes.type === 'update'){
            this.dataLayer.getFeatureById(changes.object[this.featureIdProperty]).setProperty(changes.name, changes.newValue);
        }
    }

    updateGeometry = (changeInfo) => {
            var tmpGoogleData = new google.maps.Data();
            tmpGoogleData = new google.maps.Data();
            tmpGoogleData.addGeoJson({type: 'Feature', geometry: toJS(changeInfo.geometry), properties: { 'id': changeInfo.id }} ,{ 'idPropertyName': 'id' });            
            this.dataLayer.getFeatureById(changeInfo.id).setGeometry(
                tmpGoogleData.getFeatureById(changeInfo.id).getGeometry()
                );
                
    }

    addFeature = (feature) => {
        let featureObserver = {id: feature.properties[this.featureIdProperty], disposers: [
                observe(feature.properties,(change) => {this.updateFeatureProperties(change)}),
                reaction(() => {return {id: feature.properties[this.featureIdProperty], geometry: feature.geometry}}, (changeInfo) => {this.updateGeometry(changeInfo)})
            ]};        
        this.featureObservers.push(featureObserver);                    

        if (feature && feature.geometry) {
            this.dataLayer.addGeoJson(toJS(feature), { 'idPropertyName': this.featureIdProperty });
        }
    }

    removeFeature = (id) => {
        this.featureObservers.some((featOb, index, observers) => {
            if (featOb.id == id){
                featOb.disposers.forEach(disposer => {
                    disposer();
                });
                observers.splice(index, 1);
            } else {
                return false;
            }
        });
        if (this.dataLayer.getFeatureById(id)) {
            this.dataLayer.remove(this.dataLayer.getFeatureById(id));
        }
    }   

    static propTypes = {
        dataSource: PropTypes.oneOfType([PropTypes.array,PropTypes.object]).isRequired,
        visible: PropTypes.bool,
        featureId: PropTypes.string,
        styleFn: PropTypes.func, 
        style: PropTypes.oneOfType([PropTypes.func, PropTypes.object]), 
        onClick: PropTypes.func,    
        map: PropTypes.object    
    }
}

export default (DataLayer);