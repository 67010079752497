import { observable, computed, action, runInAction } from 'mobx';
import turfDifference from '@turf/difference';
import turfRewind from '@turf/rewind';
import electoratesServcie from '../services/electorates.service';
import { ElectorateType } from '../enums';
import electoratesService from '../services/electorates.service';
import * as Enums from '../enums';

export default class ElectoratesStore {
    constructor(appMainStore) {
        this.appMainStore = appMainStore;
    }

    bindStores = ({ mapStore }) => {
        this.mapStore = mapStore;
    }

    @observable electoratesSummary = null;
    @observable getElectoratesSummaryError = null;
    @observable regions = [];
    @observable getRegionsError = null;
    @observable wards = [];
    @observable getWardsError = null;
    @observable districts = [];
    @observable getDistrictsError = null;
    @observable councils  = [];
    @observable getCouncilsError = null;
    @observable selectedRegionIndex = -1;
    @observable selectedDistrictIndex = -1;
    @observable selectedCouncilIndex = -1;
    @observable selectedWardIndex = -1;
    @observable shadeUnselectedDistricts = false;
    @observable districtBoundaries = [];
    @observable withBoundariesAPIStatus = false;
    @observable shadedBackground = [this.mapElectorateToGeoJson({electorateId: 0, parentElectorateId : 0})];

    @computed get selectedRegion() {return this.selectedRegionIndex > -1? this.regions[this.selectedRegionIndex] : undefined;}
    @computed get selectedDistrict() {return this.selectedDistrictIndex > -1? this.districts[this.selectedDistrictIndex] : undefined}
    @computed get selectedCouncil() {return this.selectedCouncilIndex > -1? this.councils[this.selectedCouncilIndex] : undefined}
    @computed get selectedWard() {return this.selectedWardIndex > -1? this.wards[this.selectedWardIndex] : undefined}
    @computed get selectedElectorates() {
        let electorates = [];
        if (this.selectedRegion) electorates.push(this.selectedRegion);
        if (this.selectedDistrict) electorates.push(this.selectedDistrict);
        if (this.selectedCouncil) electorates.push(this.selectedCouncil);
        if (this.selectedWard) electorates.push(this.selectedWard);
        return electorates;
    }
    @computed get electorateSummaryIds() {
        let summaryElectorateIds =[];                        
        if (this.electoratesSummary.ward) summaryElectorateIds.push(this.electoratesSummary.ward.electorateId);
        if (this.electoratesSummary.council) summaryElectorateIds.push(this.electoratesSummary.council.electorateId);
        if (this.electoratesSummary.district) summaryElectorateIds.push(this.electoratesSummary.district.electorateId);
        if (this.electoratesSummary.region) summaryElectorateIds.push(this.electoratesSummary.region.electorateId);
        return summaryElectorateIds;
    }

    @action getElectorateProfileName = (selectedElectorate) => {
        let electorateName = '';
        
        if(selectedElectorate)
        {
            electorateName = selectedElectorate.electorateName.toLowerCase();    
            let boroughIndex;            
            switch(ElectorateType[selectedElectorate.electorateType]) {
                case ElectorateType.Region:            
                    if (electorateName.substring(electorateName.length - 6) !== 'region') {
                        electorateName += (' ' + selectedElectorate.electorateType.toLowerCase());
                    }                
                    electorateName = 'https://www.vec.vic.gov.au/electoral-boundaries/state-regions/' + electorateName.replace(/\s/g, '-')
                 break;
                case ElectorateType.District: 
                    if (electorateName.substring(electorateName.length - 8) !==  'district') {
                        electorateName += (' ' + selectedElectorate.electorateType.toLowerCase());
                    }
                    electorateName = 'https://www.vec.vic.gov.au/electoral-boundaries/state-districts/' + electorateName.replace(/\s/g, '-')
                break;
                case ElectorateType.Ward:   
                case ElectorateType.Council:                 
                    if (selectedElectorate.parentElectorateName !== null) electorateName = selectedElectorate.parentElectorateName.toLowerCase();
                    boroughIndex = electorateName.indexOf('borough');
                    if (boroughIndex > 0) {
                        electorateName = 'borough of ' + electorateName.slice(0,boroughIndex) + 'council';
                    }
                    electorateName = 'https://www.vec.vic.gov.au/electoral-boundaries/local-councils/' + electorateName.replace(/\s/g, '-')
                break;
            }       
        }    

        return electorateName;
    }

    @action cleanUp() {
        runInAction(() => {
            this.setSelectedRegion(null);
            this.setSelectedDistrict(null);
            this.setSelectedCouncil(null);
            this.setSelectedWard(null);            
        });
    }
    @action getElectoratesSummary = (latitude, longitude) => {
        this.appMainStore.setBusy(true);
        return electoratesServcie.getElectoratesSummary(latitude, longitude).then((response) => {
            runInAction(() => {
                this.electoratesSummary = response;
            });
        }).catch((error) => {
            this.getElectoratesSummaryError = error;
        }).finally(() => {
            this.appMainStore.setBusy(false);
        });
    }

    @action getRegions = () => {
        this.appMainStore.setBusy(true)
        return electoratesServcie.getElectorates(ElectorateType.Region).then ((response) => {
            runInAction(() => {
                if (response && response.length) {
                    this.regions.replace(response.map(electorate => this.mapElectorateToGeoJson(electorate)));
                  } else {
                      this.regions.clear();
                  } 
                this.appMainStore.setBusy(false)
            });
        }).catch((error) => {
            this.regions.clear();
            this.getRegionsError = error;
            this.appMainStore.setBusy(false);
        })
    }

    @action getWards = () => {
        this.appMainStore.setBusy(true);
        return electoratesServcie.getElectorates(ElectorateType.Ward).then ((response) => {
            runInAction(() => {
                if (response && response.length) {
                    this.wards.replace(response.map(electorate => {return this.mapElectorateToGeoJson(electorate)}));
                } else {
                    this.wards.clear();
                }
                this.appMainStore.setBusy(false);
            });
        }).catch((error) => {
            this.wards.clear();
            this.getWardsError = error;
            this.appMainStore.setBusy(false);
        })
    }

    @action getDistricts = () => {
        this.appMainStore.setBusy(true);
        return electoratesServcie.getElectorates(ElectorateType.District).then ((response) => {
            runInAction(() => {
                if (response && response.length) {
                    this.districts.replace(response.map(electorate => this.mapElectorateToGeoJson(electorate)));
                } else {
                    this.districts.clear();
                }
                this.appMainStore.setBusy(false);
            });
        }).catch((error) => {
            this.districts.clear();
            this.getDistrictsError = error;
            this.appMainStore.setBusy(false);
        })
    }

    @action getCouncils = () => {
        this.appMainStore.setBusy(true);
        return electoratesServcie.getElectorates(ElectorateType.Council).then ((response) => {
            runInAction(() => {
                if (response && response.length) {
                    this.councils.replace(response.map(electorate => this.mapElectorateToGeoJson(electorate)));
                } else {
                    this.councils.clear();
                }
                this.appMainStore.setBusy(false);
            });
        }).catch((error) => {
            this.councils.clear();
            this.getCouncilsError = error;
            this.appMainStore.setBusy(false);
        })
    }

    @action async setSelectedRegion (selection) {
        if (this.selectedRegion) {
            this.selectedRegion.properties.selected = false;
        }        
        this.selectedRegionIndex = await this.getSelectedElectorate(this.regions, selection);
        if (this.selectedRegion) {
            this.selectedRegion.properties.selected = true;
            if (this.selectedDistrict && selection.electorateId && this.selectedDistrict.parentElectorateId != selection.electorateId ) {
                await this.setSelectedDistrict({});
            }
        }
    }

    @action async setSelectedDistrict (selection) {        
        if (this.selectedDistrict) {
            this.selectedDistrict.properties.selected = false;
        }
        this.selectedDistrictIndex = await this.getSelectedElectorate(this.districts, selection);
        if (this.selectedDistrict) {
            this.selectedDistrict.properties.selected = true;
            if(this.appMainStore.applicationMode != Enums.ApplicationMode.Browse) {
                this.shadedBackground[0].geometry = turfDifference(turfRewind(this.mapStore.vicBoundary[0].geometry), turfRewind(this.selectedDistrict.geometry)).geometry;
            }
        } else {
            this.shadedBackground[0].geometry = {type: 'Polygon', coordinates: []};
        }
    }

    @action async setSelectedCouncil (selection){
        if (this.selectedCouncil) {
            this.selectedCouncil.properties.selected = false;
            this.wards.filter(ward => ward.parentElectorateId == this.selectedCouncil.electorateId).forEach(ward => {
                ward.properties.parentSelected = false;
            });
        }
        this.selectedCouncilIndex = await this.getSelectedElectorate(this.councils, selection);
        if (this.selectedCouncil) {
            this.selectedCouncil.properties.selected = true;
            this.wards.filter(ward => ward.parentElectorateId == this.selectedCouncil.electorateId).forEach(async ward => {
                const wardIndex = await this.getSelectedElectorate(this.wards, ward)
                this.wards[wardIndex].properties.parentSelected = true;
            });
        }
        if (this.selectedWard && (!this.selectedCouncil || this.selectedCouncil.electorateId !== this.selectedWard.parentElectorateId)) {
            this.selectedWard.properties.selected = false;
            this.selectedWardIndex = -1;
        }
    }

    @action async setSelectedWard (selection) {
        if (this.selectedWard) {
            this.selectedWard.properties.selected = false;
        }
        if (selection && selection.electorateId && selection.electorateName !== 'Unsubdivided') {        
            this.selectedWardIndex = await this.getSelectedElectorate(this.wards, selection);
        } else {
            this.selectedWardIndex = -1;
        }
        if(selection && (!this.selectedCouncil || this.selectedCouncil.electorateId !== selection.parentElectorateId)) {
            this.setSelectedCouncil({electorateId: selection.parentElectorateId})            
        }
        if (this.selectedWard) {
            this.selectedWard.properties.selected = true;
        }
    }
    
    @action.bound setTopMost(layer, value){
        switch (layer){
            case ElectorateType.Region:
                if (this.selectedRegion) {
                    this.selectedRegion.properties.topMost = value
                }
                break;
            case ElectorateType.District:
                if (this.selectedDistrict) {
                    this.selectedDistrict.properties.topMost = value;
                }
                break;
            case ElectorateType.Council:
                if (this.selectedCouncil) {
                    this.wards.filter(ward => ward.properties.parentSelected).forEach(ward => {ward.properties.parentTopMost = value;});
                }
                break;
            case ElectorateType.Ward:
                if (this.selectedWard) {
                    this.selectedWard.properties.topMost = value;
                }
                break;        
        }        
    }

    @action.bound setLayerHighLight(layer, value) {
        switch (layer){
            case ElectorateType.Region:
                if (this.selectedRegion) {
                    this.selectedRegion.properties.highlight = value
                }
                break;
            case ElectorateType.District:
                if (this.selectedDistrict) {
                    this.selectedDistrict.properties.highlight = value;
                }
                break;
            case ElectorateType.Council:
                if (this.selectedCouncil) {
                    this.wards.filter(ward => ward.properties.parentSelected).forEach(ward => {ward.properties.highlightCouncil = value;});
                }
                break;
            case ElectorateType.Ward:
                if (this.selectedWard) {
                    this.selectedWard.properties.highlight = value;
                }
                break;        
        }
    }

    mapElectorateToGeoJson(electorate) {
        let result = electorate;
        result.type = 'Feature';
        result.properties = {
            id: electorate.electorateId, 
            parentElectorateId: electorate.parentElectorateId, 
            topMost: false, 
            highlight: false, 
            selected: false, 
            parentSelected: false,
            highlightCouncil: false,
            parentTopMost: false
        };
        result.geometry = {type: 'Polygon', coordinates: []};
        return result
    }

    findElectorateById(targetElectorateId) {
        let electorate;
        this.regions.some(region => {
            if (region.electorateId == targetElectorateId) {
                electorate = region;
                return true;
            } else {
                return false;                
            }
        });
        if (!electorate) {
            this.districts.some(district => {
                if (district.electorateId == targetElectorateId) {
                    electorate = district;
                    return true;
                } else {
                    return false;                
                }
            });
        }
        if (!electorate) {
            this.councils.some(council => {
                if (council.electorateId == targetElectorateId) {
                    electorate = council;
                    return true;
                } else {
                    return false;                
                }
            });
        }        if (!electorate) {
            this.wards.some(ward => {
                if (ward.electorateId == targetElectorateId) {
                    electorate = ward;
                    return true;
                } else {
                    return false;                
                }
            });
        }
        return electorate
    }

    async getSelectedElectorate (electoratesList, selection) {
        let electorateIndex = -1;        
        if (selection && selection.electorateId) {
            electoratesList.some((electorate, index) => {
                if (electorate.electorateId == selection.electorateId) {
                    electorateIndex = index;                    
                    return true;
                } else {
                    return false;
                }
            });            
        }
        if (electorateIndex != -1) {
            if (electoratesList[electorateIndex].geometry.coordinates.length == 0) {
                this.appMainStore.setBusy(true);
                await electoratesService.getElectorate(electoratesList[electorateIndex].electorateId).then(result => {
                    if (result.boundaryJson) {
                            electoratesList[electorateIndex].geometry = JSON.parse(result.boundaryJson);
                    }
                }).finally(() => {
                    this.appMainStore.setBusy(false);
                });
            }
        }
        return electorateIndex;
    }
    
    regionStyle(region) {        
        let topMost = region.getProperty('topMost') == true;
        let highlight = region.getProperty('highlight') == true || topMost;
        let selected = region.getProperty('selected') == true;
        return {visible: selected, strokeWeight: 4, zIndex: topMost? 100: highlight? 99 : 95, fillColor: "#8100f2", fillOpacity: highlight? 0.08: 0.0, strokeColor: '#8100f2', clickable: false};
    }

    districtStyle(district) {
        let topMost = district.getProperty('topMost') == true;
        let highlight = district.getProperty('highlight') == true || topMost;
        let selected = district.getProperty('selected') == true;
        return {visible: selected, strokeWeight: 4, zIndex: topMost? 100: highlight? 99 : 96, fillColor: "#f36d01", fillOpacity: highlight? 0.16: 0, strokeColor: '#f36d01', clickable: false};
    }

    councilStyle(ward) {
        let topMost = ward.getProperty('parentTopMost') == true;
        let highlight = ward.getProperty('highlightCouncil') == true || topMost;
        let selected = ward.getProperty('parentSelected') == true;
        return {visible: selected, strokeWeight: 4, zIndex: topMost? 100: highlight? 99 : 97, fillColor: "#0899fe", fillOpacity: highlight? 0.16: 0, strokeColor: '#0899fe', clickable: false};
    }

    wardStyle(ward) {
        let topMost = ward.getProperty('topMost') == true;
        let highlight = ward.getProperty('highlight') == true || topMost;
        let selected = ward.getProperty('selected') == true;
        return {visible: selected, strokeWeight: 4, zIndex: topMost? 100: highlight? 99 : 98, fillColor: "#56a804", fillOpacity: highlight? 0.24: 0, strokeColor: '#56a804', clickable: false};
    }

    notSelectedDistrictStyle(district) {
        return {visible: true, strokeWeight: 0, zIndex: 95, fillColor: "#757575", fillOpacity: 0.25, clickable: false};
    }
}