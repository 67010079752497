import { observable, action, runInAction, computed } from 'mobx';
import votingCentresService from '../services/voting-centres.service';
import moment from 'moment';
import distance from '@turf/distance';
import turfContains from '@turf/boolean-contains';
import * as Enums from '../enums';
import {Tools} from '../tools';

export default class VotingCentresStore {

    constructor(appMainStore, mapStore, searchStore, electionsStore, electoratesStore, landingDialogStore) {
        this.appMainStore = appMainStore;
        this.mapStore = mapStore;
        this.searchStore = searchStore;
        this.electionsStore = electionsStore;
        this.electoratesStore = electoratesStore;
        this.landingDialogStore = landingDialogStore;
    }    

    @observable getVotingCentresFilterVisibilityError = null;

    @observable votingCentresSearchResult = [];    
    @observable findVotingCentresError = null;    
    @observable selectedVotingCentreId = null;
    @observable bottomVotingCentreSearchResultSelected = false;
    @observable vcElectorate = {};
    @observable votingCentreTypeToShow = Enums.VotingCentreType.ElectionDayVotingCentre;
    @observable showAddressOutOfElectionDialog = false;
    @observable accessibilityTypeToShow = Enums.AccessibilityType.ALL;

    @observable requestParameters = {
        VotingCentreTypes: [],
        MinVotingCentreResults: 0,
        MinEarlyVotingCentreResults: 0,
        MinElectionOfficeResults: 0,
    }
    @observable showVotingCenterDetails = false;

    @computed get selectedVotingCentre() {
        return this.votingCentresSearchResult.find(vc => vc.venueId == this.selectedVotingCentreId);
    }

    @computed get votingCentreDetails() {
        return this.selectedVotingCentre;
    }

    @computed get visibleVotingCentres() {
        let filteredVotingCenters = [];
        switch (this.votingCentreTypeToShow){
            case Enums.VotingCentreType.ElectionDayVotingCentre:
                filteredVotingCenters = this.electionDayVotingCentres;
                break;
            case Enums.VotingCentreType.ElectionOffice:
                filteredVotingCenters = this.electionOffices;
                break;
            case Enums.VotingCentreType.EarlyVotingCentre:
                filteredVotingCenters = this.earlyVotingCentres;
                break;
            case Enums.VotingCentreType.ISOSVotingCentre:
                filteredVotingCenters = this.isosCentres;
                break;
            default:
                return [];
        }
        return filteredVotingCenters.filter(avc => {
            return (this.accessibilityTypeToShow === avc.accessibilityId || this.accessibilityTypeToShow === Enums.AccessibilityType.ALL)
        });
    }

    @computed get votingCentresAvailableOnViewPort() {                
        return this.visibleVotingCentres.filter(vc => {
            let isContained = false;
            this.mapStore.viewBounds.some(bounds => {
                if (turfContains(bounds, { type: 'Point', coordinates: [vc.coordinates.lng, vc.coordinates.lat] })) {
                    isContained = true;
                }
                return isContained;
            });
            return isContained;
        });
    }

    @computed get electionOffices() {
        return this.votingCentresSearchResult.filter(vc => {
            return vc.votingCentreTypeId === Enums.VotingCentreType.ElectionOffice;
        });
    }
    @computed get earlyVotingCentres() {
        return this.votingCentresSearchResult.filter(vc => {
            return vc.votingCentreTypeId === Enums.VotingCentreType.EarlyVotingCentre || vc.votingCentreTypeId === Enums.VotingCentreType.ISOSVotingCentre;
        });
    }
    @computed get isosCentres() {
        return this.votingCentresSearchResult.filter(vc => {
            return vc.votingCentreTypeId === Enums.VotingCentreType.ISOSVotingCentre;
        });
    }
    @computed get electionDayVotingCentres() {
        return this.votingCentresSearchResult.filter(vc => {
            return vc.votingCentreTypeId === Enums.VotingCentreType.ElectionDayVotingCentre;
        })
    }

    @action  toggleShowVotingCenterDetails = (value) => {
        this.showVotingCenterDetails = !!value || (!this.showVotingCenterDetails && this.selectedVotingCentre);
        if (!this.showVotingCenterDetails && Tools.breakpoints.up('sm')) {
            this.setSelectedVotingCentre(null);          
        } else if (this.showVotingCenterDetails && Tools.breakpoints.up('md')) {
            this.appMainStore.openRightDrawer();
        }
    }

    @action async getElectionElectorateVenues(election, electorate, venueType, place) {
        //this.electionsStore.setSelectedElection(election.electionId);
        this.votingCentreTypeToShow = venueType;
        this.vcElectorate = electorate;
        this.searchStore.setSelectedAddress([place])
        if (this.electoratesStore.regions.length == 0 || 
            this.electoratesStore.districts.length == 0 ||
            this.electoratesStore.councils.length == 0 ||                
            this.electoratesStore.wards.length == 0)
        {
            await Promise.all([
                this.electoratesStore.getRegions(),
                this.electoratesStore.getDistricts(),
                this.electoratesStore.getCouncils(),
                this.electoratesStore.getWards(),
            ]);
        }
        switch (electorate? electorate.electorateType : 'None'){
            case 'Region':
                    await this.electoratesStore.setSelectedRegion(electorate);
                    await this.electoratesStore.setSelectedDistrict(null);
                    await this.electoratesStore.setSelectedCouncil(null);
                    await this.electoratesStore.setSelectedWard(null);                    
                break;
            case 'District':
                    await this.electoratesStore.setSelectedRegion(null);
                    await this.electoratesStore.setSelectedDistrict(electorate);
                    await this.electoratesStore.setSelectedCouncil(null);
                    await this.electoratesStore.setSelectedWard(null);                    
                break;
            case 'Council':
                    await this.electoratesStore.setSelectedRegion(null);
                    await this.electoratesStore.setSelectedDistrict(null);
                    await this.electoratesStore.setSelectedCouncil(electorate);
                    await this.electoratesStore.setSelectedWard(null);                    
                break;
            case 'Ward':
                    await this.electoratesStore.setSelectedRegion(null);
                    await this.electoratesStore.setSelectedDistrict(null);
                    await this.electoratesStore.setSelectedCouncil(null);
                    await this.electoratesStore.setSelectedWard(electorate);
                break;
            default:
                    await this.electoratesStore.setSelectedRegion(null);
                    await this.electoratesStore.setSelectedDistrict(null);
                    await this.electoratesStore.setSelectedCouncil(null);
                    await this.electoratesStore.setSelectedWard(null);        
        }
        this.retrieveVotingCenterSearchResults();

    }

    transformVotingCentres = (vCentreLocations, addressPoint) => {
        return vCentreLocations.map((centre) => {
            const wheelchairType = /\(([^)]+)\)/.exec(centre.wheelChairAccessType);
            const vcPoint = {type: 'Point', coordinates: [centre.longitude, centre.latitude]};
            const vcDistance = distance(addressPoint, vcPoint, {units: 'metres'});
            return ({
                ...centre,
                venueId: centre.venueId,
                wheelChairAccessTypeCode: wheelchairType && wheelchairType.length > 1 && wheelchairType[1],
                distance: vcDistance,
                coordinates: {
                    lat: centre.latitude,
                    lng: centre.longitude,
                },
            });
        }).sort((c1, c2) => c1.distance - c2.distance);
    }

    @action async handleAddressSearch(place) {
        this.appMainStore.resetVotingCentreSearchResultScroll();
        this.searchStore.setSelectedAddress([place]);
        this.landingDialogStore.setSearchQueryAddress(place);
        const electionIsState = this.electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.State;
        await this.electoratesStore.getElectoratesSummary(place.geometry.location.lat(), place.geometry.location.lng());
        const addressElectionElectorate = this.electionsStore.selectedElectionContainsElectorate(this.electoratesStore.electorateSummaryIds);
        const addressIsInVic = this.electoratesStore.electorateSummaryIds.length > 0;
        if (!addressElectionElectorate) {
            if (electionIsState & !addressIsInVic) {
                this.votingCentreTypeToShow = Enums.VotingCentreType.ISOSVotingCentre;
                this.electoratesStore.cleanUp();
                this.electionsStore.isAddressSearchInVictoria = false;
                this.accessibilityTypeToShow = Enums.AccessibilityType.ALL;
                this.retrieveVotingCenterSearchResults();
            } else {
                this.showAddressOutOfElectionDialog = true;
            }
        } else {
            this.electionsStore.isAddressSearchInVictoria = true;
            if (electionIsState && this.votingCentreTypeToShow == Enums.VotingCentreType.ISOSVotingCentre) {
                if (moment.tz(this.electionsStore.selectedElection.earlyVotingFinish, 'Australia/Melbourne').isAfter(moment.tz('Australia/Melbourne'))) { 
                    this.votingCentreTypeToShow = Enums.VotingCentreType.EarlyVotingCentre;
                } else {
                    this.votingCentreTypeToShow = Enums.VotingCentreType.ElectionDayVotingCentre;
                }
            }
            await this.electionsStore.getAddressElections(place);                
            this.getElectionElectorateVenues(this.electionsStore.selectedElection, addressElectionElectorate, this.votingCentreTypeToShow, place);
        }
    }

    @action.bound retrieveVotingCenterSearchResults() {

        const electionId = this.electionsStore.selectedElection && this.electionsStore.selectedElection.electionId;
        const {selectedAddress} = this.searchStore
        const addressCoords = [selectedAddress.geometry.location.lng(), selectedAddress.geometry.location.lat()];
        this.appMainStore.setBusy(true);
        if (electionId) {
            votingCentresService.getElectionElectorateVotingCentres(electionId, addressCoords).then((searchResults) => {
                const modifiedSearchResults = searchResults.map((v) => {
                    if (v.accessibilityId === 4) {
                        v.accessibilityId = 0;
                    }
                    return v});
                runInAction(() => {
                    let addressPoint = {type: 'Point', coordinates: addressCoords};
                    this.selectedVotingCentreId = null;                   
                    this.votingCentresSearchResult.replace(this.transformVotingCentres(modifiedSearchResults, addressPoint));
                    switch (this.votingCentreTypeToShow) {
                        case Enums.VotingCentreType.ElectionDayVotingCentre:
                            this.zoomToNearestVenues(3);
                            break;
                        case Enums.VotingCentreType.ElectionOffice:
                            if (this.electionsStore.selectedElection.electionTypeId == Enums.ElectionType.General &&
                                this.electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.State) {
                                    this.zoomToNearestVenues(1, true);
                            } else {
                                this.zoomToNearestVenues(1);
                            }                            
                            break;
                        case Enums.VotingCentreType.EarlyVotingCentre:
                            this.zoomToNearestVenues(2);
                            break;
                        case Enums.VotingCentreType.ISOSVotingCentre:
                            this.zoomToNearestVenues(1);
                            break;
                     }
                });
            }).catch((error) => {
                this.findVotingCentresError = error;
            })
            .finally(() => {
                this.appMainStore.setBusy(false);
            });
        }
    }

    zoomToNearestVenues(num, restrictToElectorate) {
        let nearestVenues;

        let selectedDistrictElectorateName;
        let venuesInsideDistrict = [];

        if (this.electoratesStore.selectedDistrict != undefined)
        {
            selectedDistrictElectorateName = this.electoratesStore.selectedDistrict.electorateName;
            venuesInsideDistrict = this.visibleVotingCentres.filter(vc => ((vc.hostElectorateName == selectedDistrictElectorateName) || (vc.electorateName == selectedDistrictElectorateName))).slice(0, num);
        }

        if (restrictToElectorate) {
            nearestVenues = this.visibleVotingCentres.filter(vc => vc.hostElectorateName == selectedDistrictElectorateName).slice(0, num);
        } else {
            
            nearestVenues = this.visibleVotingCentres.slice(0, num);
                
            if (venuesInsideDistrict.length > 0) {
                nearestVenues = nearestVenues.concat(venuesInsideDistrict);
            }
        }

        // if EO is selected and no venue found, then find closest venue based on selcted accessibility filter.
        if (this.votingCentreTypeToShow == Enums.VotingCentreType.ElectionOffice && nearestVenues.length == 0)
        {
            nearestVenues = this.votingCentresSearchResult.filter(vc => vc.votingCentreTypeId == Enums.VotingCentreType.ElectionOffice && vc.accessibilityId == this.accessibilityTypeToShow).slice(0, num);
        }

        if (nearestVenues.length) {
            this.mapStore.fitMapToVCs(nearestVenues);
        }
    }

    expandCentresInViewport = () => {
        const addNextMarkers = 3//?
        this.zoomToNearestVenues(this.votingCentresAvailableOnViewPort.length + addNextMarkers);
    }

    @action setVotingCentreTypeToShow(type) {
        if (Object.values(Enums.VotingCentreType).includes(type)) {
            this.votingCentreTypeToShow = type;
            switch (type) {
                case Enums.VotingCentreType.EarlyVotingCentre:
                    this.zoomToNearestVenues(2)
                    break;
                case Enums.VotingCentreType.ElectionDayVotingCentre:
                    this.zoomToNearestVenues(3)
                    break;
                case Enums.VotingCentreType.ElectionOffice:
                case Enums.VotingCentreType.ISOSVotingCentre:
                    if (this.electionsStore.selectedElection.electionTypeId == Enums.ElectionType.General &&
                        this.electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.State) {
                            this.zoomToNearestVenues(1, true);
                    } else {
                        this.zoomToNearestVenues(1);
                    }
                    break;
            }
        }
    }

    @action setSelectedVotingCentre(votingCentre) {
        this.selectedVotingCentreId = votingCentre && votingCentre.venueId;
    }

    @action selectVotingCentre(votingCentre) {
        if (this.isSelectedVotingCentre(votingCentre)) {
             this.setSelectedVotingCentre(null);
        } else {
            this.setSelectedVotingCentre(votingCentre);
        }
    }


    @action setAccessibilityTypeToShow(type) {
        if (Object.values(Enums.AccessibilityType).includes(type)) {
            this.accessibilityTypeToShow = type;
            this.setVotingCentreTypeToShow(this.votingCentreTypeToShow);
        }
    }

    isSelectedVotingCentre(votingCentre) {
        return votingCentre.venueId == this.selectedVotingCentreId;
    }

}