import { observable, action } from 'mobx';

export default class LandingDialogCommonStore {    

    @observable step = 0;

    @observable landingDialogOpen = false;
    @action
    setLandingDialogOpen(landingDialogOpen) {
        this.landingDialogOpen = landingDialogOpen;
    }

    @action setStep = (stepNumber) => {
        this.step = stepNumber;
    }
  
}