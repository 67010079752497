import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';


class MarkDownViewer extends React.Component {
    linkNewWindow = (props) => {
        return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>
    }
    htmlElementToDisplay = (props) => {
        switch (this.props.mdName) {
            case 'IM_IntroWindow_Subheading':
                return <h2 className={this.props.className}>{props.children}</h2>;
            case 'IM_CollapsibleBox_Heading':
                return <h1 className={this.props.className}>{props.children}</h1>;
            case 'Alert_Title':
                return <h5 className={this.props.className}>{props.children}</h5>;
            default:
                return <p className={this.props.className}>{props.children}</p>;
        }
    }
    render() {
        const { mdValue, mdName } = this.props;
        return (
            <Markdown name={mdName} source={mdValue} renderers={{ paragraph: this.htmlElementToDisplay, link: this.linkNewWindow }} />
        );
    }
}

MarkDownViewer.propTypes = {
    mdValue: PropTypes.string,
    mdName: PropTypes.string,
    className: PropTypes.string
};

export default MarkDownViewer;