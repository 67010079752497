import React from 'react';
import { render } from 'react-dom';
import { observer, Provider } from 'mobx-react';
import { BrowserRouter} from 'react-router-dom';
import {LoadScript} from "@react-google-maps/api"
import SessionStore from 'stores/session.store';
import AppMain from './components/app-main';
import VecTheme from './components/vec-theme';
import './tools';

export const stores = new SessionStore();

@observer
class Root extends React.Component {
    componentDidMount() {
        // initial data load comes here
    }
    static googleLibs = ['geometry','drawing','places'];
    render() {        
        return (
            <LoadScript id='script-loader' googleMapsApiKey='AIzaSyD9EGolULdpwgQ6OwRW_nFpJdg5bZPOMCQ' channel='InteractiveMap' libraries={Root.googleLibs} language='en' region='AU' onError={() => {stores.appMainStore.flagLoadFailed()}} onLoad={() => {stores.appMainStore.flagLoadSucceeded()}}>
            <Provider {...stores}>
                <VecTheme>
                    <div>
                        <BrowserRouter>
                            <AppMain/>
                        </BrowserRouter>
                    </div>
                </VecTheme>
            </Provider>
            </LoadScript>
        )
    }
}
render(<Root />, document.getElementById('root'));
