import React from 'react';
import { withStyles } from '@material-ui/styles';
import {Typography, Card, CardHeader, CardContent, Link, Divider, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import CancelIcon from '@material-ui/icons/Cancel';
import { ElectorateType } from '../../enums';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';

const styles = (theme) => ({    
    infoContainer: {
        ...theme.overrides.MuiTypography.body2,     
        background: 'white',  
        boxShadow: 'none', 
        width: 'calc(100% - 4px)',
        pointerEvents: 'all',       
        [theme.breakpoints.down('sm')]: {           
            width: '100%',
            marginLeft: '0px',
            marginTop: '-111px',
            borderRadius: '0px',
            border: 'none',
            boxShadow: 'none'
        }, 
        [theme.breakpoints.down('xs')]: { 
            overflow: 'auto',
            maxHeight: '-webkit-calc(100vh - 150px)'           
        }
    }, 
    infoContainerMaxHeight: {
        [theme.breakpoints.down('xs')]: { 
            overflow: 'auto',
            maxHeight: 'calc(100vh - 150px)'           
        }
    },
    infoMobileContainer : {
        marginLeft : '0px',
            marginTop: '65px', 
            width : 'calc(100% - 100px)',
    }, 
    linkContent: {
        padding: '10px',
        background: '#f5f5f5',
        minHeight: '40px'
    },
    heading: {        
        padding: '16px 16px 0px 16px',
        marginBottom: '8px'
    },
    title: {
        ...theme.overrides.MuiTypography.body2,
        fontWeight: 'bold',
        color: theme.palette.navy.main,
    },
    subheading: {
        color: theme.palette.navy.main,
        marginTop: '30px',
        marginBottom: '16px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: { 
            marginTop: '24px',                  
        }, 
    },
    selectedElectorate : {
        color: theme.palette.navy.main,
        marginBottom : '8px',
        fontWeight: 'bold'
    },
    
    divider : {       
        marginTop : '16px',
        marginBottom: '16px',
        height : '2px'     
    },

   
    link: {
        color: theme.palette.navy.main,
        ...theme.overrides.MuiTypography.h6        
    },
    linkDiv : {
        textAlign : 'right',
        marginTop: '16px',
        fontWeight: '600 !important'     
    },
    content: {
        marginBottom: '16px'      
    },
    wardSpan : {
        backgroundColor : theme.palette.green.main,
        color : theme.palette.green.contrastText
    },
    councilSpan : {
        backgroundColor : theme.palette.navy.azure,
        color : theme.palette.navy.contrastText 
    },
    districtSpan : {
        backgroundColor : theme.palette.orange.main,
        color : theme.palette.orange.contrastText
    },
    regionSpan : {
        backgroundColor : theme.palette.purple.main,
        color : theme.palette.purple.contrastText
    },
    backButton : {
        ...theme.overrides.MuiTypography.h5,
        color: theme.palette.navy.main,
        fontWeight: 'bold',        
        textTransform: 'none'    
    },    
    arrowForward : {       
        verticalAlign: 'middle',
        position: 'relative',
        top: '-1px'
    },
    closeButton : {
        width: '16px',
        height: '15.5px'
    },
    label : {
        marginTop : '8px',
        marginBottom: '8px'
    },   
    contentContainer: {
        paddingTop: '0px'
    },
    votePanel: {
        backgroundColor : theme.palette.secondary.contrastText,
        height : '100vw',       
    }
});

@inject('electoratesStore')
@observer
class InfoContainer extends React.Component {    
    constructor(props) {
        super(props);        
    }
 
    handleClose() {
        this.props.closeClickFn();
    }
    
    render () {
        const {colour,selectedElectorate, classes, closeClickFn, width, electoratesStore} = this.props;
        const isDesktopView = isWidthUp('md', width);
        let closeStyle = {};
        let infoContainerStyle = {};
        let dividerStyle = {};
        if(colour && isDesktopView) {
            closeStyle.fill = colour;            
            infoContainerStyle.borderColor = colour;
        } 

        if(colour) {
            dividerStyle.backgroundColor = colour;            
        }

        let selectedInformation = {};
        switch(ElectorateType[selectedElectorate.electorateType]) {
            case ElectorateType.Region :
                    selectedInformation = {
                        heading :  'STATE PARLIAMENT',
                        label:  'Upper House (Legislative Council)',
                        learnMoreText :  'Region and sitting members'  ,
                        learnMoreLink :  electoratesStore.getElectorateProfileName(selectedElectorate),
                        subHeading: ' Voting for the State Parliament'   
                    }
                    break;
            case ElectorateType.District :
                    selectedInformation = {
                        heading :  'STATE PARLIAMENT',
                        label: 'Lower House (Legislative Assembly)',
                        learnMoreText :  'District and sitting members' ,
                        learnMoreLink :  electoratesStore.getElectorateProfileName(selectedElectorate),
                        subHeading: ' Voting for the State Parliament'   
                    }
                    break;
            case ElectorateType.Council:
                    selectedInformation = {
                        heading :  'LOCAL COUNCIL',                
                        learnMoreText :  'Council and councillors',
                        learnMoreLink :  electoratesStore.getElectorateProfileName(selectedElectorate),
                        subHeading: ' Voting for the local council'   
                    }
                 break;
            case ElectorateType.Ward:
                    selectedInformation = {
                        heading :  'LOCAL COUNCIL',                
                        learnMoreText :  'Council and councillors',
                        learnMoreLink :   electoratesStore.getElectorateProfileName(electoratesStore.selectedCouncil),
                        subHeading: ' Voting for the local council'   
                    }
                break;
        }
       
        return (<React.Fragment>
            <Card className={classnames(classes.infoContainer, classes.infoContainerMaxHeight)}  style = {infoContainerStyle}>
                 { !isDesktopView &&
                     <Button  className={classes.backButton}  onClick={()=> this.handleClose()} >
                        <KeyboardBackspaceIcon /> Back 
                    </Button>
                }

                <CardHeader title={selectedInformation.heading} className={classes.heading} classes={{ title: classes.title }}  action={closeClickFn &&
                     isDesktopView &&                        
                            <CancelIcon aria-label="close" onClick={()=> this.handleClose()} style = {closeStyle} />                                      
                }/>
                <CardContent className = {classes.contentContainer}> 
               
                        <Typography variant = "h3" className = {classes.selectedElectorate}>{selectedElectorate.electorateName}</Typography>
                        <Typography variant ="h6" className = {classes.label} > { selectedInformation.label }</Typography>
                       
                        < Divider style = {dividerStyle} className = {classes.divider} />

                        <div className = {classes.linkDiv}>  
                            <Link name="learnMore" href= {selectedInformation.learnMoreLink} target='_blank' className = {classes.link} >
                                <div>
                                    {selectedInformation.learnMoreText } <ArrowForwardIcon fontSize = 'small' className = {classes.arrowForward}  />                                
                                </div>                           
                            </Link>
                        </div>
                </CardContent>
                <CardContent className = {classes.votePanel}>                  
                                        
                            <Typography variant = "h3" className = {classes.subheading}> { selectedInformation.subHeading }</Typography>  
                            <div>
                                {
                                    ElectorateType[selectedElectorate.electorateType] == ElectorateType.Region || ElectorateType[selectedElectorate.electorateType] == ElectorateType.District ? 
                                    <div>
                                        <Typography variant = "h6" className = {classes.content}> Victoria is divided into <span className = {classes.districtSpan}> 88 districts </span> (the Lower House) and <span className = {classes.regionSpan}> eight regions </span> (the Upper House). </Typography> 
                                        <Typography variant = "h6"> In a State election, you vote for one representative for your district and five representatives for your region. </Typography> 
                                    </div>
                                    :
                                    <div>
                                        <Typography variant = "h6" className = {classes.content}> Victoria has <span className = {classes.councilSpan}> 79 councils </span>. Some councils are subdivided into<span className = {classes.wardSpan}> wards </span>. </Typography> 
                                        <Typography variant = "h6">In a council election, you vote for people to represent you in your local area.</Typography> 
                                    </div>
                                }                                
                            </div>
                            <div className = {classes.linkDiv}>
                                <Link name = "howToVote" href = "https://www.vec.vic.gov.au/voting" target='_blank' className = {classes.link} >
                                    <div>
                                        More about voting  <ArrowForwardIcon fontSize = 'small' className = {classes.arrowForward}  />                                 
                                    </div>                                
                                </Link>                          
                            </div>  
                    </CardContent>                   
               
            </Card>            
        </React.Fragment>)
    }

    static propTypes = {
        selectedElectorate : PropTypes.object,
        colour: PropTypes.string.isRequired,
        width: PropTypes.string.isRequired, 
        classes: PropTypes.object,
        closeClickFn: PropTypes.func,
        electoratesStore: PropTypes.object,     
    }
}

export default withStyles(styles)(withWidth()(InfoContainer));
