import 'whatwg-fetch'
import { Tools } from '../tools';

class AdditionalLayersService {
    getAdditionalLayersList() {
        let url = `/api/additionallayers`
        return fetch(url).then(Tools.getResponseJson);
    }

    getAdditionalLayer(layerId) {
        let url = `/api/additionallayers/${layerId}`
        return fetch(url).then(Tools.getResponseJson);
    }
}

const additionalLayersService = new AdditionalLayersService();

export default additionalLayersService;