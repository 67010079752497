import { observable, action, runInAction } from 'mobx';
import staticGeometryServcie from '../services/static-geometry.service';
import { melbourneDefaultCenter } from '../utils/constants';
import {ApplicationMode} from '../enums';
import bbox from '@turf/bbox';
import {Tools} from '../tools';

export default class MapStore {

    bindStores = ({ votingCentresStore, searchStore, appMainStore, browseStore }) => {
        this.searchStore = searchStore;
        this.votingCentresStore = votingCentresStore;
        this.appMainStore = appMainStore;
        this.browseStore = browseStore;
    }

    @observable vicBoundary = [];
    @observable getVictoriaBoundaryError = null;
    @observable viewBounds = [];
    @observable showSearchThisLocationDialog = false;
    @observable searchThisLocationPlace = null;
    @observable map = null;
    @observable googleSDK = null;
    @observable mapOverlay = null;    

    @action setGoogleMaps = ({
        map,
        googleSDK,
    }) => {
        this.map = map;
        this.googleSDK = googleSDK;        
        this.mapOverlay = new google.maps.OverlayView();
        this.mapOverlay.setMap(this.map);        
    
    }

    @action setVictoriaBoundary = (vicBoundary) => {
        this.vicBoundaryCoordinates = vicBoundary;
    }

    @action getVictoriaBoundary = () => {
        staticGeometryServcie.getVictoriaBoundary().then((response) => {
            runInAction(() => {
                this.vicBoundary.replace([{type: 'Feature', geometry: JSON.parse(response.geometryJson), properties:{id: 'vicboundary'}}]);

            });
        }).catch((error) => {
            this.getVictoriaBoundaryError = error;
        });
    }

    @action.bound updateViewPort() {
        if (this.map.getDiv().clientWidth > 0) {
            const bounds = this.map.getBounds();        
            if (bounds) {
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                const n = ne.lat();
                const s = sw.lat();
                let e = ne.lng();
                const w = sw.lng();  
                if (e < w) {
                    this.viewBounds.replace([{type: "Polygon", coordinates: [ [ [w, n], [180, n], [180, s], [w, s], [w, n] ] ]},
                        {type: "Polygon", coordinates: [ [ [-180, n], [e, n], [e, s], [-180, s], [-180, n] ] ]}]);    
                } else {            
                    this.viewBounds.replace([{type: "Polygon", coordinates: [ [ [w, n], [e, n], [e, s], [w, s], [w, n] ] ]}]);    
                }
            }
        }
    }

    @action.bound openSearchThisLocationDialog(latLng) {
        const geocoder = new this.googleSDK.maps.Geocoder();
        geocoder.geocode({ 'location': latLng }, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    this.searchThisLocationPlace = results[0];
                    this.showSearchThisLocationDialog = true;
                }
            }
        });
    }

    @action.bound closeSearchThisLocationDialog() {        
        this.showSearchThisLocationDialog = false;
    }

    @action.bound searchLocation(){
        this.showSearchThisLocationDialog = false;
        if (this.appMainStore.applicationMode == ApplicationMode.Browse) {        
            this.browseStore.handleAddressSearch([this.searchThisLocationPlace]);
        } else {
            this.votingCentresStore.handleAddressSearch(this.searchThisLocationPlace);
        }
    }

    getMapCentre = () => {
        const { selectedAddress } = this.searchStore;
        const selectedAddressCoordinates = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;
        const coordinates = selectedAddressCoordinates && { lat: selectedAddressCoordinates.lat(), lng: selectedAddressCoordinates.lng() };
        return {
            centre: coordinates || melbourneDefaultCenter  };
    };

    fitMapToVCs = (centres) => {
        const bounds = new google.maps.LatLngBounds();
        let viewPortPadding = {bottom: 180, left: 50, right: 80, top: 64};
        if (Tools.breakpoints.up('md')) {
            viewPortPadding.left = 430;
        }
        const { centre } = this.getMapCentre();
        if (centres.length > 0) {
            bounds.extend(centre);
            centres
                .forEach(centre => {
                    const coordinates = centre.coordinates;
                    if (coordinates) {
                        bounds.extend(coordinates);
                    }
                });
            setTimeout(() => {
            this.map.fitBounds(bounds, viewPortPadding);
            }, 500);
        }
    };

    zoomAndCentreOnPolygons(polygons) {
        let viewPortPadding = {};
        if (Tools.breakpoints.up('md')) {
            viewPortPadding.left = 430;
        }
        let boundingBox = bbox(polygons);
        this.map.fitBounds({west: boundingBox[0], south: boundingBox[1],east: boundingBox[2], north: boundingBox[3]}, viewPortPadding);
        this.closeSearchThisLocationDialog(); //This has to be here,if you do it in the search location action IE get confused and the map doesnt know what size it is anymore
    }

    centrePlace(place) {
        if (Tools.breakpoints.equals('sm') && place) {
            const mapProjection = this.mapOverlay.getProjection()
            let point = mapProjection.fromLatLngToContainerPixel(place.geometry.location);
            point.x += -50;
            this.map.setCenter(mapProjection.fromContainerPixelToLatLng(point));
        } else if (place) {
            this.map.setCenter(place.geometry.location);
        }
    }

    centerVotingCentre(votingCentre) {
        if (Tools.breakpoints.equals('sm')) {
            const mapProjection = this.mapOverlay.getProjection()
            let point = mapProjection.fromLatLngToContainerPixel(new google.maps.LatLng(votingCentre.latitude, votingCentre.longitude));
            point.x += -50;
            this.map.setCenter(mapProjection.fromContainerPixelToLatLng(point));
        } else {
            this.map.setCenter(new google.maps.LatLng(votingCentre.latitude, votingCentre.longitude));
        }
    }
}