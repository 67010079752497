import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';

import VotingCentreSearchResult from './voting-center-search-result';

const styles = {
    drawerPaper: {
        width: '100vw',
        height: 0
    },
    drawerPaperOpen: {
        height: 'auto'
    }
};

@inject('votingCentresStore')
@observer
class BottomDrawer extends React.Component {
    render() {
        const { classes, open, votingCentresStore } = this.props;
        const { votingCentresSearchResult } = votingCentresStore;
        const selectedVotingCentre = votingCentresSearchResult.find(result => result.venueId == votingCentresStore.selectedVotingCentreId);

        return (
            <Drawer
                variant="persistent"
                anchor="bottom"
                open={open}
                className={classes.bottomDrawer}
                classes={{
                    paper: classnames(classes.drawerPaper, { [classes.drawerPaperOpen]: open })
                }}
            >
                {
                    selectedVotingCentre &&
                    <VotingCentreSearchResult
                        id={selectedVotingCentre.venueId}
                        votingCenterDetails={selectedVotingCentre}
                        onClick={this.onVotingCentreResultClick} />
                }
            </Drawer>
        );
    }

    onVotingCentreResultClick = () => {
        this.props.votingCentresStore.bottomVotingCentreSearchResultSelected = true;        
        this.props.votingCentresStore.toggleShowVotingCenterDetails();
    }
}

BottomDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    closeClickHandler: PropTypes.func,
    votingCentresStore: PropTypes.object
};

export default withStyles(styles)(BottomDrawer);