import 'whatwg-fetch'
import { Tools } from '../tools';

class AlertsService {
    getAllModuleAlerts(module) {
        let url = `/api/alerts/${module}`
        return fetch(url).then(Tools.getResponseJson);
    }
}

const alertsService = new AlertsService();

export default alertsService;