import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { inject } from 'mobx-react';
import { Typography, Link } from '@material-ui/core';
import mapIcon from '../../../../static/images/map.svg';
import arrowImage from '../../../../static/images/arrow.svg';

const styles = theme => {
    return ({
        browseHeading: {
            color: theme.palette.secondary.main,
            fontSize: '28px',
            fontWeight: 'bold',
            lineHeight: '1.29',
            [theme.breakpoints.down('md')]: {
                width: 'calc(100vw - 140px)',
                fontSize: '24px',
                overflowWrap: 'break-word',
            },
            [theme.breakpoints.down('sm')]: {
                lineHeight: '1.31',
                fontSize: '22px'
            },            
            [theme.breakpoints.down('xs')]: {
                lineHeight: '1.45',
                fontSize: '20px',
                width: 'calc(100vw - 40px)',
            }
        },
        browsePanel: {
            width: '100vw',
            minHeight: '183px',
            background: 'linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.5))',
            paddingTop: '81px',
            paddingLeft: '166px',
            paddingBottom: '80px',
            [theme.breakpoints.down('sm')]: {
                paddingTop: '79px',
                paddingLeft: '45px',
            },
            [theme.breakpoints.down('xs')]: {
                paddingTop: '31px',
                paddingLeft: '20px',
            }
        },
        sectionText: {
            color: theme.palette.secondary.charcoal,
            marginTop: '35px',
            fontSize: '18px',            
            fontStretch: 'normal',
            lineHeight: '1.89',
            letterSpacing: '0.4px',
            width: '541px',
            [theme.breakpoints.down('sm')]: {
                lineHeight: '1.56',
                width: '678px',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '15px',
                width: 'calc(100vw - 40px)',               
            }
        },
        viewMap: {
            color: theme.palette.secondary.main,
            letterSpacing: '0.4px',
            fontSize: '16px',
            fontWeight: '600',
            lineHeight: '1.75',
            marginTop: '21px',
            [theme.breakpoints.down('sm')]: {
                lineHeight: '1.63',
            },
            [theme.breakpoints.down('xs')]: {
                marginTop: '28px',
                paddingBottom: '42px',                
            }
        },
        mapIcon: {
            verticalAlign: 'middle',
            marginRight : '10.2px',
            width: '24px',
            height: '24px',
        },
        arrowForward: {
            verticalAlign: 'middle',
            position: 'relative',
            paddingLeft: '10px',
            width: '14px',
            height: '14px',
            objectFit: 'contain',
            [theme.breakpoints.down('sm')]: {               
                width: '20px',
                height: '20px',          
            },
            [theme.breakpoints.down('xs')]: {               
                width: '18px',
                height: '12px',       
            } 
        },
    });
}

@inject('appMainStore', 'searchStore')
class BrowseFooter extends React.Component {
    render() {
        const  {classes } = this.props
        return (<div className={classes.browsePanel}>
            <Typography className={classes.browseHeading}>Find your region, district and council electoral boundaries</Typography>
            <Typography className={classes.sectionText}>Victoria&#39;s electoral boundaries have changed.</Typography>
            <div className={classes.viewMap}>
                <Link href='/' underline='none' color='secondary' onClick={(event) => { this.handleBrowseElectoratesClick(event) }}>
                    <img  alt='map with pin' src={mapIcon} className={classes.mapIcon} />
                    Go to interactive map
                                <img alt='forward arrow' src={arrowImage} className={classes.arrowForward} />
                </Link>
            </div>
        </div>)
    }


    handleBrowseElectoratesClick(event) {
        let targetUrl = '/';
        if (this.props.searchStore.selectedAddress) {
            targetUrl += '?address=' + this.props.searchStore.selectedAddress.formatted_address;
        }
        this.props.appMainStore.history.push(targetUrl);
        event.preventDefault();
        return false;
    }

    static propTypes = {
        classes: PropTypes.object.isRequired,
        searchStore: PropTypes.object,
        appMainStore: PropTypes.object,
    }
}

export default withStyles(styles)(BrowseFooter);