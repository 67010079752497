import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import { compose, withProps, } from "recompose";

import { withStyles } from '@material-ui/core/styles';
import GpsFixed from '@material-ui/icons/GpsFixed';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import {Clear, Search} from '@material-ui/icons';
import { KeyPressCodes } from '../../../enums';
import { debounce } from '../../../utils/debounce';

import { styles } from './address-search-box.styles';
import { IconButton } from '@material-ui/core';
import { autorun } from 'mobx';

const MAX_PREDICTIONS = 3;
const getDisplayName = (place) => {
    if ((place.types || []).indexOf('establishment') !== -1) {
        return `${place.name? place.name: ''} ${place.formatted_address}`;
    }
    return place.formatted_address;
}

const insertDefaultAddress = (defaultAddress, predictionsInput) => {
    if (!defaultAddress) {
        return predictionsInput;
    }
    const predictions = (predictionsInput || []).filter(prediction => (prediction && prediction.place_id) !== defaultAddress.place_id);
    predictions.unshift(defaultAddress);
    return predictions;
};

@inject('searchStore', 'appMainStore')
@observer
class AddressSearchBox extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            inputText: '',
            selectedPredictionIndex: null,
            hovering: false,
        };
        this.sessionToken = null;
    }
    
    input = null;

    componentDidMount() {
        const { defaultAddress, appMainStore } = this.props;
        const { predictions } = this.state;
        if (defaultAddress) {
            this.setState({
                defaultAddress,
                inputText: getDisplayName(defaultAddress),
                predictions: insertDefaultAddress(defaultAddress, predictions),
            });
        }
        if (this.autocompleteService && appMainStore.initialAddressText.length != 0) {
            //Inject text from the search
            this.grabPlacePredictions(appMainStore.initialAddressText, true);
            appMainStore.initialAddressText = '';
        }    
    }

    componentDidUpdate() {
        //Do this to handle lazy loading of the google api
        const { google, map, appMainStore } = this.props;
        if (this.input && google && map && !this.autocompleteService && !this.placesService) {
            this.autocompleteService = new google.maps.places.AutocompleteService();
            this.placesService = new google.maps.places.PlacesService(map);
            this.sessionToken = new google.maps.places.AutocompleteSessionToken();  
            document.addEventListener('click', this.handleSearchLoseFocus);
        }    
        if (this.autocompleteService && appMainStore.initialAddressText.length != 0) {
            //Inject text from the search
            this.grabPlacePredictions(appMainStore.initialAddressText, true);
            appMainStore.initialAddressText = '';
        }    
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.defaultAddress !== this.props.defaultAddress) {
            if (this.props.defaultAddress) {
                this.setState({
                    defaultAddress: this.props.defaultAddress,
                    inputText: getDisplayName(this.props.defaultAddress),
                    predictions: insertDefaultAddress(this.props.defaultAddress, this.state.predictions),
                });
            } else {
                this.setState({
                    inputText: ''
                });
            }
        }
        return null;
    }

    componentWillUnmount() {
        if (this.input) {
            document.removeEventListener('click', this.handleSearchLoseFocus);
        }
        this.input = null;
        this.autocompleteService = null;
        this.placesService = null;
    }

    render() {
        const { classes, defaultAddress, gray,placeholder, isLandingInput } = this.props;
        const { searchResultsShown, predictions, selectedPredictionIndex, hovering } = this.state;

        return (
            <div className={classes.autocompleteWrapper} islandingdialog={(!!isLandingInput).toString()}>
                <div className={
                    classnames(classes.inputWrapper, {
                        predictionsShown: searchResultsShown && ((predictions || []).length || isWidthDown('sm', this.props.width)),
                        gray,
                    })
                }
                    islandingdialog={(!!isLandingInput).toString()}>
                    <div className={classnames(classes.search, {
                        [classes.clickable]: true,
                        clickable: true,
                    })} >
                        {(defaultAddress) ?
                            (<IconButton id='clear-icon' aria-label='clear button' className={classes.closeIcon} onClick={this.handleClearAddress} islandingdialog={(!!isLandingInput).toString()}><Clear/></IconButton>) :
                            (<IconButton id='search-icon' aria-label='search button' className={classes.closeIcon} onClick={this.handleExecuteSearch} islandingdialog={(!!isLandingInput).toString()}><Search/></IconButton>)}
                    </div>
                    <input
                        id="FP_SearchAddress"
                        aria-label='address search input'
                        className={classes.autocompleteInput}
                        islandingdialog={(!!isLandingInput).toString()}
                        autoComplete="off" //This is to hide the chrome autocomplete box because it obscures our autocomplete results
                        ref={this.handleSaveInput}
                        placeholder= {placeholder}
                        value={this.state.inputText}
                        onKeyDown={(e) => {this.handleKeypress(e)}}
                        onInput={this.handleAddressChange}
                        onFocus={this.handleSearchGainFocus}
                        onChange={()=>{}} //This is here to suppress react warning
                    />
                </div>
                <div className={classes.predictionPopover}>
                    <div
                        className={
                            classnames(classes.autocompletePredictionsWrapper, {
                                [classes.autocompletePredictionsShown]: searchResultsShown,
                            })
                        }
                    >
                        <div id="FP_UseCurrentLocation" onClick={this.handleUseMyLocation} className={[classes.autocompletePrediction, classes.autocompleteShowMyLocation].join(' ')}>
                            <GpsFixed /> <span>Use my current location</span>
                        </div>
                        {
                            (predictions || []).slice(0, MAX_PREDICTIONS).map((prediction, index) => (
                                prediction && (
                                    <div
                                        id={`FP_VotingCentrePrediction_${index}`}
                                        key={`${prediction.place_id}-${index}`}
                                        onMouseEnter={this.handleHovering(true)}
                                        onMouseLeave={this.handleHovering(false)}
                                        className={classnames(classes.autocompletePrediction, { active: !hovering && selectedPredictionIndex === index })}
                                        onClick={(e) => {
                                            this.handleSelectPrediction(prediction);
                                            e.stopPropagation();
                                        }}
                                    >
                                        {prediction.description}
                                    </div>
                                )
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }

    getPredictionsPosition = () => {
        const { inputRectangle } = this.state;
        if (inputRectangle) {
            return {
                position: 'absolute',
                left: inputRectangle.left - 1,
                top: inputRectangle.top + 2,
                width: inputRectangle.width + 2,
                backgroundColor: 'white',
            };
        }
        return {
            position: 'relative',
        }
    }

    handleHovering = (value) => () => {
        this.setState({ hovering: value });
    }

    handleKeypress(e) {
        const { predictions, selectedPredictionIndex } = this.state;
        if (e.keyCode === KeyPressCodes.Enter) {
            if (predictions && predictions.length) {
                const index = selectedPredictionIndex || 0;
                const currentPrediction = predictions.length && predictions[index];
                if (currentPrediction) {
                    this.setState({
                        currentPrediction: null,
                        searchResultsShown: false,
                    });
                    this.handleSelectPrediction(currentPrediction);
                    e.stopPropagation();
                    return;
                }
            }
        }
        if (e.keyCode === KeyPressCodes.Up) {
            if (selectedPredictionIndex - 1 > 0) {
                this.setState({
                    selectedPredictionIndex: selectedPredictionIndex - 1,
                });
            } else {
                this.setState({ selectedPredictionIndex: 0 });
            }
            return;
        }
        if (e.keyCode === KeyPressCodes.Down) {
            if (selectedPredictionIndex === null) {
                this.setState({
                    selectedPredictionIndex: 0,
                });
            } else if (selectedPredictionIndex + 1 < predictions.length) {
                this.setState({
                    selectedPredictionIndex: selectedPredictionIndex + 1,
                });
            } else {
                this.setState({
                    selectedPredictionIndex: predictions.length - 1,
                });
            }
            return;
        }
        if (selectedPredictionIndex !== null) {
            this.setState({
                selectedPredictionIndex: null,
            });
        }
    }

    handleExecuteSearch = () => {
        const { predictions } = this.state;
        if (predictions && predictions[0]) {
            this.props.onPlacesChanged([predictions[0]]);
        }
    }

    handleClearAddress = () => {
        this.setState({
            defaultAddress: null,
            inputText: '',
            predictions: [],
        });

        this.props.onPlacesChanged(null);
        this.props.searchStore.clearSearchAddress();
        document.getElementById("FP_SearchAddress").focus();
    }

    handleUseMyLocation = () => {
        const google = this.props.google;

        if (!navigator.geolocation) {
            return alert('You do not have location services enabled');
        }

        const handleSuccess = (position) => {
            const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ 'latLng': latlng }, (results, status) => {
                // TODO:
                // OVER_QUERY_LIMIT can be a problem.
                if (status !== google.maps.GeocoderStatus.OK) {
                    alert(`Failed to get location. Status code: ${status}`);
                }

                this.props.onPlacesChanged(results);
            });

        };

        const handleError = ({ code, message }) => {
            alert(`${code} - ${message}`);
        };

        navigator.geolocation.getCurrentPosition(handleSuccess, handleError, { enableHighAccuracy: false });
    }

    handleSelectPrediction(selectedPrediction) {
        const inputText = (selectedPrediction && selectedPrediction.description) || '';
        this.getPlaceDetails(google, selectedPrediction).then((place) => {
            this.setState({
                inputText,
            });

            this.input.blur();
            this.props.onPlacesChanged([place]);
        });
    }

    handleSavePopup = ref => {
        if (!this.popover) {
            this.popover = ref;
        }
    }

    handleSaveInput = ref => {
        if (!this.input) {
            this.input = ref;
        }
    }

    handleAddressChange = (e) => {
        e.persist();
        this.setState(
            {
                inputText: e.target.value,
                searchResultsShown: true,
            },
            () => {
                this.grabPlacePredictions(e.target.value);
            }
        );
    }

    handleSearchGainFocus = () => {
        this.setState({
            searchResultsShown: true,
        });
    }

    handleSearchLoseFocus = (e) => {
        if (this.input && !this.input.contains(e.target)) {
            this.setState({
                searchResultsShown: false,
            });
        }
    }

    getPlacePredictions = (google, inputText) => new Promise((resolve, reject) => {
        this.autocompleteService.getPlacePredictions(
            {
                input: inputText,
                types: ['geocode'],
                sessionToken: this.sessionToken,  
            },
            (predictions, status) => {
                if (status !== google.maps.places.PlacesServiceStatus.OK) {
                    reject(new Error(` search returned no result. Status: ${status}`));
                    this.setState({
                        predictions: [],
                        error: new Error(` search returned no result. Status: ${status}`)
                    });
                    return;
                }
                resolve(predictions);
            },
        );
    })

    getPlaceDetails = (google, prediction) => {        
        if (!this.placesService || !prediction) {
            return null;
        }        
        return new Promise((resolve) => {
            this.placesService.getDetails({ placeId: prediction.place_id, fields: ['formatted_address', 'geometry', 'utc_offset_minutes'], sessionToken: this.sessionToken }, (place, status) => {
                this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(place);
                } else {
                    resolve(null);
                }
                //the session token is only good for one details call to we need a new one incase the user searches again for a different address                
            });


        });
    }

    grabPlacePredictions = debounce(
        async (inputText, autoPickFirstResult) => {
            if (!this.autocompleteService || !this.placesService) {
                return;
            }
            if (!inputText) {
                this.setState({
                    predictions: [],
                    error: null,
                });
                return;
            }
            try {
                const predictions = await this.getPlacePredictions(this.props.google, inputText);
                this.setState({
                    predictions: (predictions || []),
                    error: null,
                });
                if (autoPickFirstResult && predictions.length == 1) {
                    this.handleSelectPrediction(predictions[0])();
                }
            } catch (error) {
                this.setState({
                    predictions: [],
                    error,
                });
            }
        },
        250,
    )
}

AddressSearchBox.propTypes = {
    searchStore: PropTypes.object,
    appMainStore: PropTypes.object,
    gray: PropTypes.bool,
    google: PropTypes.object,
    classes: PropTypes.object,
    map: PropTypes.object,
    onPlacesChanged: PropTypes.func.isRequired,
    defaultAddress: PropTypes.object,
    width: PropTypes.any,
    placeholder: PropTypes.string.isRequired,
    isLandingInput: PropTypes.bool
};

export default compose(
    withStyles(styles),
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `100%` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withWidth(),
)(AddressSearchBox);