import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MainMap from '../map/main-map';

const styles = theme => ({
    content: {
        flexGrow: 1,
        marginTop: '64px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '56px',
        }
    }
});

class Content extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.content} >
                <MainMap isMarkerShown />
            </div>
        );
    }
}

Content.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Content);
