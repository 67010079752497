import AppMainStore from './app-main.store';
import SearchStore from './search.store';
import ElectoratesStore from './electorates.store';
import MapStore from './map.store';
import VotingCentresStore from './voting-centres.store';
import LandingDialogStore from './landing-dialog.store';
import LandingDialogCommonStore from './landing-dialog-common-store';
import AdditionalLayersStore from './additional-layers.store';
import ElectionsStore from './elections.store';
import BrowseStore from './browse.store';

export default class SessionStore {
    constructor() {
        this.appMainStore = new AppMainStore();
        this.electoratesStore = new ElectoratesStore(this.appMainStore);
        this.mapStore = new MapStore();
        this.searchStore = new SearchStore();
        this.landingDialogStore = new LandingDialogStore();
        this.landingDialogCommonStore = new LandingDialogCommonStore();
        this.electionsStore = new ElectionsStore(this.appMainStore, this.electoratesStore, );
        this.browseStore  = new BrowseStore(this.electoratesStore, this.searchStore, this.mapStore, this.electionsStore, this.appMainStore);
        this.votingCentresStore = new VotingCentresStore(this.appMainStore, this.mapStore, this.searchStore, this.electionsStore, this.electoratesStore, this.landingDialogStore);
        this.additionalLayersStore = new AdditionalLayersStore(this.appMainStore, this.mapStore);
                
        this.appMainStore.bindStores({
            landingDialogCommonStore: this.landingDialogCommonStore,
            landingDialogStore: this.landingDialogStore,
            votingCentresStore: this.votingCentresStore,
            electoratesStore: this.electoratesStore,
            electionsStore: this.electionsStore     
        });
        this.searchStore.bindStores({ landingDialogStore: this.landingDialogStore, appMainStore: this.appMainStore });
        this.mapStore.bindStores({ votingCentresStore: this.votingCentresStore, searchStore: this.searchStore, appMainStore: this.appMainStore, browseStore: this.browseStore });
        this.landingDialogStore.bindStores({ landingDialogCommonStore: this.landingDialogCommonStore, votingCentresStore: this.votingCentresStore, searchStore: this.searchStore, appMainStore: this.appMainStore });       
        this.electoratesStore.bindStores({mapStore : this.mapStore})
    }
}
