import 'whatwg-fetch';
import { Tools } from '../tools';

class VotingCentresService {
    findVotingCentres(filter) {
        const url = '/api/votingcentres';
        return fetch(url, {
            method: 'post',
            body: JSON.stringify(filter),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(Tools.getResponseJson);
    }

    getElectionElectorateVotingCentres(electionId, coords){
        let url = `/api/votingcentres/${electionId}/?longitude=${coords[0]}&latitude=${coords[1]}`;
        return fetch(url)
            .then(Tools.getResponseJson);
    }
}

const votingCentresService = new VotingCentresService();

export default votingCentresService;