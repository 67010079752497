import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/IconButton';
import {Link} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { observer, inject } from 'mobx-react';
import MarkDownViewer from './../common/markdown-viewer';
import * as Enums from '../../enums';

const styles = theme => ({
    detailPanel: {
        borderColor: '#0899fe',
        borderStyle: 'solid',
        borderWidth: '3px',
        height: 'calc(100vh - 70px)',
        overflowY: 'auto',
        [theme.breakpoints.down('md')]: {
            height: 'calc(100% - 65px)',      
            zIndex: 2,
            position: 'absolute',      
            boxSizing: 'border-box',
            background: 'white'
        },
        [theme.breakpoints.down('xs')]: {
            height: 'calc(100% - 57px)',      
            marginBottom: '0px',
            overflowY: 'auto',
            marginTop: '57px',
            position: 'initial',      
            zIndex: 'auto',
        }
    },
    detailPanelHeader: {
        height: '224px',
        width: '100%',
        padding: '0 16px 24px 24px',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        alignItems: 'flex-end',
        boxSizing: 'border-box',
        fontSize: '16px'
    },
    detailPanelBody: {
        padding: '24px',
        backgroundColor: '#fff'
    },
    detailPanelBodySection: {
        marginTop: '24px'
    },
    detailPanelBodySectionTitle: {
        margin: '0 0 4px 0',
        fontWeight: 'bold',
        fontSize: '12px',
        color: '#9a9a9a',
        textTransform: 'uppercase'
    },
    detailPanelBodySectionName: {
        ...theme.overrides.MuiTypography.h5,
        margin: '0 0 4px 0',
        fontWeight: 'bold'
    },
    iconButton: {
        position: 'absolute',
        right: '24px',
        top: '10px',
        width: '20px',
        height: '20px'
    },
    markdownWrapper: {
        fontSize: '16px',
        ['& ul']: {
            ['& a']: {
                color: theme.palette.primary.main
            },
            listStyleType: 'none',
            paddingLeft: '0px'
        },
        ['& li']: {
            paddingLeft: '2.3em',
            backgroundImage: 'url(images/outline-launch.svg)',
            backgroundRepeat: 'no-repeat',
            marginTop: '10px',
            paddingTop: '3px',
            listStyleType: 'none'
        },
        alignItems: 'center'
    }
});


@inject('electoratesStore', 'appMainStore', 'electionsStore')
@observer
class CouncilDetailsPanel extends Component {
    render() {
        const { classes, closeClickHandler, electoratesStore, appMainStore, electionsStore } = this.props;
        if (appMainStore.rightDrawerOpen && electoratesStore.electoratesSummary && electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.Municipal) {
            const { electoratesSummary } = electoratesStore;
            const { council, ward } = electoratesSummary;
            return (
                <div className={classes.detailPanel}>
                <div className={classes.detailPanelHeader} >
                    {  
                        <div className={classes.markdownWrapper}>
                            <MarkDownViewer mdName="IM_Council_Intro_Text"
                                    mdValue={'There are **79 local councils** in Victoria.  Elections take place on the fourth Saturday in October, every four years.' + ' Voters elect councillors to represent them in the local council for their area. Councillors make decisions about local issues.'}  />
                        </div>
                    }
                    <Button id="btn-council-details-panel-close" classes={{ root: classes.iconButton }} onClick={closeClickHandler}><CloseIcon /></Button>
                </div>
                <div className={classes.detailPanelBody}>
                    <div className={classes.detailPanelBodySection}>
                        <p id="lbl-council-details-lower-house" className={classes.detailPanelBodySectionTitle}>Council</p>
                        {
                            council &&
                            <p id="council-details-council-name" className={classes.detailPanelBodySectionName}>{council.electorateName}</p>
                        }
                        {  
                            <div className={classes.markdownWrapper}>
                                <li>
                                    <Link name="IM_Council_Profile_Link" target='_blank' underline='always' href={ electoratesStore.getElectorateProfileName(council) }>Council profile</Link>                               
                                </li>
                                Read detailed information about the Council.
                            </div>
                        }
                    </div>
                    {
                        ward && ward.electorateName !== 'Unsubdivided' &&
                        <div className={classes.detailPanelBodySection}>
                            <p id="lbl-council-details-wards" className={classes.detailPanelBodySectionTitle}>Council Ward</p>
                            <p className={classes.detailPanelBodySectionName}>{ward.electorateName} Ward</p>                                        
                        </div>
                    }   
                </div>
            </div>
            );
        } else {
            return null;
        }
    }
}

CouncilDetailsPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    closeClickHandler: PropTypes.func.isRequired,
    electoratesStore: PropTypes.object,
    electionsStore: PropTypes.object,
    appMainStore: PropTypes.object
};

export default withStyles(styles)(CouncilDetailsPanel);