import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Route, Redirect, withRouter, Switch } from 'react-router-dom'
import Header from './common/header';
import LeftDrawer from './common/left-drawer';
import Content from './common/content';
import ElectionsPanel from './elections/elections-panel';
import BrowsePanel from './browse/browse-panel';
import AppLoadingSpinner from './Common/app-loading-spinner';
import Alerts from './common/alerts';

const styles = theme => ({
    appFrame: {        
        overflow: 'hidden',
        position: 'fixed',
        display: 'flex',
        zIndex: 1,
        width: '100%',
        height: '100%',
        marginBottom: '-64px',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('xs')]: {
            marginBottom: '-56px',
        },
    },
    floatingPanel: {
        position: 'fixed',
        top: '88px',
        left: '24px',
        width: '400px',
        maxHeight: 'calc(100% - 114px)',
        overflowY: 'auto',        
        zIndex: 1,        
        ['& .inactive']: {
            backgroundColor: '#f5f5f5',
        },
        [theme.breakpoints.up('md')]:{
            overflow: 'visible',            
        },
        [theme.breakpoints.down('sm')]:{
            position: 'absolute',
            left: '0px',
            zIndex: 1,
            backgroundColor: 'transparent',
            top: '64px',
            overflow: 'visible'
        },
        [theme.breakpoints.down('xs')]: {
            top: '56px',            
            maxHeight: 'initial',
            width: '100%',
        }
    },
});

@inject('appMainStore')
@observer
class AppMain extends React.Component {
    constructor(props) {
        super(props);
        if (props.appMainStore.isOld == null) props.appMainStore.checkSupportedBrowser();        
        if (props.appMainStore.isOld || props.appMainStore.loadFail) {
            setTimeout(() => {
                window.location.replace("https://vec.vic.gov.au");
            }, 10000);
        }
        
        this.props.appMainStore.bindHistory(this.props.history);
    }
    componentDidMount() {
        this.props.appMainStore.determineUserLocation();
        this.props.appMainStore.getAllActiveMapConfigurationValues();
        this.props.appMainStore.getAllModuleAlerts();
        this.openLandingDialog();
    }
   
    render() {
        const { classes, appMainStore, history } = this.props;

        return ( 
            appMainStore.isOld| appMainStore.loadFail? <div>
                {appMainStore.loadFail? 'There was an error to loading the map': 'You are using an unsupported browser.'}<br/> 
                You will be redirected to <a href='https://www.vec.vic.gov.au/'>https://www.vec.vic.gov.au/</a><br/>
                If you are not redirected in 10 seconds please click <a href='https://www.vec.vic.gov.au'>here</a>.
            </div>: 
            <div className={classes.appFrame}>            
                <AppLoadingSpinner show={appMainStore.isBusy}/>
                <Alerts history = {history} /> 
                <Header menuClickHandler={this.onHeaderMenuClick} /> 
                    <Switch>
                        <Route exact path='/elections'> <ElectionsPanel style = {classes.floatingPanel} /> </Route>
                        <Route exact path='/'> <div className={classes.floatingPanel}><BrowsePanel /> </div></Route>
                        <Redirect to='/'/>
                </Switch>
                <LeftDrawer open={appMainStore.leftDrawerOpen} closeClickHandler={this.onLeftDrawerCloseClick} />
                <Content />
            </div>
        );
    }

    onToggleMobileListView = () => {
        this.props.appMainStore.setMobileSearchListOpen(!this.props.appMainStore.mobileSearchListOpen);
    }

    onHeaderMenuClick = () => {
        this.props.appMainStore.setLeftDrawerOpen(true);
    }

    onSearchResultClick = () => {
        this.props.appMainStore.setRightDrawerOpen(true);
    }

    onLeftDrawerCloseClick = () => {
        this.props.appMainStore.setLeftDrawerOpen(false);
    }

    openLandingDialog = () => {
        this.props.appMainStore.openLandingDialog();
    }
}

AppMain.propTypes = {
    classes: PropTypes.object.isRequired,
    appMainStore: PropTypes.object,
    history: PropTypes.object
};

export default withStyles(styles)(withRouter(AppMain));
