import React from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const baseTheme = createMuiTheme();
const defaultVecTheme =  {
    overrides: {        
        MuiAppBar: {
            root: {
                backgroundColor: '#ffe3ea !important',
                color: '#0E3A6C !important',
                '& svg': {
                    color: '#0E3A6C !important'
                }
            }
        },
        MuiInput: {
            root: {
                '&$disabled': {
                    borderColor: '#e2e2e2 !important',
                },
            },
        },
        MuiIconButton: {
            root: {
                [baseTheme.breakpoints.down('sm')]: {
                    padding: '8px'
                }
            }
        },
        MuiLink:{
            root: {
                cursor: 'pointer !important',
                fontWeight: 600,
            }
        },
        MuiSvgIcon: {
            root: {
                height: '1em',
                width: '1em'
            }
        },   
        MuiTab: {
            root:{
                textTransform: 'initial',
                fontSize: '0.8em'
            }
        },    
        MuiSelect: {
            select: {
                letterSpacing: '2px',
                textTransform: 'uppercase'
            },
        },
        MuiExpansionPanel: {
            root: {
                ['&::before']: {
                    backgroundColor: 'transparent'
                },
                ['&$expanded']: {
                    marginTop: '8px',
                }
            }
        },
        MuiExpansionPanelSummary: {
            root: {
                padding: '0px 16px',
                ['&$expanded']: {
                    minHeight: 'initial',
                },
            },
            content: {
                margin: '16px 0px 15px 0px',
                ['&$expanded']: {
                    margin: '16px 0px 15px 0px',
                }
            }
        },
        MuiTypography: {            
            h1: {
                fontSize: '26px',
            },
            h2: {
                fontSize: '22px',
                fontWeight: 700,
                fontStretch: 'normal'
            },
            h3: {
                fontSize: '18px',
                fontWeight: 600,
                fontStretch: 'normal'
            },
            h4: {
                fontSize: '19px',
            },
            h5: {
                fontSize: '16px',
            },
            h6: {
                fontSize: '14px',
            },                      
            subtitle1: {
                fontSize: '14px',
                textTransform: 'uppercase',
                weight: 700,                
            },
            subtitle2: {
                fontSize: '14px',
                textTransform: 'uppercase',                
            },
            body1: {
                fontSize: '14px',                
            },
            body2: {
                fontSize: '12px',
            },
        },
        [baseTheme.breakpoints.down('sm')]: {
            h1: {
                fontSize: '26px',
            },
            h2: {
                fontSize: '26px',
            },
            h3: {
                fontSize: '22px',
            },
            h4: {
                fontSize: '18px',
            },
            h5: {
                fontSize: '14px',               
            },
            h6: {
                fontSize: '12px', 
            },           
            subtitle1: {
                fontSize: '19px',
                textTransform: 'uppercase',
            },
            subtitle2: {
                fontSize: '16px',
                textTransform: 'uppercase',                
            },
            body1: {
                fontSize: '14px',                
            },
            body2: {
                fontSize: '12px',
            },
        }     
    },    
    palette: {
        primary: {
            light: '#da475d',
            main: '#a30134',
            dark: '#84002a',
            contrastText: '#ffffff'
        },
        secondary: {
            light: '#b6d7ec',
            main: '#0e3a6c',
            dark: '#0e253a',
            charcoal: '#39444f',
            webpink: '#ffe3ea',
            contrastText: '#f7f7f7'
        },
        navy: {
            light: '#54A0D2',
            main: '#0e3a6c',
            dark: '#00396b',
            contrastText: '#f7f7f7',
            azure: '#0899fe'
            
        },
        gray: {
            gray100: '#f5f5f5',
            gray200: '#e2e2e2',
            gray300: '#c2c2c2',
            gray400: '#757575',
            gray500: '#6f6f6f',
            gray600: '#37444e'
        },
        orange: {
            main: '#f36d01',
            contrastText: '#f7f7f7'
        },
        purple : {
            main: '#7d34f1',
            contrastText: '#f7f7f7'
        },
        green: {
            main: '#56a804',
            contrastText: '#f7f7f7'
        },
        cloudyBlue: '#b6c3d2'
    },

    typography: {    
        fontFamily: [
            'Poppins',
            'Roboto',
            'Arial',
            'sans-serif'
        ].join(',')
    }
};

const defaultVecMaterialTheme = createMuiTheme(defaultVecTheme);

class VecTheme extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={defaultVecMaterialTheme}>
                {this.props.children}
            </MuiThemeProvider>
        );
    }
}

VecTheme.propTypes = {
    children: PropTypes.object.isRequired,
};

export default VecTheme;