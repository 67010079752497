import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Typography, Button } from '@material-ui/core';
import * as Enums from '../../enums';

const styles = (theme) => {
    const { spacing } = theme;
    const unit2x = `${spacing(2)}px`;
    return ({
        container: {
            backgroundColor: 'white',
            padding: unit2x,
        },
        filtersContainer:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '10px',
            '& button': {
                fontSize: '10px',
                fontWeight: 600,
                [theme.breakpoints.down('xs')]: {
                    padding: '5px 5px'
                }
            }
        }
    })
}

@inject('searchStore', 'electionsStore', 'votingCentresStore')
@observer
class AddressSearchFiltersBox extends React.Component {
    render() {
        const { classes, } = this.props;
        const {isBeforeEarlyVotingCutoff, isAddressSearchInVictoria, selectedElection} = this.props.electionsStore;
        const {votingCentreTypeToShow} = this.props.votingCentresStore;
        
        return (<React.Fragment>
                {isAddressSearchInVictoria && isBeforeEarlyVotingCutoff && selectedElection && selectedElection.electionMethod !== 'Postal' ?<div className={classes.container}>
                    <Typography variant='h4' color='secondary'>Nearby voting centres</Typography>
                    <div className={classes.filtersContainer}>
                        <Button color='primary' onClick={() => {this.updateVotingCentreType(Enums.VotingCentreType.EarlyVotingCentre);}} variant={votingCentreTypeToShow == Enums.VotingCentreType.EarlyVotingCentre? 'contained' : 'outlined'}>Early Voting</Button>
                        <Button color='primary' onClick={() => {this.updateVotingCentreType(Enums.VotingCentreType.ElectionDayVotingCentre);}} variant={votingCentreTypeToShow == Enums.VotingCentreType.ElectionDayVotingCentre? 'contained' : 'outlined'}>Election Day Voting</Button>
                        <Button color='primary' onClick={() => {this.updateVotingCentreType(Enums.VotingCentreType.ElectionOffice);}} variant={votingCentreTypeToShow == Enums.VotingCentreType.ElectionOffice? 'contained' : 'outlined'}>Election Office</Button>
                    </div>
                </div>: null
                }
            </React.Fragment>
        );
    }

    updateVotingCentreType(type) {
        this.props.votingCentresStore.setVotingCentreTypeToShow(type);
    }

}

AddressSearchFiltersBox.propTypes = {
    classes: PropTypes.object.isRequired,
    searchStore: PropTypes.object,
    electionsStore: PropTypes.object,
    votingCentresStore: PropTypes.object
};

export default withStyles(styles)(AddressSearchFiltersBox);
