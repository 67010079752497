import 'whatwg-fetch'
import { Tools } from '../tools';

class MapContentService {
    getAllActiveMapConfigurationValues(module) {
        let url = `/api/content/${module}`
        return fetch(url).then(Tools.getResponseJson);
    }
}

const mapContentService = new MapContentService();

export default mapContentService;