import { observable, action } from 'mobx';
import electoratesService from '../services/electorates.service';

export default class BrowseStore {    

    constructor(electoratesStore, searchStore, mapStore, electionsStore, appMainStore) {
        this.electoratesStore = electoratesStore;
        this.searchStore = searchStore;
        this.mapStore = mapStore;
        this.electionsStore = electionsStore;
        this.appMainStore = appMainStore;
    }

    @observable infoDialogOpen = false;
    @action
    setInfoDialogOpen(infoDialogOpen) {
        this.infoDialogOpen = infoDialogOpen;
    }

    @observable infoDrawerOpen = -1;
    @action
    setInfoDrawerOpen(value) {
        this.infoDrawerOpen = value;
    }
    
    @action.bound async handleAddressSearch(places) {
        this.setInfoDrawerOpen(-1);
        if (places && places.length) {
            this.searchStore.setSelectedAddress(places);
            this.appMainStore.setBusy(true);
            await electoratesService.getElectoratesSummary(places[0].geometry.location.lat(), places[0].geometry.location.lng()).then(async (electorates) => {
                if (this.electoratesStore.regions.length == 0 || 
                    this.electoratesStore.districts.length == 0 ||
                    this.electoratesStore.councils.length == 0 ||                
                    this.electoratesStore.wards.length == 0)
                {
                    await Promise.all([
                        this.electoratesStore.getRegions(),
                        this.electoratesStore.getDistricts(),
                        this.electoratesStore.getCouncils(),
                        this.electoratesStore.getWards(),
                    ]);
                }
                
                await Promise.all([
                    this.electoratesStore.setSelectedRegion(electorates.region),
                    this.electoratesStore.setSelectedDistrict(electorates.district),
                    this.electoratesStore.setSelectedCouncil(electorates.council),
                    this.electoratesStore.setSelectedWard(electorates.ward)
                ]);
                

                if (this.electoratesStore.selectedDistrict || this.electoratesStore.selectedCouncil) {
                    let zoomObj = {type: 'FeatureCollection', features: [this.electoratesStore.selectedDistrict, this.electoratesStore.selectedCouncil]};
                    this.mapStore.zoomAndCentreOnPolygons(zoomObj);
                } else {
                    this.mapStore.centrePlace(places[0]);
                }
                if (electorates.length != 0) {
                    this.appMainStore.isLocationInVictoria = true;
                } else {
                    this.appMainStore.isLocationInVictoria = false;
                }
                this.electionsStore.getElectorateElections(electorates).then(() => {
                    this.searchStore.setSearchPerformed(true);
                });
            })
            .finally(() => {
                this.appMainStore.setBusy(false);
            }); 
        }
        else {
            this.electionsStore.elections = [];
            this.searchStore.setSearchPerformed(false);
        }      
    }

    @action async setSelectedRegion(selection, zoomAndCenter) {
        await this.electoratesStore.setSelectedRegion(selection);        
        if (this.electoratesStore.selectedRegion && zoomAndCenter) {
            let zoomObj = {type: 'FeatureCollection', features: [this.electoratesStore.selectedRegion]};
            this.mapStore.zoomAndCentreOnPolygons(zoomObj);
            this.searchStore.setSelectedAddress(null);
        }
    }

    @action async setSelectedDistrict(selection, zoomAndCenter) {
        await this.electoratesStore.setSelectedDistrict(selection);
        if (this.electoratesStore.selectedDistrict && zoomAndCenter) {
            let zoomObj = {type: 'FeatureCollection', features: [this.electoratesStore.selectedDistrict]};
            this.mapStore.zoomAndCentreOnPolygons(zoomObj);
            this.searchStore.setSelectedAddress(null);
        }
    }

    @action async setSelectedCouncil(selection, zoomAndCenter) {
        await this.electoratesStore.setSelectedCouncil(selection);
        if (this.electoratesStore.selectedCouncil && zoomAndCenter) {
            let zoomObj = {type: 'FeatureCollection', features: [this.electoratesStore.selectedCouncil]};
            this.mapStore.zoomAndCentreOnPolygons(zoomObj);
            this.searchStore.setSelectedAddress(null);
        }
    }

    @action async setSelectedWard(selection, zoomAndCenter) {
        await this.electoratesStore.setSelectedWard(selection);
        if (this.electoratesStore.selectedWard && zoomAndCenter) {
            let zoomObj = {type: 'FeatureCollection', features: [this.electoratesStore.selectedWard]};
            this.mapStore.zoomAndCentreOnPolygons(zoomObj);
            this.searchStore.setSelectedAddress(null);
        }
    }
}