import 'whatwg-fetch'
import { Tools } from '../tools';

class ElectoratesService {
    getElectoratesSummary(latitude, longitude) {
        let url = `/api/electorates/summary?latitude=${latitude}&longitude=${longitude}`;
        return fetch(url).then(Tools.getResponseJson);
    }

    getElectorate(electorateId) {
        let url = `/api/electorates/${electorateId}/withboundary`;
        return fetch(url).then(Tools.getResponseJson);
    }

   getElectorates(electorateType) {
       let url = `/api/electorates/` + electorateType;
       return fetch(url , {
           method: "get",
           headers: {
            'Content-Type': 'application/json'
           }
        }).then(Tools.getResponseJson);       
   }

   getElectoratesBoundaries(electorateType) {
        let url = `/api/electorates/${electorateType}/withboundaries`;
        return fetch(url , {
            method: "get",
            headers: {
            'Content-Type': 'application/json'
            }
        }).then(Tools.getResponseJson);       
    }
}

const electoratesService = new ElectoratesService();

export default electoratesService;