import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import { observer, inject } from 'mobx-react';
import classnames from 'classnames';
import * as Enums from '../../enums';

import VotingCentreDetailsPanel from './voting-centre-details-panel';
import DistrictDetailsPanel from './district-details-panel';
import CouncilDetailsPanel from './council-details-panel';

const styles = theme => ({
    rightDrawer: {
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    drawerPaper: {
        marginTop: '64px',
        width: 0,
        height: 'calc(100vh - 64px)',
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: '56px',
            height: 'calc(100vh - 56px)',
        }
    },
    drawerPaperDistrict: {
        marginTop: '64px',
        width: 0,
        minHeight: 'calc(100vh - 64px)',
        zIndex: theme.zIndex.drawer + 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: '56px',
            minHeight: 'calc(100vh - 56px)',
        }
    },
    drawerPaperOpen: {
        width: '420px',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    drawerPaperDistrictOpen: {
        width: '420px',
        '@media (min-device-width: 800px) and (max-device-width: 1280px)': {
            backgroundColor: '#f5f5f5'
        },
        [theme.breakpoints.down('md')]: {
            backgroundColor: '#f5f5f5'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    }
});

@inject('votingCentresStore', 'electoratesStore', 'appMainStore', 'electionsStore')
@observer
class RightDrawer extends React.Component {
    render() {
        const { classes, open, closeClickHandler, votingCentresStore, electoratesStore, appMainStore, electionsStore } = this.props;

        return (
            <React.Fragment>
            {open && 
            <Drawer
                variant="persistent"
                anchor="right"
                open={open}
                className={classes.rightDrawer}
                classes={{
                    paper: classnames(votingCentresStore.selectedVotingCentreId ? classes.drawerPaper : classes.drawerPaperDistrict, { [votingCentresStore.selectedVotingCentreId ?  classes.drawerPaperOpen : classes.drawerPaperDistrictOpen]: open })
                }}
                >

                {
                    appMainStore.applicationMode != Enums.ApplicationMode.Browse && votingCentresStore.selectedVotingCentreId &&
                    <VotingCentreDetailsPanel closeClickHandler={closeClickHandler} />
                }
                {
                    appMainStore.applicationMode != Enums.ApplicationMode.Browse && electoratesStore.electoratesSummary &&
                    electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.State &&
                    <DistrictDetailsPanel closeClickHandler={closeClickHandler} />
                }
                  {
                    appMainStore.applicationMode != Enums.ApplicationMode.Browse && electoratesStore.electoratesSummary
                     && electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.Municipal &&
                    <CouncilDetailsPanel closeClickHandler={closeClickHandler} />
                }
            </Drawer>
            }
            </React.Fragment>
        );
    }

}

RightDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    closeClickHandler: PropTypes.func.isRequired,
    votingCentresStore: PropTypes.object,
    electoratesStore: PropTypes.object,
    appMainStore: PropTypes.object,
    electionsStore: PropTypes.object
};

export default withStyles(styles)(RightDrawer);