import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { VotingCentreType } from '../../enums'

import { styles } from './voting-center-search-results.styles';

import ShapeIcon from '../../../static/images/misc/shape.svg';
import { WheelchairIcon } from '../../components/common/svg-icons';

@inject('appMainStore')
@observer
class VotingCenterSearchResult extends React.Component {

    render() {
        const { classes, selected, votingCenterDetails, onClick, id } = this.props;
        const svgIconPropsConfig = {
            width: "14",
            height: "16",
            viewBox: "0 0 14 16",
            fill: '#A30134'
        };

        return (
            <li
                aria-label='Venue Details'
                id={`FP_VotingCentre_${id}`}
                className={
                    `${classnames(classes.listItem, {
                        [classes.listItemActive]: selected,
                    })}`
                }
                key={id}
                onClick={() => onClick(votingCenterDetails)}
                ref={c => this.componentRef = c}
            >
                <div className={classes.listItemLeft}>
                    <p id={"voting-center-details" + id} className={classes.listItemLeftTitle}>{votingCenterDetails.venueName ? votingCenterDetails.venueName.replace("EO - EVC", "") : votingCenterDetails.venueName}</p>
                    <p className={classes.listItemLeftSubtitle}>
                        {votingCenterDetails.addressLine1}, {votingCenterDetails.addressLine2} {votingCenterDetails.addressLine3}
                    </p>
                    <p className={classes.listItemLeftSubtitle}>{this.formatDistance(votingCenterDetails.distance)}</p>
                    <p className={`${classes.listItemLeftFooter} ${classes.votingCenterTypesListContainer}`}>
                        { (votingCenterDetails.votingCentreTypes) ? 
                            votingCenterDetails.votingCentreTypes.map((votingCenter, index) => 
                                <span  key={index}>
                                    <span>{votingCenter}</span> 
                                    {(index !== votingCenterDetails.votingCentreTypes.length - 1) && <span className={classes.dotSeparator}></span>}
                                </span>)
                             :(votingCenterDetails.votingCentreTypeId === VotingCentreType.ISOSVotingCentre) ? "" : <span>{votingCenterDetails.votingCentreType ? votingCenterDetails.votingCentreType.replace("IS/OS ","") : votingCenterDetails.votingCentreType}</span>
                        }
                    </p>
                </div>
                <div className={classes.listItemRight}>
                    {
                        votingCenterDetails.wheelChairAccessTypeCode &&
                        <div className={classes.listItemRightTransportation}>
                            {WheelchairIcon(svgIconPropsConfig)}
                            <span>{votingCenterDetails.wheelChairAccessTypeCode}</span>
                        </div>
                    }
                    <div className={classes.listItemRightIndicator}>
                        <img alt='details icon' src={ShapeIcon} />
                    </div>
                </div>
            </li>
        );
    }

    componentDidMount() {
        // setup refs - add ref only if parent component is the results list
        if (this.props.index != null) {
            this.props.appMainStore.searchResultComponentRefs[this.props.id] = this.componentRef;
        }
    }

    componentWillUnmount() {
        // clean up refs
        if (this.props.index != null) {
            this.props.appMainStore.searchResultComponentRefs[this.props.id] = null;
        }
    }

    formatDistance(distance) {
        let result = distance.toFixed(0) + ' m';  // show in meters for distances between 0 - 1 KM

        if (distance >= 1000 && distance <= 100000) {
            // show in kilometers for distances between 1 - 100 KM
            result = (distance / 1000).toFixed(1) + ' km';
        }

        if (distance > 100000) {
            // show in kilometers for distances grater than 100 KM
            result = (distance / 1000).toFixed(0) + ' km';
        }

        return result;
    }
}

VotingCenterSearchResult.propTypes = {
    classes: PropTypes.object.isRequired,
    votingCenterDetails: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    index: PropTypes.number,
    id: PropTypes.any,
    appMainStore: PropTypes.object,
};

export default withStyles(styles)(VotingCenterSearchResult);