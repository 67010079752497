import { observable, action } from 'mobx';
import additionalLayersService from '../services/additional-layers.service';
import * as Enums from '../enums';
import centreOfMass from '@turf/center-of-mass';

export default class AdditionalLayersStore {

    constructor(appMainStore) {
        this.appMainStore = appMainStore;
    }

    @observable layers = []    

    @action loadLayersList () {
        this.appMainStore.setBusy(true);
        this.layers.clear();
        additionalLayersService.getAdditionalLayersList().then(layers => {
            this.layers.replace(layers.sort((a,b) => {return b.orderPosition-a.orderPosition}).map(layer => {return observable({show: false, showLabels: false, labels: [], ...layer})}));
        }).finally(() => {
            this.appMainStore.setBusy(false);
        });
    }

    @action.bound hideAll(){
        this.layers.forEach((layer) =>{
            layer.show = false;
            layer.showLabels = false;
        });
    }

    @action.bound loadLayerGeometry(layerId) {
        this.appMainStore.setBusy(true);
        additionalLayersService.getAdditionalLayer(layerId).then(additionalLayer=> {
            let labels = [];
            additionalLayer.features.forEach(feature => {                
                feature.type = 'Feature';
                feature.properties = {
                    id: feature.additionalLayerDataID,
                    label: feature.label
                }
                feature.geometry = JSON.parse(feature.jsonGeometry);
                if (additionalLayer.hasLabels) {
                    const centre = centreOfMass(feature);
                    labels.push({text: feature.label, position: {lat: centre.geometry.coordinates[1], lng: centre.geometry.coordinates[0]}});
                }
            });
            const loadedLayer = this.layers.find(layer => layer.additionalLayerID == layerId);
            loadedLayer.features.replace(additionalLayer.features);
            loadedLayer.labels.replace(labels);            
        }).finally(() => {
            this.appMainStore.setBusy(false);
        });

    }

    @action.bound toggleLayer(layerId) {
        this.layers.some(layer => {
            if (layer.additionalLayerID == layerId) {
                layer.show = !layer.show;
                if (layer.show && layer.sourceType == Enums.AdditionalLayerType.GISDB && layer.features.length == 0) {
                    this.loadLayerGeometry(layerId);
                }
                return true;
            } else {
                return false;
            }
        });
    }

    @action.bound toggleLayerLabels(layerId) {
        this.layers.some(layer => {
            if (layer.additionalLayerID == layerId) {
                layer.showLabels = !layer.showLabels;
                return true;
            } else {
                return false;
            }
        });
    }

    additionalLayerStyleFn(styleObjectString, orderPosition) {        
        let style;
        try {
            style = {...JSON.parse(styleObjectString)}
        } catch (e) {
            style = {};
        }        
        style.zIndex = orderPosition;
        style.clickable = false;
        return style;
    }
}