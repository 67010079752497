import { observable, action } from 'mobx';

export default class SearchStore {
    bindStores({ landingDialogStore, appMainStore }) {
        this.landingDialogStore = landingDialogStore;
        this.appMainStore = appMainStore;
    }

    @observable selectedAddress = null;
    @observable searchPerformed = false;

    @action setSelectedAddress(places) {
        this.selectedAddress = places && places[0];
    }

    @action setSearchPerformed(value) {
        this.searchPerformed = value;
    }

    @action clearSearchAddress() {
        this.selectedAddress = null;
        this.landingDialogStore.reset();
        this.appMainStore.closeRightDrawer();
    }
}