import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { styles } from './voting-center-search-results.styles';
import DistrictIcon from '../../../static/images/search/DistrictIcon.svg';
import CouncilIcon from '../../../static/images/search/CouncilIcon.svg';
import ShapeIcon from '../../../static/images/misc/shape.svg';
import VotingCenterSearchResult from './voting-center-search-result';
import AddressSearchFiltersBox from './address-search-filters-box';
import AccessibilityFiltersBox from './accessibility-filters-box';
import { Tools } from '../../tools';
import * as Enums from '../../enums';

@inject('appMainStore', 'mapStore', 'votingCentresStore', 'electoratesStore', 'searchStore', 'electionsStore')
@observer
class VotingCenterSearchResults extends React.Component {
    selectedAccessibilityType = Enums.AccessibilityType.ALL;

    componentDidUpdate() {
        this.props.appMainStore.calCulateMaxHeightForListContainer(this.props);
    }

    isMobileShown() {
        return this.props.appMainStore.mobileSearchListOpen && Tools.breakpoints.down('sm');
    }

    render() {
        const { classes, votingCentresStore } = this.props;
        const { visibleVotingCentres, votingCentresAvailableOnViewPort } = votingCentresStore;
      
        if (!visibleVotingCentres.length) {
        //    return null;
        }
        
        const hasNext = visibleVotingCentres.length > votingCentresAvailableOnViewPort.length;
        const {selectedElectorates} = this.props.electoratesStore;
        const selectedElectorateNames = selectedElectorates.map(e => {return e.electorateType == 'Council'? e.electorateName : e.electorateName + ' ' + e.electorateType}).join(', ');
        const { root, listContainer, listContainerMaxHeight, listItem, listItemTopPath, listItemTop, listItemRight, listItemRightIndicator, listContainerEarlyVoting, listContainerOutsideVictoria,listContainerBaseStyle, listContainerElectionDay, listContainerEarlyVotingIOS, listContainerElectionDayIOS, listContainerOutsideVictoriaIOS} = classes;
        let showVenueList = Tools.breakpoints.up('md') || !votingCentresStore.showVotingCenterDetails;
        const {isAddressSearchInVictoria, isBeforeEarlyVotingCutoff } = this.props.electionsStore;
        const isDeviceIOS = Tools.isDeviceIOS();
        
        return (          
            <div className={classnames(root)}  ref={this.setSearchResultsComponentRef}>
                {
                    (Tools.breakpoints.up('sm') && selectedElectorates.length)?
                    (<ul id="votingSearchResultElectorate" className={classnames(listContainer, listContainerMaxHeight)}>                      
                            <li className={listItem} onClick={() => {this.onDistrictResultClick()}}>
                                <div className={listItemTopPath}>
                                    <img alt='electorate type icon' src={selectedElectorates[0].electorateType == 'Council'? CouncilIcon : DistrictIcon} />
                                </div>
                                <div className={listItemTop} >
                                    <p>The address you searched is in:</p>
                                    <p id="votingSearchResultElectorateName" className='search-results-electorate-name'>{selectedElectorateNames}</p>
                                </div>
                                <div className={listItemRight}>
                                    <div className={listItemRightIndicator}>
                                        <img alt='show electorate info icon' src={ShapeIcon} />
                                    </div>
                                </div>
                            </li>                      
                    </ul> )
                    : null
                }
                {
                    showVenueList && <div>
                        <AddressSearchFiltersBox />
                        <AccessibilityFiltersBox />

                        <ul id="votingSearchResultList" 
                            className={classnames(listContainerBaseStyle, 
                                (isAddressSearchInVictoria && isBeforeEarlyVotingCutoff && isDeviceIOS) ? listContainerEarlyVotingIOS : 
                                    (isAddressSearchInVictoria && isBeforeEarlyVotingCutoff && !isDeviceIOS) ? listContainerEarlyVoting : 
                                    (isAddressSearchInVictoria && isDeviceIOS) ? listContainerElectionDayIOS :
                                    (isAddressSearchInVictoria && !isDeviceIOS) ? listContainerElectionDay : 
                                    (!isAddressSearchInVictoria && isDeviceIOS) ? listContainerOutsideVictoriaIOS : listContainerOutsideVictoria) } >
                                {votingCentresAvailableOnViewPort.map(
                                    (result, index) => (
                                        <VotingCenterSearchResult
                                            id={result.venueId}
                                            key={'vc' + index + result.venueId}
                                            index={index}
                                            selected={votingCentresStore.selectedVotingCentreId === result.venueId}
                                            votingCenterDetails={result}
                                            onClick={this.onVotingCentreResultClick} />
                                    )
                                )}
                        </ul>
                            {
                                hasNext ? 
                                    <div className={classes.listFooter} onClick={this.handleShowMore}>
                                        <a id="floatingPanelShowMore-button" className={classes.listFooterButton}>Show more</a>
                                    </div>
                                : null
                            }
                            {<div className={classes.listItemScrollingPlaceholder} />}
                    </div>
                }  
            </div>
        );
    }

    setSearchResultsComponentRef = (element) => {
        if (element) {
            this.props.appMainStore.searchResultsComponentRef = element;
        }
    }
    
    handleShowMore = () => {
        const { votingCentresStore } = this.props;
        votingCentresStore.expandCentresInViewport();
    }

    onVotingCentreResultClick = (votingCentre) => {
        this.props.electoratesStore.electoratesSummary = null;
        let showDetails = false;
        if (!this.props.votingCentresStore.isSelectedVotingCentre(votingCentre)) {        
            showDetails = true;
        }
        this.props.votingCentresStore.selectVotingCentre(votingCentre);
        this.props.mapStore.centerVotingCentre(votingCentre);
        this.props.votingCentreClicked();
        this.props.votingCentresStore.toggleShowVotingCenterDetails(showDetails);
    }

    onDistrictResultClick = () => {        
        const { selectedAddress } = this.props.searchStore;
        if (!this.props.appMainStore.rightDrawerOpen || !!this.props.votingCentresStore.selectedVotingCentre) {
            const selectedAddressCoordinates = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;
            this.props.votingCentresStore.setSelectedVotingCentre(null);
            this.props.electoratesStore.getElectoratesSummary(selectedAddressCoordinates.lat(), selectedAddressCoordinates.lng());
            this.props.appMainStore.openRightDrawer();
         } else {
             this.props.appMainStore.closeRightDrawer();
         }
    }
}

VotingCenterSearchResults.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.bool,
    appMainStore: PropTypes.object,
    mapStore: PropTypes.object,
    votingCentresStore: PropTypes.object,
    electoratesStore: PropTypes.object,
    searchStore: PropTypes.object,
    electionsStore: PropTypes.object,
    isAlertMessageAvailable: PropTypes.bool,
    isAlertTitleAvailable: PropTypes.bool,
    votingCentreClicked: PropTypes.func
};

export default compose(
     withStyles(styles))(VotingCenterSearchResults);