import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { DirectionIcon } from '../../components/common/svg-icons'
import Info from '@material-ui/icons/InfoOutlined';
import { VotingCentreType, AccessibilityType } from '../../enums';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Hidden from '@material-ui/core/Hidden';
import { wheelChairAccessibilityType } from '../../utils/constants'
import * as Enums from '../../enums';

const styles = theme => ({
    detailPanelHeader: {
        height: '245px',
        width: '100%',
        color: 'white',
        padding: '0 16px 24px 24px',
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'flex-end',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: '130px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '31px'
        },
        ['& h1']: {
            ...(theme.overrides.MuiTypography.h1),
            marginBottom: '16px'
        },
        ['& p']: {
            fontSize: '13px',
            marginBottom: '0',
            fontWeight: 'bold',
            textTransform: 'uppercase'
        }
    },
    detailPanelBody: {
        width: 'inherit',
        margin: '24px',
        minHeight: 'calc(100vh - 550px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 500px)',
            paddingBottom: '16px',
        }
    },
    detailPanelBodyNotInVIC: {
        width: 'inherit',
        padding: '0 24px',
        minHeight: 'calc(100vh - 550px)',
        [theme.breakpoints.down('sm')]: {
            minHeight: 'calc(100vh - 510px)',
            paddingBottom: '16px'
        }
    },
    detailPanelBodySection: {
        marginTop: '24px',
        ['& p']: {
            ...(theme.overrides.MuiTypography.h5),
            margin: '0 0 4px 0'
        },
        ['& p:first-child']: {
            fontWeight: 'bold'
        },
        ['& ul']: {
            ...(theme.overrides.MuiTypography.h5)
        }
    },
    detailPanelBodySubSection: {
        marginTop: '12px',
        ['& p:first-child']: {
            ...(theme.overrides.MuiTypography.h6),
            fontWeight: 'bold'
        }
    },
    detailPanelBodySubSubHeader: {
        marginTop: '10px',
        marginBottom: '4px',
        fontSize: '11px',
        color: '#9a9a9a',
        fontWeight: 'bold'
    },
    iconButton: {
        position: 'absolute',
        right: '24px',
        top: '24px',
        color: 'white',
        width: '20px',
        height: '20px'
    },
    dotSeparator: {
        width: '3px',
        display: 'inline-block',
        background: '#ffffff',
        height: '3px',
        border: '1px solid #ffffff',
        borderRadius: '3px',
        margin: ' 0 6px'
    },
    getDirectionBtnWrapper: {
        width: 'calc(100% - 48px)',
        display: 'flex',
        margin: '0 24px',
        paddingBottom: '16px',
        marginBottom: '25px',
        [theme.breakpoints.down('md')]: {
            marginBottom: '100px'
      },
      '@media (min-device-width: 800px) and (max-device-width: 1280px)': {
            marginTop: '20px'
      }
    },
    getDirectionBtn: {
        width: '100%',
        height: '54px',
        margin: '0',
        borderRadius: '4px',
        backgroundColor: theme.palette.primary.contrastText,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        fontWeight: 'bold',
        fontSize: '18px',
        ['&:hover']: {
            backgroundColor: theme.palette.primary.contrastText,
        },
        ['&.active']: {
            backgroundColor: theme.palette.primary.contrastText,
        },
        ['svg']: {
            paddingRight: '8px'
        },

    },
    markdownWrapper: {
        marginTop: '30px',
        marginRight: '10px',
        marginBottom: '20px',
        backgroundColor: '#f5f5f5',
        paddingLeft: '10px',
        paddingRight: '10px',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '4px',
        ['& p']: {
            fontSize: '13px',
            paddingLeft: '8px',
            fontWeight: 600
        },
        ['& svg']: {
            fontSize: '18px'
        },
        width: 'calc(100% - 10px)',
        [theme.breakpoints.down('xs')]: {
            ['& p']: {
                fontSize: '12px'
            }
        }
    },
    panelContainer: {
        display: 'inline',
        backgroundColor: '#fff',
        [theme.breakpoints.down('sm')]: {
            overflowY: 'auto',
            height: 'calc(100vh - 50px) !important',            
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '24px',
            display: 'inherit',
        },
    },
    locationComment: {
        display: 'block'
    },
    backToResults: {
        fontSize: '16px',
        fontWeight: '600',
        color: 'white',
        letterSpacing: '0.4px',
        lineHeight: '26px'
    }
});
const svgPropsConfig = {
    style: { paddingRight: '8px' },
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: '#a30134'
};

@inject('votingCentresStore', 'appMainStore', 'searchStore', 'electionsStore')
@observer
class VotingCentreDetailsPanel extends React.Component {
    render() {
        const {votingCentresStore} = this.props;
        if (votingCentresStore.selectedVotingCentreId && votingCentresStore.showVotingCenterDetails) {
            return (
                <div>
                    {this.renderVotingCentreDetails()}
                </div>
            );
        } else {
            return null;
        }
    }

    getWheelChairAccessType(wheelChairAccessTypeCode) {
        let wheelChairAccessType;
        switch (wheelChairAccessTypeCode) {
            case "IWA":
                wheelChairAccessType = wheelChairAccessibilityType.IWA.label;
                break;
            case "AWA":
                wheelChairAccessType = wheelChairAccessibilityType.AWA.label;
                break;
            case "LNWA":
                wheelChairAccessType = wheelChairAccessibilityType.LNWA.label;
                break;
        }
        return wheelChairAccessType;
    }

    getVotingCentres = (votingCentreDetails) => {
        let result = [];

        if (votingCentreDetails.electionDayDetails) {
            result.push(votingCentreDetails.electionDayDetails);
        }

        if (votingCentreDetails.earlyVotingDetails) {
            result.push(votingCentreDetails.earlyVotingDetails);
        }

        if (votingCentreDetails.electionOfficeDetails) {
            result.push(votingCentreDetails.electionOfficeDetails);
        }

        return result;
    }

        renderVotingCentreDetails() {
        const { classes, closeClickHandler, votingCentresStore, electionsStore } = this.props;
        const { votingCentreDetails } = votingCentresStore;        

        if (!votingCentreDetails) {
            return null;
        }
        const votingCentre = votingCentresStore.selectedVotingCentre;
        const votingCentreTypeIds = [votingCentre.votingCentreTypeId]; 

        if (!votingCentre) {
            return null;
        }

        const accessibilityDetails = votingCentre.accessibilityDetails ? votingCentre.accessibilityDetails : [];
        const operationDetails = votingCentre.operationDetails ? votingCentre.operationDetails.sort(function(a,b) {
            return new Date(a.operatingFrom) - new Date(b.operatingFrom)
        }) : [];
        
        const votingCentreFullAddress = `${votingCentre.addressLine1}, ${votingCentre.addressLine2} ${votingCentre.addressLine3}`;

        return (
            <div className={classes.panelContainer}>
                <div className={classes.detailPanelHeader} >                  
                    <div>
                        <Hidden mdUp>                            
                                <Button  className={classes.backToResults}  onClick={closeClickHandler} >
                                    <KeyboardBackspaceIcon /> Back to results 
                                </Button>
                        </Hidden>
                        <h1 id="header-voting-center-details-name">{votingCentre.venueName.replace("EO - EVC","")}</h1>
                        <p>{votingCentre.votingCentreTypeId === VotingCentreType.ISOSVotingCentre ? "" : votingCentre.votingCentreType.replace("IS/OS ","")}</p>
                    </div>
                    <Hidden smDown>
                         <Button id="btn-voting-center-details-panel-close" classes={{ root: classes.iconButton }} onClick={closeClickHandler}><CloseIcon /></Button>
                    </Hidden>
                </div>
                <div className={classes.detailPanelBody}>
                    {votingCentre.votingCentreType === "IS/OS Early Voting Centre" ? 
                        <div className={classes.markdownWrapper}>
                            <Info/><Typography>Open for local business hours only - not open on election day</Typography>
                        </div> :
                        electionsStore.selectedElection && electionsStore.selectedElection.electionTypeId == Enums.ElectionType.General && electionsStore.selectedElection.electionCategoryId == Enums.ElectionCategory.State &&
                        <div className={classes.markdownWrapper}>
                        <Info/><Typography>If you vote outside your enrolled district, it may take longer</Typography>
                        </div>
                    }
                    <div className={classes.detailPanelBodySection}>
                        <p>{votingCentre.hostElectorateType}</p>
                        <p>{votingCentre.hostElectorateType !== 'Council'? `${votingCentre.hostElectorateName} ${votingCentre.hostElectorateType}`: votingCentre.hostElectorateName}</p>
                    </div>
                    <div className={classes.detailPanelBodySection}>
                        <p>Hours</p>
                        {votingCentre.votingCentreTypeId == VotingCentreType.EarlyVotingCentre && votingCentreDetails && (
                            <div className={classes.detailPanelBodySubSection}>
                            <p>Early Voting</p>
                            {operationDetails.length > 0 &&
                                operationDetails.map((operationDetail, index) => (
                                <div key={index}>
                                    <p>
                                    {moment(operationDetail.operatingFrom,"hh:mm").minute() == 0 ? moment(operationDetail.operatingFrom).format("h a") : moment(operationDetail.operatingFrom).format(
                                        "h:mm a"
                                    )}
                                    {" "}-{" "}
                                    {moment(operationDetail.operatingTo,"hh:mm").minute() == 0 ? moment(operationDetail.operatingTo).format("h a") : moment(operationDetail.operatingTo).format(
                                            "h:mm a"
                                    )}
                                    , {operationDetail.daysOperating}{" "}
                                    {moment(operationDetail.operatingFrom).format(
                                        "D MMMM"
                                    ) ===
                                    moment(operationDetail.operatingTo).format("D MMMM")
                                        ? moment(operationDetail.operatingFrom).format(
                                            "D MMMM"
                                        )
                                        : moment(operationDetail.operatingFrom)
                                            .format("D MMMM")
                                            .concat(" - ")
                                            .concat(
                                            moment(operationDetail.operatingTo).format(
                                                "D MMMM"
                                            )
                                            )}{" "}
                                    </p>
                                    {operationDetail.specialCondition && index + 1 !== operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p><br/></> : ""}
                                    {operationDetail.specialCondition && index + 1 === operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p></> : ""}
                                </div>
                                ))}
                            </div>
                        )}
                        {votingCentre.votingCentreTypeId == VotingCentreType.ElectionDayVotingCentre && votingCentreDetails && (
                            <div className={classes.detailPanelBodySubSection}>
                            <p>Election Day</p>
                            {operationDetails.length > 0 &&
                                operationDetails.map((operationDetail, index) => (
                                <div key={index}>
                                    <p>
                                    {moment(operationDetail.operatingFrom,"hh:mm").minute() == 0 ? moment(operationDetail.operatingFrom).format("h a") : moment(operationDetail.operatingFrom).format(
                                        "h:mm a"
                                    )}
                                    {" "}-{" "}
                                    {moment(operationDetail.operatingTo,"hh:mm").minute() == 0 ? moment(operationDetail.operatingTo).format("h a") : moment(operationDetail.operatingTo).format(
                                            "h:mm a"
                                    )}
                                    , {operationDetail.daysOperating}{" "}
                                    {moment(operationDetail.operatingFrom).format(
                                        "D MMMM"
                                    ) ===
                                    moment(operationDetail.operatingTo).format("D MMMM")
                                        ? moment(operationDetail.operatingFrom).format(
                                            "D MMMM"
                                        )
                                        : moment(operationDetail.operatingFrom)
                                            .format("D MMMM")
                                            .concat(" - ")
                                            .concat(
                                            moment(operationDetail.operatingTo).format(
                                                "D MMMM"
                                            )
                                            )}{" "}
                                    </p>
                                    {operationDetail.specialCondition && index + 1 !== operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p><br/></> : ""}
                                    {operationDetail.specialCondition && index + 1 === operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p></> : ""}
                                </div>
                                ))}
                            </div>
                        )}
                        {votingCentre.votingCentreTypeId == VotingCentreType.ElectionOffice && votingCentreDetails && (
                        <div className={classes.detailPanelBodySubSection}>
                        <p>Election Office</p>
                        {operationDetails.length > 0 &&
                            operationDetails.map((operationDetail, index) => (
                            <div key={index}>
                                <p>
                                {moment(operationDetail.operatingFrom,"hh:mm").minute() == 0 ? moment(operationDetail.operatingFrom).format("h a") : moment(operationDetail.operatingFrom).format(
                                        "h:mm a"
                                )}
                                {" "}-{" "}
                                {moment(operationDetail.operatingTo,"hh:mm").minute() == 0 ? moment(operationDetail.operatingTo).format("h a") : moment(operationDetail.operatingTo).format(
                                        "h:mm a"
                                )}
                                , {operationDetail.daysOperating}{" "}
                                {moment(operationDetail.operatingFrom).format(
                                    "D MMMM"
                                ) ===
                                moment(operationDetail.operatingTo).format("D MMMM")
                                    ? moment(operationDetail.operatingFrom).format(
                                        "D MMMM"
                                    )
                                    : moment(operationDetail.operatingFrom)
                                        .format("D MMMM")
                                        .concat(" - ")
                                        .concat(
                                        moment(operationDetail.operatingTo).format(
                                            "D MMMM"
                                        )
                                        )}{" "}
                                </p>
                                {operationDetail.specialCondition && index + 1 !== operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p><br/></> : ""}
                                {operationDetail.specialCondition && index + 1 === operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p></> : ""}
                            </div>
                            ))}
                        </div>
                        )}
                        {votingCentre.votingCentreTypeId == VotingCentreType.ISOSVotingCentre && votingCentreDetails && (
                        <div className={classes.detailPanelBodySubSection}>
                        {operationDetails.length > 0 &&
                            operationDetails.map((operationDetail, index) => (
                            <div key={index}>
                                <p>
                                {moment(operationDetail.operatingFrom,"hh:mm").minute() == 0 ? moment(operationDetail.operatingFrom).format("h a") : moment(operationDetail.operatingFrom).format(
                                        "h:mm a"
                                )}
                                {" "}-{" "}
                                {moment(operationDetail.operatingTo,"hh:mm").minute() == 0 ? moment(operationDetail.operatingTo).format("h a") : moment(operationDetail.operatingTo).format(
                                        "h:mm a"
                                )}
                                , {operationDetail.daysOperating}{" "}
                                {moment(operationDetail.operatingFrom).format(
                                    "D MMMM"
                                ) ===
                                moment(operationDetail.operatingTo).format("D MMMM")
                                    ? moment(operationDetail.operatingFrom).format(
                                        "D MMMM"
                                    )
                                    : moment(operationDetail.operatingFrom)
                                        .format("D MMMM")
                                        .concat(" - ")
                                        .concat(
                                        moment(operationDetail.operatingTo).format(
                                            "D MMMM"
                                        )
                                        )}{" "}
                                </p>
                                {operationDetail.specialCondition && index + 1 !== operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p><br/></> : ""}
                                {operationDetail.specialCondition && index + 1 === operationDetails.length ? <><p dangerouslySetInnerHTML={{__html: operationDetail.specialCondition.replaceAll("\n","<br/>")}}></p></> : ""}
                            </div>
                            ))}
                        </div>
                        )}
                    </div>
                    <div className={classes.detailPanelBodySection}>
                        <p>{votingCentre.name ? votingCentre.name.replace("EO - EVC","") : votingCentre.name}</p>
                        <p>{votingCentreFullAddress}</p>
                    </div>
                    <div className={classes.detailPanelBodySection}>
                        <p>Wheelchair Access</p>
                        <p>{this.getWheelChairAccessType(votingCentre.wheelChairAccessTypeCode)}</p>
                        {
                            accessibilityDetails.length > 0 &&
                            <ul>{accessibilityDetails.map((accessibilityDetail, index) => <li key={index}>{accessibilityDetail.description}</li>)} </ul>
                        }
                    </div>
                    {
                        votingCentreTypeIds.indexOf(VotingCentreType.EarlyVotingCentre) >= 0 && votingCentreDetails.earlyVotingDetails &&
                        <div className={classes.detailPanelBodySection}>
                            <p>Accessibility</p>
                            <p>Hearing assistance is available at all Early Voting Centres</p>
                        </div>
                    }
                </div>
                {this.rendreGetDirectionButton()}
            </div>
        )
    }

    rendreGetDirectionButton() {
        const { classes } = this.props;

        return (
            <div className={classes.getDirectionBtnWrapper}>
                <Button
                    id="btn-voting-center-details-google-redirect" variant="contained" color="primary"
                    className={[classes.button, classes.getDirectionBtn].join(' ')}
                    onClick={this.setGooglMapDirectionURLRedirect} >
                    {DirectionIcon(svgPropsConfig)}
                    Get directions
                </Button>
            </div>
        )
    }

    setGooglMapDirectionURLRedirect = () => {
        const { searchStore, votingCentresStore } = this.props,
            { selectedVotingCentre } = votingCentresStore,
            selectedAddress = searchStore.selectedAddress,
            selectedAddressLocation = selectedAddress && selectedAddress.geometry && selectedAddress.geometry.location;           

        const selectedAddressLangLat = `${selectedAddressLocation.lat()},${selectedAddressLocation.lng()}`,
            selectedVotingCenterLangLat = `${selectedVotingCentre.latitude},${selectedVotingCentre.longitude}`;
        const redirectGoogleMapFullURL = `https://maps.google.com?saddr=${selectedAddressLangLat}&daddr=${selectedVotingCenterLangLat}`;

        window.open(redirectGoogleMapFullURL, '_blank');
    }
}

VotingCentreDetailsPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    closeClickHandler: PropTypes.func.isRequired,
    votingCentresStore: PropTypes.object,
    appMainStore: PropTypes.object,
    searchStore: PropTypes.object,
    electionsStore: PropTypes.object,
};

export default withStyles(styles)(VotingCentreDetailsPanel);