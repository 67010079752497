import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const electionsTheme = theme =>{
    return createMuiTheme({
        ...theme,
        overrides: {        
            MuiTypography: {            
                h1: {
                    fontSize: '46px',
                    fontWeight: 'bold',
                    lineHeight: '1.13',
                    [theme.breakpoints.down('sm')]: {               
                        fontSize: '44px',
                        lineHeight: '1.14'
                    },
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '42px',
                    },    
                },
                h2: {
                    fontSize: '38px',
                    fontWeight: 'bold',
                    lineHeight: '1.21',
                    fontStretch: 'normal',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '34px',
                        lineHeight: '1.35',
                    },
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '28px',
                        lineHeight: '1.29',
                    },    
                },
                h3: {
                    fontSize: '29px',
                    fontWeight: 'bold',
                    lineHeight: '1.29',
                    fontStretch: 'normal',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '26px',
                        lineHeight: '1.31',
                    },
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '22px',
                        lineHeight: '1.31',
                    },    
                },
                h4: {
                    fontSize: '24px',
                    fontWeight: 'bold',
                    lineHeight: '1.33',
                    fontStretch: 'normal',
                    [theme.breakpoints.down('sm')]: {
                        fontSize: '22px',
                        lineHeight: '1.36',
                    },
                    [theme.breakpoints.down('xs')]: {
                        fontSize: '22px',
                        lineHeight: '1.27',
                    },    
                },
                h5: {
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '1.63',
                    fontStretch: 'normal',                      
                },
                h6: {
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: '1.14',
                    fontStretch: 'normal',
                    letterSpacing: '3.5px',
                },                      
                subtitle1: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    lineHeight: '1.57',
                    fontStretch: 'normal',
                    letterSpacing: '0.4px',
                },
                subtitle2: {
                    fontSize: '12px',
                    fontWeight: 'normal',
                    lineHeight: '1.67',
                    fontStretch: 'normal',
                    letterSpacing: '0.4px',
                },
                body1: {
                    fontSize: '18px',
                    fontWeight: 'normal',
                    lineHeight: '1.89',
                    fontStretch: 'normal',
                    letterSpacing: '0.4px',
                    [theme.breakpoints.down('xs')]: {                                      
                        lineHeight: '1.56',
                    },    
                },
                body2: {
                    fontSize: '16px',
                    fontWeight: 'normal',
                    lineHeight: '1.75',
                    fontStretch: 'normal',
                    letterSpacing: '0.4px',
                    [theme.breakpoints.down('xs')]: {                                      
                        lineHeight: '1.63',
                        fontSize: '14px'
                    },
                },
            },
            MuiLink:{
                root: {
                    cursor: 'pointer !important',
                    fontWeight: 600,
                }
            },
        }, 
    });     
}

@observer
class ElectionsTheme extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme=> electionsTheme(theme) }>
                {this.props.children}
            </MuiThemeProvider>
        );
    }

    static propTypes = {
        parentTheme: PropTypes.object,
        children: PropTypes.object.isRequired,
    }
}

export default ElectionsTheme;