import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import * as Enums from '../../enums';
import VecLogo from '../../../static/images/vec-logo.svg';
import withWidth, {isWidthDown} from '@material-ui/core/withWidth';

const styles = theme => {
    const { breakpoints, spacing } = theme;
    return ({
        titleContainer: {           
            lineHeight: '64px',
            width: '100%',
            textAlign: 'center',
            [theme.breakpoints.down('xs')]: {
                lineHeight: '56px',
            }
        },
        vecLogo: {
            width: '31px',
            height: '31px',
            objectFit: 'contain',
            transform: 'translateY(26%)'
        },
        appBarTitle: {
            fontSize: '22px',
            fontWeight: 'bold',
            display: 'inline-block',
            marginLeft: `${spacing(2)}px`,
            [breakpoints.down('sm')]: {
                fontSize: '16px',               
                marginLeft: spacing(1),
            }
        },
        clickableHeader: {
            cursor: 'pointer'
        }
    });
}

@inject('electionsStore', 'appMainStore' )
@observer
class HeaderTitle extends React.Component {

    render() {
        const { classes, customeStyle, electionsStore, appMainStore,width, headerTitleText } = this.props;
        const { selectedElection} = electionsStore;
        const clickableHeader = !electionsStore.selectedElection && appMainStore.applicationMode != Enums.ApplicationMode.Browse;
        const isMobileView = isWidthDown('xs', width);   
        return (
            <div className={classes.titleContainer} style={{ paddingLeft: customeStyle.paddingLeft, textAlign: customeStyle.textAlign, lineHeight: customeStyle.lineHeight }}>                
                    <img className={classes.vecLogo} src={VecLogo} alt="VEC logo" />
                    <span className={clickableHeader ? classes.clickableHeader : null} onClick={this.titleClickHandler}>
                        <Typography className={classes.appBarTitle} id="mapheader-title">
                        {isMobileView ? "": selectedElection ? electionsStore.formattedElectionName : headerTitleText}
                        </Typography>
                    </span>
            </div>
        );
    }

    titleClickHandler = () => {
       if(!this.props.electionsStore.selectedElection && this.props.appMainStore.applicationMode != Enums.ApplicationMode.Browse)  {
        window.location.reload();
       }
    }
}

HeaderTitle.propTypes = {
    classes: PropTypes.object.isRequired,
    customeStyle: PropTypes.object,
    electionsStore: PropTypes.object,
    appMainStore: PropTypes.object,
    width: PropTypes.string.isRequired,
    headerTitleText: PropTypes.string
};

export default withStyles(styles)(withWidth()(HeaderTitle));