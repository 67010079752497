import { observable, action, computed, runInAction } from 'mobx';
import mapContentService from '../services/map-content.service';
import alertService from '../services/alerts.service';
import ReactDOM from 'react-dom';
import * as Enums from '../enums';
import {Tools} from '../tools';
import staticGeometryService from '../services/static-geometry.service';

export default class AppMainStore {
    bindStores({  landingDialogCommonStore , landingDialogStore, votingCentresStore, electoratesStore, electionsStore}) {
        this.landingDialogCommonStore = landingDialogCommonStore;
        this.landingDialogStore = landingDialogStore;        
        this.votingCentresStore = votingCentresStore;
        this.electoratesStore = electoratesStore;       
        this.electionsStore = electionsStore;
    }

    @observable activeBusyFuncs = 0;
    @observable leftDrawerOpen = false;
    @observable rightDrawerOpen = false;
    @observable applicationMode = Enums.ApplicationMode.Browse;
    @observable mobileSearchListOpen = false;
    @observable activeMapConfigurationValues = [];
    @observable alerts = [];
    @observable alertMessageShown = true;
    @observable isBrowserLocationInVictoria = undefined;
    @observable isLocationInVictoria = undefined;
    @observable hasDeterminedLocation = false;
    @observable alertTitleContent = '';
    @observable alertMessageContent = '';
    @observable listContainerMAXHeight = '';
    @observable showGenericHeader = true;
    @observable initialAddressText = '';
    @observable isOld = null;
    @observable loadFail = null;
    
    @computed get isBusy() {return this.activeBusyFuncs != 0}

    searchResultComponentRefs = {};
    searchResultsComponentRef = null;

    @action setShowGenericHeader(value) {
        this.showGenericHeader = value;
    }

    @action 
    setBusy(busy) {
        if (busy) {
            this.activeBusyFuncs ++;
        } else {
            this.activeBusyFuncs --;
        }
    }

    @action
    setMobileSearchListOpen(open) {
        this.mobileSearchListOpen = open;
    }
    
    @action
    setLeftDrawerOpen(leftDrawerOpen){
        this.leftDrawerOpen = leftDrawerOpen;
    }

    @action
    setRightDrawerOpen(rightDrawerOpen){
        this.rightDrawerOpen = rightDrawerOpen;
    }

    @action
    setApplicationMode(applicationMode){
        this.applicationMode = applicationMode;
        this.getAllActiveMapConfigurationValues();
        this.getAllModuleAlerts();
    }
    
    @action getAllActiveMapConfigurationValues = () => {
        mapContentService.getAllActiveMapConfigurationValues(this.applicationMode).then((response) => {
            runInAction(() => {
                this.activeMapConfigurationValues.replace(response);
            });
        }).catch(() => {
            runInAction(() => {
                this.activeMapConfigurationValues.clear();
            });
        });
    }

    @action getAllModuleAlerts = () => {
        return alertService.getAllModuleAlerts(this.applicationMode).then((response) => {
            this.alerts.replace(response);
        }).catch(() => {
            this.alerts.clear();
        });
    }

    bindHistory(history) {
        this.history = history;
        const addressQueryString = history.location.search.match(/address=([^&/]*)/);
        if (addressQueryString) {
            this.initialAddressText = decodeURI(addressQueryString[1]);
        }
    }

    getMapConfigs = (name) => {
        if (this.activeMapConfigurationValues.length > 0) {
            return this.activeMapConfigurationValues.filter(k => k.contentName === name);
        } else
            return [];
    }

    getMapConfigContent = function(name) {
        let configValue = this.getMapConfigs(name);
        if (configValue.length) {
            return configValue[0].contentText;
        } else {
            return '';
        }
    }
    @action calCulateMaxHeightForListContainer = () => {
        var windowHeight = window.innerHeight;
        var p = 512;
        if (Tools.breakpoints.down('sm'))
            p = 320;

        if (windowHeight - p < 30)
            this.listContainerMAXHeight = 0;
        else    
            this.listContainerMAXHeight = windowHeight - p;
    }

    scrollToVotingCentreSearchResult(id) {
        const searchResultComponentRef = this.searchResultComponentRefs[id];

        if (searchResultComponentRef) {
            const searchResultNode = ReactDOM.findDOMNode(searchResultComponentRef)

            if (searchResultNode) {
                searchResultNode.scrollIntoView(true);
            }
        }
    }

    resetVotingCentreSearchResultScroll() {
        this.closeRightDrawer();
        if (this.searchResultsComponentRef) {
            const searchResulstNode = ReactDOM.findDOMNode(this.searchResultsComponentRef)

            if (searchResulstNode && searchResulstNode.children && searchResulstNode.children.votingSearchResultList) {
                searchResulstNode.children.votingSearchResultList.scrollTop = 0;
            }
        }
    }

    @computed get isRightDrawerOpen() {
        let result = false; 
        const isAboveSM = Tools.breakpoints.up('sm');
        if (isAboveSM && this.rightDrawerOpen
            && (!!this.votingCentresStore.selectedVotingCentreId || !!this.electoratesStore.electoratesSummary)) {
            result = true;
        }

        return result;        
    }

    closeVotingCentreDrawer() {
        this.votingCentresStore.toggleShowVotingCenterDetails();
        //this.votingCentresStore.bottomVotingCentreSearchResultSelected = false;
    }

    @action.bound closeRightDrawer() {
        this.votingCentresStore.setSelectedVotingCentre(null);        
        this.rightDrawerOpen = false;        
    }

    @action.bound openRightDrawer() {
        this.rightDrawerOpen = true;
        this.toggleShowAlerts(false);
    }

    openLandingDialog() {
        this.landingDialogCommonStore.setLandingDialogOpen(true);
    }

    determineUserLocation(){
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(this.userLocationCallback, this.userLocationCallBackError);
        }
    }
    
    @action userLocationCallBackError = () => {
        this.isBrowserLocationInVictoria = true;
        this.hasDeterminedLocation = true;
    }

    @action userLocationCallback = (result) => {
        let coords = result.coords;

        staticGeometryService.isUserLocationInVictoria(coords.latitude, coords.longitude)
        .then((response) => {
            runInAction(() => {
                this.isBrowserLocationInVictoria = response;
                this.hasDeterminedLocation = true;
            });
        }).catch(() => {
            this.isBrowserLocationInVictoria = true;
            this.hasDeterminedLocation = true;
        });
    }

    @action.bound toggleShowAlerts(value) {
        this.alertMessageShown = typeof(value) == 'boolean'? value : !this.alertMessageShown;
    }

    @computed get IsAlertMessageAvailable() {
        return this.RelativeAlerts.length > 0 || this.electionsStore.electionsForAlert.length > 0;
    }

    @computed get CanShowAlert(){
        return (this.alertMessageShown && this.showGenericHeader) ;
    }

    @computed get RelativeAlerts(){
        var addressAlertType = this.hasDeterminedLocation ? this.isBrowserLocationInVictoria ? Enums.AlertType.WithinVictoria : Enums.AlertType.OutsideVictoria : 0;
        return this.alerts.filter(x => x.alertType === Enums.AlertType.Generic || x.alertType === addressAlertType);
    }

    @computed get IsLandingDialogOpen() {
         return this.landingDialogCommonStore.landingDialogOpen;  
    }

    @action.bound checkSupportedBrowser() {
        if (Tools.isUnsupportedBrowser()) {
            this.isOld = true
        } else {
            try {
                let tmpMapDiv = window.document.createElement('div');            
                // eslint-disable-next-line
                let mapInstance = new google.maps.Map(tmpMapDiv);
                this.isOld = false;
                mapInstance = null;                
            } catch (e) {
                this.loadFail = true;
                this.isOld = (e.message == 'The Google Maps JavaScript API does not support this browser.') ;
            }
        }
    }

    flagLoadFailed() {
        runInAction(() => {
            this.loadFail = true;
        });
    }
    flagLoadSucceeded() {
        runInAction(() => {            
        this.loadFail = false;        
        });
    }

}