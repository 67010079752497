export const ApplicationMode = {
    Browse: 0,
    State: 1,    
    StateBy: 2,
    Council: 3,
    CouncilBy: 4    
};

export const VotingCentreType = {
    ElectionDayVotingCentre: 1,
    EarlyVotingCentre: 2,
    ElectionOffice: 4,
    ISOSVotingCentre: 9,
};

export const KeyPressCodes = {
    Enter: 13,
    Down: 40,
    Up: 38,
};

export const ElectionCategory = {
    State: 1,
    Municipal: 2,
};

export const ElectionType = {
    General: 1,
    ByElection: 2
};

export const ElectorateType = {
    District : 1,
    Region : 2,
    Council : 3,
    Ward : 4
}

export const AlertType = {
    OutsideVictoria : 1,
    WithinVictoria : 2,
    Generic : 3
}

export const AdditionalLayerType = {
    GISDB: 1,
    KML: 2
}

export const AccessibilityType = {
    LNWA: 0,    
    AWA: 1,
    IWA: 2,
    NOTSET: 4,
    ALL: -1
};