import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { AppBar, Toolbar, IconButton, Grid} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import HeaderTitle from './header-title'
import StartOver from './start-over'
import NotificationIcon from '../../../static/images/notification.svg';
import NotificationPendingIcon from '../../../static/images/notification-pending.svg';

const styles = theme => {
    const { zIndex } = theme;
    return ({
        appBar: {
            position: 'absolute',
            zIndex: zIndex.drawer + 1
        },
        menuButton: {
            display: 'inline-block',
        },
        appToolbar: {
            paddingLeft: '0px',
            paddingRight: '0px'
        },
        rightNavContainer: {            
            textAlign: 'right', 
            paddingRight: '25px',
            [theme.breakpoints.down('md')]: {
                paddingRight: '0px',
            }
        }
    });
}

@inject('appMainStore', 'electionsStore')
@observer
class Header extends React.Component {    
    render() {
        const { classes, menuClickHandler, appMainStore, electionsStore } = this.props;
        const {IsAlertMessageAvailable, alertMessageShown, toggleShowAlerts} = this.props.appMainStore;
        const styling = {
            paddingLeft:'0rem'
        }
        return (
            <React.Fragment>
            {
            (appMainStore.showGenericHeader) &&
            <AppBar className={classes.appBar}>
                <Toolbar className={classes.appToolbar}>
                    <Grid container alignItems='center'>
                        <Grid item sm={2} xs={4}>
                            <IconButton
                                className={classes.menuButton}
                                color="inherit"
                                aria-label="Menu"
                                id="btn-header-menu"
                                onClick={menuClickHandler}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item sm={8} xs={4}>
                                    <HeaderTitle customeStyle={styling} headerTitleText='Victorian Electoral Map' />
                        </Grid>
                        <Grid item sm={2} xs={4} className={classes.rightNavContainer}>
                            {(IsAlertMessageAvailable || electionsStore.electionsForAlert.length > 0) && <IconButton id="btn-alert-toggle" aria-label={'Toggle notifications'} onClick={toggleShowAlerts} className={classes.menuButton}><img alt='bell image' src={alertMessageShown? NotificationIcon: NotificationPendingIcon} /></IconButton>}
                            <StartOver />                    
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        }
        </React.Fragment>           
        );
    }    
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    menuClickHandler: PropTypes.func.isRequired,
    appMainStore: PropTypes.object,
    electionsStore: PropTypes.object
};

export default withStyles(styles)(Header);