import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { observable, action } from 'mobx';
import { withStyles} from '@material-ui/core';
import withWidth from '@material-ui/core/withWidth';
import {ExpandMore} from '@material-ui/icons';
import { Typography, Divider, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails} from '@material-ui/core';
import SelectionList from './selection-list';
import showMapKey from '../../../static/images/show-map-key.svg'
import {ElectorateType} from '../../enums';
import {Tools} from '../../tools';

const styles = (theme) => ({

    container : {
        backgroundColor: theme.palette.primary.contrastText ,
        margin: '0px !important',
        boxShadow: 'none',
    },
      moduleToolbar: {
        minHeight: 'initial'
    },

    heading : {
        color: theme.palette.navy.main,
        paddingTop: '16px',
        paddingBottom: '18px',
        width:  'calc(100% - 32px)',
        height: '24px',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: theme.palette.primary.contrastText,
        fontWeight: '600',
        [theme.breakpoints.down('xs')]: {
            ...theme.overrides.MuiTypography.h6
        }
    },
    expansionSummary: {
        padding: '0px 16px !important',
    },
    expansionSummaryTitle: {
        paddingTop: '0px',
        paddingBottom: '0px',        
    },
    expansionSummaryIcon: {
        marginRight: '8px',
    },
    expansionDetails: {
        display: 'block',
        padding: '0px 16px',
    },
  });

@inject('electoratesStore','appMainStore', 'browseStore')
@observer
class BrowseSelection extends React.Component {

    constructor (props) {
        super(props);
        this.inputLabel = React.createRef(null);
        this.stateInfoRef = React.createRef();
        this.councilInfoRef = React.createRef();

        this.state = {labelwidth: 0,
            expandedInMobile: false,
            showStateInfo: false,
            showCouncilInfo: false
        };
    }

    @observable expanded = true;
    
    @action toggleExpanded() {
        if (Tools.breakpoints.up('md')) {
            this.expanded = !this.expanded;
        }
    }
    componentDidMount = () =>{
        if (this.props.electoratesStore.regions.length == 0 || 
            this.props.electoratesStore.districts.length == 0 ||
            this.props.electoratesStore.councils.length == 0 ||                
            this.props.electoratesStore.wards.length == 0)
        {
            this.props.electoratesStore.getRegions();
            this.props.electoratesStore.getDistricts();
            this.props.electoratesStore.getCouncils();
            this.props.electoratesStore.getWards();
        }

    }

    handleRegionChange = selection => {
        this.props.browseStore.setSelectedRegion(selection, true);
    };
    handleDistrictChange = selection => {
        this.props.browseStore.setSelectedDistrict(selection, true);
    };
    handleCouncilChange = selection => {
        this.props.browseStore.setSelectedCouncil(selection, true);
    };
    handleWardChange = selection => {
        this.props.browseStore.setSelectedWard(selection, true);
    };
    toggleExpandedInMobile = () => {
        this.setState({expandedInMobile: !this.state.expandedInMobile});
    }
    toggleShowStateInfo = () => {
        this.setState({showStateInfo: !this.state.showStateInfo});
    }
    toggleShowCouncilInfo = () => {
        this.setState({showCouncilInfo: !this.state.showCouncilInfo});
    }

    selectionMouseOver(layer){
        this.props.electoratesStore.setLayerHighLight(layer, true)
    }
    selectionMouseLeave(layer){
        this.props.electoratesStore.setLayerHighLight(layer, false)
    }

    setTopMost(layer, value){
        if (Tools.breakpoints.down('sm')) value = false; //This is disabled for tablet and mobile beause it behaves oddly without a mouse
        this.props.electoratesStore.setTopMost(layer, value);
    }
    
    render() {
        const {classes }  = this.props;
        const {councils, wards, districts, regions, selectedDistrict, selectedRegion, selectedCouncil, selectedWard} = this.props.electoratesStore
        const councilWards = wards.filter(ward => {return selectedCouncil && ward.parentElectorateId == selectedCouncil.electorateId});
        const regionDistricts = districts.filter(district => {return selectedRegion && district.parentElectorateId == selectedRegion.electorateId});
        const desktopView = Tools.breakpoints.up('md');
        return (            
            <ExpansionPanel className={classes.container} square={true} expanded={this.expanded || !desktopView} onChange={() => this.toggleExpanded()}> 
                <ExpansionPanelSummary
                    className={classes.expansionSummary}
                    expandIcon={desktopView? <ExpandMore /> : null}
                    id="electorate-selector"
                    >
                    {!this.expanded && <img alt='layers icon' className={classes.expansionSummaryIcon} src={showMapKey}/>}
                    <Typography variant = 'h5' className={classes.heading + ' ' + classes.expansionSummaryTitle}>{this.expanded? 'State Parliament' : 'Electoral Areas'} </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.expansionDetails}>
                    <SelectionList heading={'Upper House Region'} name="region" id="region"
                        onChange={this.handleRegionChange} colour='#7d34f1' listItems={regions}
                        title='State Parliament Regions of Victoria' selectedItem={selectedRegion} enabled={regions.length > 0}
                        selectedElectorateType = {ElectorateType.Region}
                        onMouseOver={() => {this.selectionMouseOver(ElectorateType.Region)}}
                        onMouseLeave={() =>{this.selectionMouseLeave(ElectorateType.Region)}}
                        onShowInfoChange={(value) =>{this.setTopMost(ElectorateType.Region, value)}}
                    />
                    <SelectionList heading={'Lower House District'} name="district" id="district"
                        onChange={this.handleDistrictChange} colour="#f36d01" listItems={selectedRegion ? regionDistricts : districts}
                        title='State Parliament Districts of Victoria' selectedItem={selectedDistrict} enabled={districts.length > 0}
                        selectedElectorateType = {ElectorateType.District}
                        onMouseOver={()=>{this.selectionMouseOver(ElectorateType.District)}}
                        onMouseLeave={()=>{this.selectionMouseLeave(ElectorateType.District)}}
                        onShowInfoChange={(value) =>{this.setTopMost(ElectorateType.District, value)}}
                    />
                    <Divider />
                    <Typography variant="h5" className={classes.heading}>
                        Local Council
                    </Typography>

                    <SelectionList heading={'Council'} name="council" id="council"
                        onChange={this.handleCouncilChange} colour="#0899fe" listItems={councils}
                        title='Local Councils of Victoria' selectedItem={selectedCouncil} enabled={councils.length > 0}
                        selectedElectorateType = {ElectorateType.Council}
                        onMouseOver={() =>{this.selectionMouseOver(ElectorateType.Council)}}
                        onMouseLeave={() =>{this.selectionMouseLeave(ElectorateType.Council)}}
                        onShowInfoChange={(value) =>{this.setTopMost(ElectorateType.Council, value)}}
                    />
                    <SelectionList heading={'Council Ward'} name="ward" id="ward"
                        onChange={this.handleWardChange} colour="#56a804" listItems={councilWards}
                        title={selectedCouncil ? selectedCouncil.electorateName + ' Wards' : undefined} selectedItem={selectedWard}
                        selectedElectorateType = {ElectorateType.Ward}
                        enabled={councilWards.length > 1} placeHolderText={councilWards.length === 1 ? councilWards[0].electorateName : undefined}
                        onMouseOver={() =>{this.selectionMouseOver(ElectorateType.Ward)}}
                        onMouseLeave={() =>{this.selectionMouseLeave(ElectorateType.Ward)}}
                        onShowInfoChange={(value) =>{this.setTopMost(ElectorateType.Ward, value)}}
                    />
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

BrowseSelection.propTypes = {
    appMainStore: PropTypes.object,
    electoratesStore: PropTypes.object,
    browseStore: PropTypes.object,
    width: PropTypes.string,
    classes: PropTypes.object
}

export default  withStyles(styles)(withWidth()(BrowseSelection))
