export const styles = (theme) => {
    const { typography, palette, breakpoints, spacing } = theme;
    const unit7x = `${spacing(7)}px`;
    const unit3x = `${spacing(3)}px`;
    const unit2x = `${spacing(2)}px`;
    const unit1x = `${spacing(1)}px`;
    return ({
        panelHeaderWrapper:{
            display: 'flex',
            flexDirection: 'row',
            height: '192px',
            minHeight: '192px',
            background: palette.secondary.webpink,            
            [breakpoints.down('sm')]: {
                height: '160px',
                minHeight: '160px',
                }
        },
        drawerItems: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            color: 'white',
            height: '100vh !important',
            overflowY: 'auto',
            [breakpoints.up('sm')]: {
                height: '100%',
                maxHeight: '100vh',
            }
        },
        drawerPaper: {
            width: '400px',
            fontFamily: typography.fontFamily,
            backgroundColor: 'black',
            [breakpoints.down('sm')]: {
                width: '100%',
                height: 'auto !important'
            },
        },
        navItems: {
            ['& ul']: {
                listStyleType: 'none',
                paddingLeft: 0,
            },
        },
        drawerNavItems: {
            backgroundColor: palette.secondary.main,
            color: palette.primary.contrastText,
            fontWeight: '700',
            minHeight: '228px',
            flex: 1,
            overflowY: 'hidden',
            [breakpoints.down('md')]: {
                maxHeight: '50vh'
            },
            [breakpoints.down('lg')]: {
               maxHeight: 'calc(50vh - 30px)'
            },
            
            ['& ul']: {
                listStyleType: 'none',
                paddingLeft: 0,
                margin: 0,
                ['& li']:{
                    height: '75px',
                    borderBottom: '1px solid ' + palette.secondary.dark
                },
            },
        },
        drawerFooter: {
            backgroundColor: palette.secondary.contrastText,
            color: palette.secondary.main,
            ...theme.overrides.MuiTypography.h5,
            padding: '22px 0rem 22px 22px',
        },
        drawerLogo: {
            backgroundColor: palette.secondary.webpink,
            padding: `${unit7x} 0`,
            textAlign: 'center',
            width: '100%',
            ['& img']: {
                width: '50.9px',
                height: '46.3px',
            },
            ['& p']:{
                maxWidth: '124px',
                margin: 'auto'
            }
        },
        drawerCloseButton: {
            border: '0',
            display: 'inline-flex',
            background: 'transparent',
            padding: '20px 15px 0 0',
            alignSelf: 'flex-start',
            cursor: 'pointer',
            ['& img']: {
                width: '16px',
                height: '16px',
                cursor: 'pointer'
            },
        },
        navItem: {
            ...theme.overrides.MuiTypography.h5,
            display: 'flex',
            alignItems: 'center',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            cursor: 'pointer',
            transition: 'background-color 0.1s ease-in',
            padding: '0px 24px',
            ['&:hover']: {
                backgroundColor: palette.secondary.dark,
            }
        },
        footerNavItem: {
            color: palette.secondary.main,
            fontWeight: 'normal',
            padding: '0px',
            transition: 'opacity 0.1s ease-in',
            ['&:hover']: {
                backgroundColor: 'transparent',
                opacity: 0.9,
                textDecoration: 'underline',
            }
        },
        footerNavItems: {
            display: 'inline-block',
            paddingBottom: unit3x,
            paddingTop: unit1x,
            ['& ul']: {
                margin: 0,
            },
        },
        footerIcons: {
            paddingBottom: unit3x,
            ['& img']: {
                width: '40px',
                height: '40px',
                marginRight: unit1x,
                cursor: 'pointer',
            },
        },
        footerSocialIcons: {
            paddingBottom: 0,
            ['& img']: {
                maxHeight: '20px',
                maxWidth: '28px',
                marginRight: unit2x,
                cursor: 'pointer',
            },
            [breakpoints.down('sm')]: {
                marginBottom: '45px',
            },
            [breakpoints.down('xs')]: {
                marginBottom: '100px',
            },
        },
        extraText: {
            ...theme.overrides.MuiTypography.body2,
            maxWidth: '312px',
        }
    });
};