import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { computed, action, observable } from 'mobx';
import { withStyles } from '@material-ui/core/styles';
import withWidth, {isWidthDown, isWidthUp} from '@material-ui/core/withWidth';
import { Select, Typography, MenuItem, FormControl, Drawer, Divider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancelIcon from '@material-ui/icons/Cancel';
import InfoContainer from '../browse/info-container';
import {Tools} from '../../tools';


const styles = theme => ({

    selectMain : { 
        ...theme.overrides.MuiTypography.body2,
        borderRadius: '4px',
        border: 'solid 2px #37444E',
        backgroundColor: theme.palette.primary.contrastText ,
        paddingLeft: '8px', 
        marginRight: '0px',
        color: theme.palette.navy.dark,
        fontWeight: 'bold',
        borderColor: 'rgba(55, 68, 78, 0.4)'         
     },     
    title : {        
        color: theme.palette.gray.gray600,     
        minWidth: '160px',
        [theme.breakpoints.down('xs')]: {
            ...theme.overrides.MuiTypography.body2          
        }  
               
    },
    chevron : {
        color : theme.palette.navy.main + ' !important'
    }, 
    disabledChevron:{
        color: 'rgba(0, 0, 0, 0.38)'
    },
    selectContainer : {  
        backgroundColor: theme.palette.primary.contrastText,
        paddingBottom: '24px',
    },
    listContainer:{
        borderStyle: 'solid',
        borderWidth: '2px',
        borderColor: 'inherit',
        ['& div']: {
            overflowX: 'auto',
            pointerEvents: 'initial',
        },
        ['& li']:{
            background: 'inherit !important',
            display: 'block',
            pointerEvents: 'none',
        }
    },
    iconClass : {
       cursor: 'pointer'
    },    
    windowTitle: {
        color: theme.palette.gray.gray600,
    },
    itemList: {
        display: 'inline-grid',
        gridAutoFlow: 'column',
        listStyle: 'none',
        paddingInlineStart: '0px',
        cursor: 'default',
        padding: '0px',
        ['& li']: {
            width: 'fit-content',
            padding: '3px 32px 3px 0px',
            whiteSpace: 'nowrap',
            display: 'block',
            pointerEvents: 'none',
            color: theme.palette.gray.gray600,
            ['& hr']: {
                width: '60px',
                marginTop: '-0.5em',
                borderStyle: 'solid',
                borderRadius: '4px',
                borderWidth: '1px',
                marginLeft: '20px',        
            },
            ['&.item']: {
                pointerEvents: 'auto',
                overflowX: 'visible',
                cursor: 'pointer',
            },
            ['&.item:hover']: {
                backgroundColor: theme.palette.secondary.main + ' !important',
                color: theme.palette.secondary.contrastText,
                cursor: 'pointer',
            }
        },
    },
    formControl: {
        width: '100%'       
    },
    info: {
        float: 'left'
    },
    cancel: {
        float : 'right'
    },
    iconDiv: {
        width : '77px'
    },
    deviceDrawer : {
         marginTop: '200px',
        overflow: 'visible',       
        border: 'none',
        [theme.breakpoints.down('sm')]: { 
            width: '400px',
            marginTop: '280px'
        },
        [theme.breakpoints.down('xs')]: {
            width: 'fit-content',
            marginTop: '188px'
        }         
   },
   desktopDrawer : {
       marginTop: '65px',
       width: '400px',
       borderStyle: 'solid',
       borderWidth: '2px',
       borderColor: 'black'
   },
   iconDivider : {
       border: '1px #dddddd',
       height: '24px',
       marginLeft: '3px',      
   } 
  });
 

@inject('browseStore', 'appMainStore')
@observer
class SelectionList extends React.Component {
    
    constructor(props){
        super(props);
        this.inputRef = React.createRef();
        this.selectInfoRef = React.createRef();     
    }   

    @observable showList = false;
    @observable hovered = false;
    @observable focused = false;
    @computed get sortedItems() {
        const sortedList = this.props.listItems.slice().sort((el1, el2) => {
            if (el1.electorateName > el2.electorateName) return 1;
            if (el1.electorateName < el2.electorateName) return -1;            
            return 0;
        }).map(item => {
            let name = item.electorateName;
            if (name.substring(name.length - item.electorateType.length) != item.electorateType) {
                name += ' ' + item.electorateType;
            }
            return {...item, electorateName: name}
        });
        return sortedList;
    }
    @computed get selectedItem() {
        let item = this.props.selectedItem;
        if (item) {
            let name = item.electorateName;
            if (name.substring(name.length - item.electorateType.length) != item.electorateType) {
                name += ' ' + item.electorateType;
            }
            return {...item, electorateName: name}
        } else {
            return item;
        }
    }
    @computed get showSelectInfo() {
        return this.props.browseStore.infoDrawerOpen == this.props.selectedElectorateType;
    }

    @computed get hasSelection () {
        return !!this.props.selectedItem && !!this.props.selectedItem.electorateId;
    }

    componentWillUnmount() {
        const {onShowInfoChange, onMouseLeave} = this.props
        if (onShowInfoChange) {
            onShowInfoChange(false);
        }
        if (onMouseLeave) {
            onMouseLeave();
        }
    }

    @action customInputGotFocus() {
        this.showList = true;
    }

    @action hideCustomSelectList() {
        this.showList = false;
    }
    
    @action itemSelected() {
        this.showList = false;
        this.props.onChange();
    }

    handleSelectionChange = (itemId) => {
        if (this.props.onChange) {
            const item = this.props.listItems.find(item => item.electorateId == itemId)
            this.props.onChange(item);           
        }
    }

    handleCancelClick = () => { 
        this.toggelDrawerOpen(-1);
        this.props.onChange({});
    }

    handleInfoClick = (e) => {       
        e.stopPropagation();
        e.preventDefault();
        this.toggelDrawerOpen(this.props.selectedElectorateType)      
    }

    handleInfoClose =() => {
        this.toggelDrawerOpen(-1);
    }


    @action toggelDrawerOpen = (value) => {
        if (this.showSelectInfo) {
            this.props.browseStore.setInfoDrawerOpen(-1);
        } else {
            this.props.browseStore.setInfoDrawerOpen(value);
        }
        this.props.appMainStore.toggleShowAlerts(false);
        this.onMouseLeave();
        const {onShowInfoChange} = this.props; 
        if (onShowInfoChange) {
            onShowInfoChange(this.showSelectInfo);
        } 

    }

    @action.bound onMouseOver() {
        if (Tools.breakpoints.up('md')) {
            const {onMouseOver} = this.props; 
            if (onMouseOver) {
                this.hovered = true;
                onMouseOver();
            }
        }
    }

    @action.bound onMouseLeave() {
        if (Tools.breakpoints.up('md')) {
            const {onMouseLeave} = this.props
            if (onMouseLeave) {
                this.hovered = false;
                onMouseLeave();
            }
        }
    }
   
    render () {        
        const {classes, heading, name, id, colour, width, title, enabled, placeHolderText, browseStore, selectedElectorateType} = this.props;
        const numListRows = Math.round(Math.sqrt(this.sortedItems.length) * 2);
        const supportsCustomListSelector = (!!window.CSS && window.CSS.supports('grid-template-rows', 'repeat(3, auto)'));
        const isMobileView = isWidthDown('xs', width);   
        const isDesktopView = isWidthUp('md', width)  && supportsCustomListSelector;
        let sortedListItems = [];
        let border={};
        if (colour) {
            border.borderStyle='none';            
            border.borderColor=colour;            
        }
       
        let iconStyle = '';        
        if(colour && this.hasSelection) {
            iconStyle = "color: '" + colour + "'";
        }        
       
        let infoStyle = {};
        if(colour && this.hasSelection)  {
            infoStyle.borderColor = colour;
        }

        let inputStyle = {};
        inputStyle.textTransform = 'uppercase' ;
        if(colour &&  (browseStore.infoDrawerOpen  == selectedElectorateType)) {                       
            inputStyle.backgroundColor = colour;
            inputStyle.borderColor = colour;
            inputStyle.color = 'white';              
        }
        else if (colour && this.hasSelection) {                   
            inputStyle.borderColor=colour;
            inputStyle.color = colour; 
            let rgbColour = Tools.hexToRgb(colour);
            inputStyle.backgroundColor = this.hovered? `rgba(${rgbColour.r},${rgbColour.g},${rgbColour.b},0.08)` : 'white'; 
        }
       
        let  floatingStyle = {pointerEvents : 'none',  position: 'fixed'};       
        if(!isDesktopView) {
            floatingStyle.height = '100%';
        }

        let selectedValue = '';
        if (isMobileView) {
            selectedValue = this.selectedItem? this.selectedItem.electorateId : "default";            
        } else {
            selectedValue = this.selectedItem? this.selectedItem : {};
        }


        const itemListGridTemplate = {gridTemplateRows: 'repeat(' + numListRows + ', auto)'}
        this.sortedItems.forEach((item) => {
            if (item.electorateName.length && !item.electorateName.startsWith(sectionfirstChar)) {
                sectionfirstChar = item.electorateName.charAt(0);
                sortedListItems.push(<li key={'h'+sectionfirstChar}><Typography variant='h3'>{sectionfirstChar}<hr/></Typography></li>)
            }
            sortedListItems.push(<li key={item.electorateId} className={'item'} onClick={() => {this.handleSelectionChange(item.electorateId);}}><Typography variant='h6'>{item.electorateName}</Typography></li>);
        });
        let sectionfirstChar = '';
        let infoContainerStyle = {};      
        if(colour && isDesktopView) { 
            infoContainerStyle.borderColor = colour;
        } 

        return (
            <div className = {classes.selectContainer}>
                  
                    <Typography  display='block' variant = 'h6' className={classes.title}>{heading} </Typography>                           
                   
                    <div>
                    <FormControl className={classes.formControl} >
                        <Select
                            value={selectedValue}
                            className={classes.selectMain}
                            style={inputStyle}
                            onMouseOver={() => {this.onMouseOver();}}
                            onMouseLeave={() => {this.onMouseLeave();}}
                            ref={this.selectInfoRef}
                            IconComponent={this.hasSelection ?
                                () => (
                                    <div className={classes.iconDiv}>   
                                        <div className={classes.info} ><InfoIcon onClick = {this.handleInfoClick} className = {classes.iconClass} /></div>
                                        <div className = {classes.info}> <Divider orientation = "vertical" className = {classes.iconDivider}  /></div>
                                        <div className={classes.cancel} ><CancelIcon onClick = {this.handleCancelClick} className = {classes.iconClass} /></div>
                                    </div>
                                            ) 
                                    : KeyboardArrowDownIcon }
                          
                                native = {isMobileView}
                                disabled = {!enabled}
                                onChange = {event => {if (!isDesktopView) this.handleSelectionChange(event.target.value);}}
                                MenuProps= {isDesktopView? {style:border, classes: {paper: classes.listContainer}}: {}}
                                renderValue = {((selectedValue) => {return selectedValue.electorateName? selectedValue.electorateName: placeHolderText? placeHolderText: "Select " + name})}
                                disableUnderline 
                                inputProps={
                                    {    
                                        classes : {icon : enabled ? this.hasSelection  ? iconStyle : classes.chevron : classes.disabledChevron },
                                        style : inputStyle,                             
                                        name: id+'electorateName',
                                        id:  id,
                                        disabled: !enabled                                 
                                    }} >
                                    {   //Placeholder content for native selector
                                        isMobileView && <option value="default" disabled hidden>{placeHolderText? placeHolderText: "Select " + name}</option>
                                    }
                                    {
                                        isMobileView?
                                            this.sortedItems.map( (electorate) => (
                                                <option key={electorate.electorateId} value={electorate.electorateId}>
                                                    {electorate.electorateName}
                                                </option> 
                                            ))
                                        : isDesktopView? 
                                        <MenuItem disableRipple>
                                        <Typography variant='h2' paragraph className={classes.windowTitle}>{title}</Typography>
                                        <div onClick={(event)=> {if (event) {if (event.target.tagName == 'UL') {event.stopPropagation(); }}}}>
                                            <ul style={itemListGridTemplate} className={classes.itemList}>
                                                {sortedListItems.map ((item) => {
                                                    return (item)
                                                })}
                                            </ul>              
                                        </div>
                                        </MenuItem>                                                
                                        : this.sortedItems.map( (electorate) => (
                                            <MenuItem key={electorate.electorateId } value={electorate.electorateId} >
                                                {electorate.electorateName}
                                            </MenuItem> 
                                        ))                                            
                                    }
                            </Select> 
                        </FormControl>
                    </div>
                    <div>
                        {
                            isDesktopView ?
                            <Drawer open={browseStore.infoDrawerOpen == selectedElectorateType} anchor='right' onClose={() => this.toggelDrawerOpen(-1)} variant = "persistent" 
                                    classes = {{paper : classes.desktopDrawer}} PaperProps = {{ style : infoContainerStyle }} >
                                { (browseStore.infoDrawerOpen  == selectedElectorateType) && <InfoContainer  closeClickFn={this.handleInfoClose}                                   
                                            colour = {colour}                                   
                                            selectedElectorate = {this.selectedItem}  
                                    />
                                }
                            </Drawer> 
                            :
                            <Drawer open={browseStore.infoDrawerOpen == selectedElectorateType } anchor='top' onClose={() => this.toggelDrawerOpen(-1)} variant = "permanent" 
                                        classes = {{paper : classes.deviceDrawer}} >
                                {(browseStore.infoDrawerOpen  == selectedElectorateType) && <InfoContainer  closeClickFn={this.handleInfoClose}                                   
                                            colour = {colour}                                   
                                            selectedElectorate = {this.selectedItem}  
                                    />
                                }
                            </Drawer> 
                        }
                    </div>

            </div>
        );
       
    }

    static propTypes = {
        listItems: PropTypes.oneOfType([PropTypes.array, PropTypes.object.isRequired]),
        name: PropTypes.string,
        id: PropTypes.string,
        title: PropTypes.string,
        selectedItem: PropTypes.object,
        selectedElectorateType: PropTypes.number,
        enabled: PropTypes.bool,        
        placeHolderText: PropTypes.string,
        width: PropTypes.string.isRequired,      
        onChange: PropTypes.func.isRequired,     
        onMouseOver: PropTypes.func,
        onMouseLeave: PropTypes.func,      
        onShowInfoChange: PropTypes.func,
        colour: PropTypes.string.isRequired,
        heading: PropTypes.string,
        classes: PropTypes.object,
        browseStore: PropTypes.object,
        appMainStore: PropTypes.object       
    }
}


export default withStyles(styles)(withWidth()(SelectionList));
