import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import MarkDownViewer from './markdown-viewer';
import {InfoOutlined} from '@material-ui/icons';
import ShapeCopyIcon from '../../../static/images/misc/shape-copy.svg';
import { Typography, IconButton } from '@material-ui/core';
import  ElectionAlerts  from './electionAlerts';


const styles = theme => ({
    headingContainer:{
        padding: '23px 8px 23px 28px',
        [theme.breakpoints.down('xs')]: {
            padding: '13px 8px 13px 28px',
        }
    },
    alertsContainer: {
        padding: '13px 8px 13px 28px',
        [theme.breakpoints.down('xs')]: {
            padding: '8px 8px 8px 28px'
        }        
    },
    title: {
        fontSize: '16px !important',
        fontWeight: 'bold',
        alignItems: 'center',
        marginBottom: '5px',
        width: '100%',
        height: '40px',
        display: 'flex'
    },
    messagesWrapper: {
        fontSize: '16px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
        },
        ['& p']: {
            marginTop: '5px',
            marginBottom: '5px',
        },
        ['& a']: {
            color: theme.palette.primary.main
        }
    },
    infoIcon: {
        float: 'left',
        paddingTop: '3px',
        marginRight: '11px',
        width: '25px',
        height: '25px',
    },
    closeButton: {
        top: '-5px',
        float: 'right',
        ['& img']: {
            width: '16px',
            height: '16px',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: '10px',
        },
    },
    container: {
        position: 'absolute',
        minHeight: '100px',
        maxHeight: 'calc(100% - 100px)',
        backgroundColor: 'white',
        color: theme.palette.gray.gray600,                
        paddingBottom: '15px',
        zIndex: '9999',
        width: '379px',
        right: '45px',
        top: '70px',
        boxShadow: '4px 5px 10px 1px #8a8a8a',
        '& > div > div:not(:last-child)':{ 
            borderBottom: '1px solid ' + theme.palette.gray.gray200,
        },
        ['& a']: {
            color: theme.palette.primary.main,            
        },   
        ['& span']: {
            fontSize: '20px',
            fontWeight: '600',
            fontSpacing: '0.4px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            position: 'fixed',
            display: 'block',    
            top: '60px',
            right: 'auto',
            maxHeight: 'calc(100% - 75px)',
        },
        [theme.breakpoints.down('xs')]: {
            top: '55px',
            overflowY: 'auto',
            maxHeight: 'calc(100vh - 180px)'
        },
        [theme.breakpoints.up('md')]: {
            borderWidth: '2px',
            borderRadius: '5px',
            borderColor: '#f9f9f9',
            '&::before': {
                position: 'absolute',
                top: '-20px',
                left: '310px',
                width: 0,
                height: 0,
                borderLeft: '20px solid transparent',
                borderRight: '20px solid transparent',
                borderBottom: '20px solid #f9f9f9',
                content: '""',
            },
            '&::after': {
                position: 'absolute',
                top: '-20px',
                left: '310px',
                width: 0,
                height: 0,
                borderLeft: '20px solid transparent',
                borderRight: '20px solid transparent',
                borderBottom: '20px solid white',
                content: '""',
            },
        }
    },
    scrollMe: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 100px)'
    }
});

@inject('appMainStore', 'electionsStore')
@observer
class Alerts extends React.Component {

    constructor(props) {
        super(props);
        this.electionWatchDisposer = reaction(() => props.electionsStore.electionsForAlert.map(election => election.electionName), (elections) => {
             if (elections.length != 0) props.appMainStore.alertMessageShown = true; 
        });
    }

    componentWillUnmount() {
        this.electionWatchDisposer();
    }
    handleCloseAlertMessages = () => {
        this.props.appMainStore.alertMessageShown = false;
    }

    render() {
        const {classes, appMainStore, electionsStore, history} = this.props;

        return ((appMainStore.IsAlertMessageAvailable || electionsStore.electionsForAlert.length > 0  ) && appMainStore.CanShowAlert  &&
            <div position="static" className={classes.container}>
                <div className={classes.scrollMe}>
                    <div className={classes.headingContainer}>
                        <InfoOutlined className={classes.infoIcon}/><Typography component='span'>Notifications</Typography><IconButton id="btn-alert-close" aria-label='Close notifications' onClick={this.handleCloseAlertMessages} className={classes.closeButton}><img alt='close' src={ShapeCopyIcon}/></IconButton>
                    </div>
                    <ElectionAlerts classes = {classes} history = {history} />
                    {appMainStore.RelativeAlerts.map((alert, index) => {
                        return (<div key={'Alert-' + index} className={classes.alertsContainer}>
                            {alert.title && 
                                <div className={classes.title}>
                                    <MarkDownViewer mdName="Alert_Title" mdValue={alert.title} />
                                </div>
                            }
                            <div className={classes.messagesWrapper}>
                                <MarkDownViewer mdName="Alert_Content" mdValue={alert.content} />
                            </div>
                        </div>)
                    })}
                </div>
            </div>  
        )
    }

    static propTypes = {
        appMainStore: PropTypes.object,
        classes: PropTypes.object,
        electionsStore: PropTypes.object,
        history: PropTypes.object
    }
}

export default withStyles(styles)(Alerts);
