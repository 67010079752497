export const styles = theme => {
    const { spacing } = theme;
    const unit1x = `${spacing(1)}px`;
    const unit2x = `${spacing(2)}px`;
    const unit0_5x = `${spacing(0.5)}px`;
    const unit1_5x = `${spacing(1.5)}px`;
    return ({
        root: {
            transition: 'max-height .15s linear',
            overflowY: 'auto',

            [theme.breakpoints.down('sm')]: {
                maxHeight: 'calc(100vh - 165px)',
            },
            [theme.breakpoints.down('xs')]: {
                position: 'fixed',
                marginTop: '120px',
                width: '100%',
                maxHeight: 'calc(100vh - 65px)',
                minHeight: '100vh',
                background: 'white',
                zIndex: 2,
            },
        },
        listItemScrollingPlaceholder: {
            [theme.breakpoints.down('sm')]: {
                height: '18px',
                width: '100%',
                zIndex:'0'
            }
        },
        listContainer: {
            margin: 0,
            padding: 0,
            backgroundColor: 'white',
            maxHeight: 'calc(95vh - 520px)',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'initial',
                overflowY: 'initial',
            },
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(95vh - 460px)',
                overflowY: 'auto',
            },
        },
        listContainerBaseStyle: {
            margin: 0,
            padding: 0,
            backgroundColor: 'white',
            maxHeight: 'calc(95vh - 520px)',
            overflowY: 'auto',
            [theme.breakpoints.down('sm')]: {
                maxHeight: 'initial',
                overflowY: 'initial',
            },
        },
        listContainerEarlyVoting: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 505px)',
                overflowY: 'auto',
            },
        },
        listContainerEarlyVotingIOS: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 545px)',
                overflowY: 'auto',
            },
        },
        listContainerOutsideVictoria: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 280px)',
                overflowY: 'auto',
            },
        },
        listContainerOutsideVictoriaIOS: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 320px)',
                overflowY: 'auto',
            },
        },
        listContainerElectionDay: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 405px)',
                overflowY: 'auto',
            },
        },
        listContainerElectionDayIOS: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: 'calc(100vh - 445px)',
                overflowY: 'auto',
            },
        },
        listContainerMaxHeight: {
            [theme.breakpoints.down('xs')]: {
                maxHeight: '-webkit-calc(99vh - 420px)'
            }
        },
        listFooter: {
            backgroundColor: 'white',
            padding: `${unit1_5x} ${unit2x}`,
            borderTop: '1px solid #e2e2e2',
            cursor: 'pointer'
        },
        listFooterButton: {
            fontSize: '13px',
            display: 'block',
            backgroundColor: 'white',
            border: 0,
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            padding:0,
            width: '100%',
            lineHeight: '27px',
            textTransform: 'uppercase'
        },
        listItem: {
            ...theme.overrides.MuiTypography.h1,
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            background: 'white',
            borderTop: '1px solid #e2e2e2',
            padding: unit2x,
            cursor: 'pointer',
            ['&:last-child']: {
                borderBottom: '1px solid #e2e2e2',
            }
        },
        listDistrictItem: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            }
        },
        listItemActive: {
            ['&:before']: {
                content: '" "',
                height: '100%',
                width: '4px',
                backgroundColor: theme.palette.primary.main,
                position: 'absolute',
                top: 0,
                left: 0,
            }
        },
        listItemLeft: {
            flex: 1,
        },
        listItemLeftTitle: {
            fontSize: '16px',
            fontWeight: 600,
            margin: 0,
            marginBottom: unit1x,
        },
        listItemLeftSubtitle: {
            ...theme.overrides.MuiTypography.h6,
            letterSpacing: '0.4px',
            fontWeight: 'normal',
            margin: 0,
            marginBottom: unit0_5x,
        },
        listItemLeftFooter: {
            fontSize: '12px',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            margin: 0,
            marginTop: unit2x,
            fontWeight: 600,
        },
        listItemRight: {
            position: 'relative',
            minWidth: '10px'
        },
        listItemRightTransportation: {
            fontSize: '13px',
            fontWeight: 600,
            width: '100%',
            textAlign: 'right',
            verticalAlign: 'top',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'flex-end',
            ['& img']: {
                width: '14px',
                height: '16px',
                objectFit: 'contain',
            },
            ['& span']: {
                marginLeft: '6px',
            }
        },
        listItemRightIndicator: {
            position: 'absolute',
            height: '100%',
            top: 0,
            display: 'flex',
            alignItems: 'center',
            right: 0,
            ['& img']: {
                width: '10px',
                height: '16px',
            }
        },
        listItemTop: {
            flex: 1,
            ['& p']: {
                ...theme.overrides.MuiTypography.body1,
                fontWeight: 500,
                margin: 0,
            },
            ['& p:first-child']: {
                ...theme.overrides.MuiTypography.subtitle1,             
                marginBottom: unit0_5x,
            },
            ['& .search-results-electorate-name']: {
                fontSize: '17px',
                fontWeight: 600,
                lineHeight: 'inherit',
            }
        },
        listItemTopPath: {
            ['& img']: {
                width: '23px',
                height: '20px',
            },
            marginRight: unit2x,
        },
        votingCenterTypesListContainer:{
            ['& span']: {
                alignSelf: 'center',
                flexDirection: 'row',
                display: 'inline-flex'
            }
        },
        dotSeparator: {
            width: '3px',
            display: 'inline-block',
            background: '#000000',
            height: '3px',
            border: '1px solid #000',
            borderRadius: '3px',
            margin:' 0 6px'
        },
        groupedHeadingText: {            
            ...theme.overrides.MuiTypography.h5,
            textTransform: 'none',
            padding: '16px 18px 18px 16px',
            boxSizing: 'border-box',
            height: '54px',
            color: theme.palette.primary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
            outline: 'none',
            border: 0,
            fontFamily: theme.typography.fontFamily,
            [theme.breakpoints.down('xs')]: {
                ...theme.overrides.MuiTypography.h5,
                height: '54px',
                fontWeight: '600',
            },
        },
    });
};
