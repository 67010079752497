import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { Typography, Button } from '@material-ui/core';
import { WheelchairIcon } from '../../components/common/svg-icons';
import * as Enums from '../../enums';
import { accessibilityItem } from '../../utils/constants'

const styles = (theme) => {
    const { spacing } = theme;
    const unit2x = `${spacing(2)}px`;
    return ({
        container: {
            backgroundColor: 'white',
            padding: unit2x,
            borderTop: '1px solid #e2e2e2',
        },
        filtersContainer:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingTop: '10px',
            '& button': {
                fontSize: '10px',
                fontWeight: 600,
                [theme.breakpoints.down('xs')]: {
                    padding: '5px 5px'
                }
            }
        },
        description: {
            fontSize: '12px',
            paddingTop: '10px'
        },
        filtersButtons: {
            display: 'flex',
            alignItems: 'center',
            paddingTop: '10px',
            '& button': {
                fontSize: '10px',
                fontWeight: 600,
                marginRight: '10px',
                [theme.breakpoints.down('xs')]: {
                    padding: '5px 5px'
                }
            }
        }
    })
}

@inject('searchStore', 'electionsStore', 'votingCentresStore')
@observer
class AccessibilityFiltersBox extends React.Component {
    render() {
        const { classes, votingCentresStore } = this.props;
        const accessibilityTypeToShow = votingCentresStore.accessibilityTypeToShow;
        const { isBeforeEarlyVotingCutoff, isAddressSearchInVictoria, selectedElection } = this.props.electionsStore;
        const svgIconPropsConfig = {
            width: "14",
            height: "16",
            viewBox: "0 0 14 16",
            fill: '#A30134'
        };
        const svgIconPropsConfigSelected = {
            width: "14",
            height: "16",
            viewBox: "0 0 14 16",
            fill: 'white'
        };
        
        return (<React.Fragment>
                {isAddressSearchInVictoria && selectedElection && selectedElection.electionMethod !== 'Postal' ? <div className={classes.container}>
                <Typography variant='h5' color='secondary'>Filter by</Typography>
                <div className={classes.filtersButtons}>                    
                    <Button color='primary' onClick={() => { this.updateFilterType(Enums.AccessibilityType.ALL); }} variant={accessibilityTypeToShow == Enums.AccessibilityType.ALL ? 'contained' : 'outlined'}><span>ALL VENUES</span></Button>
                    <Button color='primary' onClick={() => { this.updateFilterType(Enums.AccessibilityType.IWA); }} variant={accessibilityTypeToShow == Enums.AccessibilityType.IWA ? 'contained' : 'outlined'}>{WheelchairIcon(accessibilityTypeToShow == Enums.AccessibilityType.IWA ? svgIconPropsConfigSelected : svgIconPropsConfig)}<span>IWA</span></Button>
                    <Button color='primary' onClick={() => { this.updateFilterType(Enums.AccessibilityType.AWA); }} variant={accessibilityTypeToShow == Enums.AccessibilityType.AWA ? 'contained' : 'outlined'}>{WheelchairIcon(accessibilityTypeToShow == Enums.AccessibilityType.AWA ? svgIconPropsConfigSelected : svgIconPropsConfig)}<span>AWA</span></Button>
                    <Button color='primary' onClick={() => { this.updateFilterType(Enums.AccessibilityType.LNWA); }} variant={accessibilityTypeToShow == Enums.AccessibilityType.LNWA ? 'contained' : 'outlined'}>{WheelchairIcon(accessibilityTypeToShow == Enums.AccessibilityType.LNWA ? svgIconPropsConfigSelected : svgIconPropsConfig)}<span>LNWA</span></Button>
                </div>
                <div className={classes.description}>{this.getDescription()}</div>
                </div> : null }
            </React.Fragment>
        );
    }

    getDescription() {
        let description;
        switch (this.props.votingCentresStore.accessibilityTypeToShow) {
            case Enums.AccessibilityType.IWA:
                description = accessibilityItem.IWA.label;
                break;
            case Enums.AccessibilityType.AWA:
                description = accessibilityItem.AWA.label;
                break;
            case Enums.AccessibilityType.LNWA:
                description = accessibilityItem.LNWA.label;
                break;
            default:
                description = accessibilityItem.ALL.label;
                break;
        }
        return description;
    }

    updateFilterType(type) {
        this.props.votingCentresStore.setAccessibilityTypeToShow(type); //.onFilterAccessibility(type);,
    }

}

AccessibilityFiltersBox.propTypes = {
    classes: PropTypes.object.isRequired,
    searchStore: PropTypes.object,
    electionsStore: PropTypes.object,
    votingCentresStore: PropTypes.object,
    onFilterAccessibility: PropTypes.func,
    accessibilityTypeToShow: PropTypes.number
};

export default withStyles(styles)(AccessibilityFiltersBox);
